import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./NotFound.css"; // Import your custom CSS for styling
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="non-founds-yatrii">
      <section className="page-section">
      <div className="page-decoration-1" />
      <div className="page-decoration-2" />
      <div className="page-decoration-3" />
      <div className="full-width-screen">
        <div className="container-fluid">
          <div className="content-detail">
            <h1 className="global-title">
              <span>O</span>
              <span>o</span>
              <span>P</span>
              <span>S</span>
              <span>!</span>
            </h1>
            <div className="page-desc">
              <h4 className="sub-title">Oops!</h4>
              <p className="detail-text">
                We're sorry,
                <br /> There is an technical issue. please contact <Link to="mailto:support@yatriservice.com">support@yatriservice.com</Link> 
              </p>
              <div className="back-btn">
                <Link to="/agent/dashboard" className="btn">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default NotFound;
