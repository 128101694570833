import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Row, Col } from "react-bootstrap";
import React from "react";

const OneWaySkeleton = () => {
  return (
    <div className="detail-bar">
      <div className="detail-wrap wow fadeInUp">
        <Row>
          <Col md={2}>
            <div className="logo-sec">
              <Skeleton width={80} height={50} />
              <span className="title">
                <Skeleton width={80} />

                <Skeleton width={80} />
              </span>
            </div>
          </Col>
          <Col md={5}>
            <div className="airport-part">
              <div className="airport-name">
                <h4>
                  {" "}
                  <Skeleton width={60}/>
                </h4>
                <h6>
                  {" "}
                  <Skeleton width={60}/>
                </h6>
              </div>
              <div className="airport-progressss">
                <div>
                  <Skeleton width={200}/>
                </div>
                <Skeleton width={200}/>
                <div>
                  {" "}
                  <Skeleton width={200}/>
                </div>
              </div>
              <div className="airport-name arrival">
                <h4>
                  {" "}
                  <Skeleton width={60}/>
                </h4>
                <h6>
                  {" "}
                  <Skeleton width={60}/>
                </h6>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="price">
              <div>
                <h4 style={{border:'none'}}>
                  <Skeleton width={80} height={20} style={{border:'none'}}/>
                </h4>
                <span>
                  <Skeleton width={80} height={20}/>
                </span>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="book-flight" style={{border:'none'}}>
             
              
                  <Skeleton width={90} height={45}/>
                
            
            </div>
          </Col>
          <Col lg={12}>
            <div
              style={{
                paddingLeft: "35px",
                paddingY: "10px",
                marginTop: "5px",
                background: "#f9f5ff7d",
                cursor: "pointer",
              }}
            >
              <Skeleton />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OneWaySkeleton;
