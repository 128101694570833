import React, { useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { IoCall } from "react-icons/io5";
import { FaEnvelopeOpenText } from "react-icons/fa";
import "./HeroNavbar.css";
import { Link, useNavigate } from "react-router-dom";
import Yatra from "./yatri-service.png";

import { useDispatch } from "react-redux";
import { Wallentbalance, login } from "../../../redux/services/operations/auth";

const HeroNavbar = () => {
  const [LoginId, setLoginId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [acceptCookie, setAcceptCookie] = useState(true);
  const [UserName, setUserName] = useState("guruansh@holidaychacha.com");
  const [Password, setPassword] = useState("");
  const [Passwordss, setPasswordss] = useState("Hello123@123");
  const [APIKey, setAPIKey] = useState("8FFA90A1-0459-43EC-BDA8-B77D3BCA57A7");
  const handleLogin = async () => {
    dispatch(login(LoginId, Password, APIKey, navigate));
    dispatch(Wallentbalance(LoginId, Password));
    // navigate('/agent/dashboard')
  };
  return (
    <header className="header">
      <div className="yscontainerfull">
        <div
          id="cookiesDiv"
          className={`${acceptCookie ? "d-block" : "d-none"} cookiesdiv`}
          style={{}}
        >
          {/* Text to Show in cookie*/}
          <span>
            YatraService uses necessary cookies to improve your user experience
            and to make our website, mobile applications and services work.
          </span>
          <button
            type="button"
            className="cookiesbtn"
            onClick={() => setAcceptCookie(false)}
          >
            Accept
          </button>
        </div>
      </div>
      <div style={{ background: "#fff" }}>
        <Container>
          <Row>
            <Col md={2} style={{ textAlign: "center", marginTop: "5px" }}>
              <Link to="/">
                <img src={Yatra} alt="" width={150} />
              </Link>
            </Col>
            <Col md={10}>
              <div
                className="new_loginbox hero-login-box-navbar"
                
              >
                <div className="new_loginform forgot_paas_link">
                  <div id="ShowCredentials" className="tbofleft">
                    <input
                      name="LoginName"
                      type="text"
                      id="LoginName"
                      className="new__loginput new_username"
                      placeholder="Username"
                      value={LoginId}
                      onChange={(e) => setLoginId(e.target.value)}
                    />
                    <input
                      name="Password"
                      type="password"
                      id="Password"
                      className="new__loginput new_pass"
                      placeholder="Password"
                      // aria-label="Login Password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="new_loginbtn">
                  <button
                    type="submit"
                    name="Login"
                    placeholder="Login"
                    value="Login"
                    defaultValue="Login"
                    id="Login"
                    className="new_frmbtn"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                  <Link
                    to="/agent-registration"
                    className="new_frmbtn new__registerbtn"
                  >
                    Register Now
                  </Link>
                  <br />
                  <input
                    id="captchaLoginName"
                    name="captchaLoginName"
                    type="hidden"
                    defaultValue=""
                  />
                </div>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default HeroNavbar;
