import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Protected = ({ children }) => {
  const location = useLocation();
  const isAgentRoute = location.pathname.includes("/agent/");
  const { token } = useSelector((state) => state.auth);

  //   if (isAgentRoute) {
  if (token || localStorage.getItem("token")) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
  //   } else {
  //     return isActive ? <Navigate to="/agent/dashboard" /> : children;
  //   }
};

export default Protected;
 