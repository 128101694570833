import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminDm: 0,
  adminIm: 0,
  agentDm: 0,
  agentIm: 0,
};
const markUpSlice = createSlice({
  name: "markup",
  initialState: initialState,
  reducers: {
    setAdminDm(state, action) {
      state.adminDm = action.payload;
    },
    setAdminIm(state, action) {
      state.adminIm = action.payload;
    },
    setAgentDm(state, action) {
      state.agentDm = action.payload;
    },
    setAgentIm(state, action) {
      state.agentIm = action.payload;
    },
  },
});

export const { setAdminDm, setAdminIm, setAgentDm, setAgentIm } =
markUpSlice.actions;
export default markUpSlice.reducer;
