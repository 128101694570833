import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./MyComponent.css";
import Business from "./business.png";
function MyComponent() {
  return (
    // <section className="section_padding">
    //   <Container>
    //     <Row>
    //       <Col md={6}>
    //         <h2><b>Unlock Your Earning Potential with Yatri Service - The Ultimate B2B Travel Companion</b></h2>
    //         <p>In the fast-paced world of travel, seize the opportunity to maximize your earnings with Yatri Service, your go-to B2B travel tool. Designed for travel agents by travel experts, our platform empowers you to elevate your business to new heights. Experience a seamless booking process, access exclusive deals, and enjoy personalized support to ensure every transaction contributes to your success. With Yatri Service, it's not just a tool; it's your key to unlocking unparalleled earning potential in the dynamic realm of travel.</p>
    //       </Col>
    //       <Col md={6}>
    //         <img src="https://admin.yatriservice.com/public/images/expand-sales.jpg" className="sectionImg" alt="Expand Sales" />
    //       </Col>
    //     </Row>
    //   </Container>
    // </section>
    <section className="tf__faq mt_100 pt_95 xs_pt_100 pb_100">
      <Container>
        <Row>
          <Col xl={6} lg={6} className="wow fadeInLeft">
            <div
              className="tf__faq_img"
              style={{ position: "relative", zIndex: 2 }}
            >
              <img src={Business} alt="faqs" className="w-100" />
            </div>
          </Col>
          <Col xl={6} lg={6} className="wow fadeInRight">
            <div className="tf__faq_text">
              <div className="tf__heading_area tf__heading_area_left mb_25">
                {/* <h5>Our Education FAQ</h5> */}
                <h2>
                  Unlock Your Earning Potential with Yatri Service - The
                  Ultimate B2B Travel Companion
                </h2>
              </div>
              <p className="description">
                In the fast-paced world of travel, seize the opportunity to
                maximize your earnings with Yatri Service, your go-to B2B travel
                tool. Designed for travel agents by travel experts, our platform
                empowers you to elevate your business to new heights. Experience
                a seamless booking process, access exclusive deals, and enjoy
                personalized support to ensure every transaction contributes to
                your success. With Yatri Service, it's not just a tool; it's
                your key to unlocking unparalleled earning potential in the
                dynamic realm of travel.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default MyComponent;
