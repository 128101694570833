import React, { useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./FlightInternational.css";
// import { FlightListInfo } from "../FlightList/FlightListInfo";
// import { FlightListInfo } from "../OneWay/FlightListInfo";
// import { FlightListInfo } from "../../OneWay/FlightListInfo";
import { InternationalFlightDetail } from "./InternationFlightDetail";
import { useSelector } from "react-redux";

const InternationalFlightListCard = ({
  e,
  handleMoreFare,
  handleClick,
  activeId,
  fares,
  showModal,
  fareRules,
  setShowModal,
  formatTime,
  netFaress,
  handlebookmodal,
  internationalMarkup,
}) => {
  const [netFare, setNetFare] = useState(false);
  // console.log("dvsgfdyfddevdbheeeeeeee", e);
  const { adminIm, agentIm } = useSelector((state) => state.markUp);

  const formatLayoverTime = (arrivalTime, nextDepartureTime) => {
    const arrival = new Date(arrivalTime);
    const nextDeparture = new Date(nextDepartureTime);
    const layoverMinutes = Math.floor(
      (nextDeparture.getTime() - arrival.getTime()) / (1000 * 60)
    );
    const layoverHours = Math.floor(layoverMinutes / 60);
    const layoverMinutesRemainder = layoverMinutes % 60;
    return `${layoverHours}h : ${layoverMinutesRemainder}m`;
  };
  return (
    <div className="internationtrip_search">
      
      {e && e.AirSegments && e.AirSegments[0] && e.AirSegments[1] && (
        <Container
          className="refendable11 refendable11onword"

          // style={{maxWidth:"100%"}}
        >
          <Row className="price1">
            <Col
              className="price111 price111onword"
              style={{ paddingInline: "5px" }}
            >
              <Row className="flight11" flight="UL">
                <Col className="flight112" flightcraft="">
                  <div className="stopscount" stop="1">
                    <div className="Price33827 allshow block-content-2 custom_block_content flight-list-v2 ULSri Lankan Airlines 0Stops bingo_button_4">
                      <div className="box-result custom_box_result">
                        <div className="inter_trip_left">
                          <h4>{e.AirSegments[0][0].SegmentType}</h4>
                          {/* {e.Segments[0].map((returnflight, index2) => ( */}
                          <ul
                            className="list-search-result result_list"
                            style={{ display: "flex" }}
                          >
                            <li>
                              <Image
                                src={`/Images/AirlineLogo/${e.AirSegments[0][0].AirlineCode}.gif`}
                                alt=""
                              />
                              <div className="flight_name obflight_name">
                                {e.AirSegments[0][0].AirlineName}{" "}
                                <span className="flight_no">
                                  {e.AirSegments[0][0].AirlineCode}-
                                  {e.AirSegments[0][0].FlightNumber}
                                </span>
                              </div>
                            </li>
                            <li className="depart_time cus_dep_arr_time">
                              <span className="date departdate">
                                {e.AirSegments[0][0].Origen}{" "}
                                {new Date(
                                  e.AirSegments[0][0].DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {/* {e.Segments[0][0].Origin.Airport.CityName} */}
                              <div className="date_time">
                                {formatTime(
                                  e.AirSegments[0][0].DepartureDateTime
                                )}
                              </div>
                            </li>
                            <li className="flight_time_between">
                              <span className="duration departdur">
                                {/* {e.AirSegments[0][0].Duration} */}
                                {formatLayoverTime(
                                  e.AirSegments[0][0].DepartureDateTime,
                                  e.AirSegments[0][e.AirSegments[0].length - 1]
                                    .ArrivalDateTime
                                )}
                                |
                                <div className="cus_tooltip">
                                  {/* {e.Segments[0].length - 1 == 0
                                    ? "Non-Stop"
                                    : `${e.Segments[0].length - 1} Stops`}{" "} */}
                                  <span className="tooltiptext">
                                    {/* {e.Segments[0]
                                      .map(
                                        (segment, index) =>
                                          segment.Origin.Airport.CityCode
                                      )
                                      .join("→")}
                                    →
                                    {
                                      e.Segments[0][e.Segments[0].length - 1]
                                        .Destination.Airport.CityCode
                                    } */}
                                  </span>
                                </div>
                              </span>
                              <div className="time_separete"></div>
                              <div className="flight_rel">
                                {" "}
                                {/* {e.Segments[0]
                                  .map(
                                    (segment, index) =>
                                      segment.Origin.Airport.CityCode
                                  )
                                  .join("→")}
                                →
                                {
                                  e.Segments[0][e.Segments[0].length - 1]
                                    .Destination.Airport.CityCode
                                } */}
                              </div>
                            </li>
                            <li className="arrive_time cus_dep_arr_time">
                              <span className="date arivedate">
                                {
                                  e.AirSegments[0][e.AirSegments[0].length - 1]
                                    .Destination
                                }{" "}
                                {new Date(
                                  e.AirSegments[0][
                                    e.AirSegments[0].length - 1
                                  ].ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {
                                // e.Segments[0][e.Segments[0].length - 1]
                                //   .Destination.Airport.CityName
                              }
                              <div className="date_time">
                                {formatTime(
                                  e.AirSegments[0][e.AirSegments[0].length - 1]
                                    .ArrivalDateTime
                                )}
                              </div>
                            </li>
                          </ul>
                          {/* ))} */}
                          <div className="clearfix"></div>
                          <div className="hr_seperator"></div>
                          <h4>Return</h4>
                          {/* {e.Segments[1].map((returnflight, index2) => ( */}
                          <ul
                            className="list-search-result result_list"
                            style={{ display: "flex" }}
                          >
                            <li>
                              <Image
                                src={`/Images/AirlineLogo/${e.AirSegments[1][0].AirlineCode}.gif`}
                                alt=""
                              />
                              <div className="flight_name">
                                {e.AirSegments[1][0].AirlineName}{" "}
                                <span className="flight_no">
                                  {e.AirSegments[1][0].AirlineCode}-
                                  {e.AirSegments[1][0].FlightNumber}
                                </span>
                              </div>
                            </li>
                            <li className="depart_time cus_dep_arr_time">
                              <span className="date">
                                {" "}
                                {e.AirSegments[1][0].Origen}{" "}
                                {new Date(
                                  e.AirSegments[1][0].DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {/* {e.Segments[1][0].Origin.Airport.CityName} */}
                              <div className="date_time cus_dep_arr_time">
                                {formatTime(
                                  e.AirSegments[1][0].DepartureDateTime
                                )}
                              </div>
                            </li>
                            <li className="flight_time_between">
                              <span className="duration">
                                {/* {e.AirSegments[1][0].Duration}  */}
                                {formatLayoverTime(
                                  e.AirSegments[1][0].DepartureDateTime,
                                  e.AirSegments[1][e.AirSegments[1].length - 1]
                                    .ArrivalDateTime
                                )}{" "}
                                |
                                <div className="cus_tooltip">
                                  {/* {e.Segments[1].length - 1 == 0
                                    ? "Non-Stop"
                                    : `${e.Segments[1].length - 1} Stops`}{" "} */}
                                </div>
                              </span>
                              <div className="time_separete"></div>
                              <div className="flight_rel">
                                {" "}
                                {/* {e.Segments[1]
                                  .map(
                                    (segment, index) =>
                                      segment.Origin.Airport.CityCode
                                  )
                                  .join("→")}
                                →
                                {
                                  e.Segments[1][e.Segments[1].length - 1]
                                    .Destination.Airport.CityCode
                                } */}
                              </div>
                            </li>
                            <li className="arrive_time cus_dep_arr_time">
                              <span className="date">
                                {
                                  e.AirSegments[1][e.AirSegments[1].length - 1]
                                    .Destination
                                }{" "}
                                {new Date(
                                  e.AirSegments[1][
                                    e.AirSegments[1].length - 1
                                  ].ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {
                                // e.Segments[1][e.Segments[1].length - 1]
                                // .Destination.Airport.CityName
                              }
                              <div className="date_time">
                                {formatTime(
                                  e.AirSegments[1][e.AirSegments[1].length - 1]
                                    .ArrivalDateTime
                                )}
                              </div>
                            </li>
                          </ul>
                          {/* ))} */}
                        </div>
                        <div className="book_flight">
                          <div className="refundable clr_green">
                            <span>
                              {e.Refundable ? "Refundable" : "Non-Refundable"}
                            </span>
                          </div>
                          <span className="fli_price airlineprice">
                            <span style={{ fontSize: "12px", color: "#000" }}>
                              {fares[e.FareType]}
                            </span>{" "}
                            &nbsp;
                            <span className="mainprice">
                              ₹
                              {e.AirlineFares[0].GrossFare +
                                agentIm +
                                adminIm +
                                e.AirlineFares[1].GrossFare +
                                agentIm +
                                adminIm}
                            </span>
                            <br />
                            <span
                              style={{
                                marginTop: "5px",
                                marginBottom: "3px",
                                fontSize: "13px",
                                color: "#000",
                              }}
                            >
                              {netFaress &&
                                `₹${
                                  e.AirlineFares[0].NetFare +
                                  adminIm +
                                  e.AirlineFares[1].NetFare +
                                  adminIm
                                }`}
                            </span>
                          </span>
                          <div className="bookbtn_morefare">
                            <div className="book_btn">
                              <Button
                                className="btn small colorful-transparent hover-colorful btn_green checkout_flight"
                                faretype="offer 1"
                                fareindex="OB2"
                                faretraceid="23c6201f-d7df-45aa-a04e-9d2b533ea956"
                                fareuid="OB2"
                                resultindex="LS1DOFQuIyxYLCYpQSxWLFEsMGBgCmAK"
                                flightindex="NDU0UFEuMzk1MyMoUi01NSwsQyhWNTRQUS4zNGAKYAo="
                                onClick={() => handlebookmodal(e.ContractId)}
                              >
                                Book Now
                              </Button>
                            </div>
                            <div className="more_farebtn"></div>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="flight_details">
                          <div className="main_flight_btn">
                            <a
                              is_btn="detail"
                              href="javascript:;"
                              sessionid="6648380ba3c11"
                              flightindex="UL196UL225UL226UL195"
                              className="details_btn flightdetailajax"
                              onClick={() => {
                                handleClick(e.ContractId);
                              }}
                            >
                              Flight Details
                            </a>
                          </div>
                          {/* <div
                          className="flight_details_info"
                          is_show="0"
                          id="show_UL196UL225UL226UL195"
                        >
                          <h4>Please wait loading...</h4>
                        </div> */}
                        </div>
                        {activeId === e.ContractId && (
                          <InternationalFlightDetail
                            idx={e.ContractId}
                            flight={e}
                            adminIm={adminIm}
                            agentIm={agentIm}
                            internationalMarkup={internationalMarkup}
                            fareRules={fareRules}
                            isInterNational={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default InternationalFlightListCard;
