import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./FlightDeal.css";
import imageSources from "./Images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function FlightDeal() {
  var settings = {
    dots: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    infinite: true,
    // nextArrow: <NextBtn />,
    // prevArrow: <PreviousBtn />,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="section_padding">
      <Container>
        <h2 className="text-center">
          <b>Flights we deal in</b>
        </h2>
        <Row>
          <Slider {...settings} className="mb-5 mt-4">
            {imageSources.map((src, index) => (
              <Col key={index} md={12} sm={4} xs={12}>
                <div className="fligtLogo">
                  <span>
                    <img src={src} alt={`Flight Logo ${index}`} />
                  </span>
                </div>
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </section>
  );
}

export default FlightDeal;
