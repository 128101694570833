import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Button,
  Form,
} from "react-bootstrap";
import { FaArrowLeft, FaEdit } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";

const EditMarkup = () => {
  const [serviceType, setServiceType] = useState("");
  const [amount, setAmount] = useState("");
  const [amountType, setAmountType] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { walletData } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://admin.yatriservice.com/api/agent-markup/all/${id}`
        );
        if (response.data) {
          const { flight_type, service_fee, service_type } = response.data.data;
          setServiceType(flight_type);
          setAmount(service_fee);
          setAmountType(service_type);
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching the markup data:", error);
      }
    };
    fetchData();
  }, []);

  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };
  const handleAmountTypeChange = (e) => {
    setAmountType(e.target.value);
  };
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      AgentId: walletData.Id,
      api: 0,
      flight_type: serviceType,
      service_fee: amount,
      service_type: amountType,
      user_type: "b2b",
      markup_type: "all",
    };

    try {
      const response = await axios.put(
        `https://admin.yatriservice.com/api/agent-markup/update/${id}`,
        updatedData
      );

      if (response.status === 200) {
        navigate("/agent/mark-up");
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error updating the markup:", error.response || error);
    }
  };

  return (
    <div className="content-wrapper">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Manage Markup</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    <span style={{ color: "#fff" }}>Home</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Manage Markup</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <h3 className="card-title">Edit Markup</h3>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Flight Type{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="service_type"
                              value={serviceType}
                              onChange={handleServiceTypeChange}
                              required
                            >
                              <option value="">Select flight Type</option>
                              <option value="domestic">Domestic</option>
                              <option value="international">
                                International
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Amount
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="amount"
                              value={amount}
                              onChange={handleAmountChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Amount Type{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="service_type"
                              value={amountType}
                              onChange={handleAmountTypeChange}
                              required
                            >
                              <option value="">Select flight Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="percentage">Percentage</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} style={{ marginTop: "15px" }}>
                          <Form.Group
                            className="form-group"
                            style={{ textAlign: "right" }}
                          >
                            <Link
                              to="/agent/mark-up"
                              className="btn btn-theme-sec"
                              style={{ marginRight: "5px" }}
                            >
                              <FaArrowLeft /> Back
                            </Link>
                            <Button className="btn btn-theme" type="submit">
                              <FaEdit /> Update Markup
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default EditMarkup;
