import React from "react";
import { Container } from "react-bootstrap";
import "./TermCondition.css";
const TermCondition = () => {
  return (
    <div>
      {" "}
      <section className="bannerInner">
        <Container>
          <h1>
            <b>Terms &amp; Conditions</b>
          </h1>
        </Container>
      </section>
      <section className="section_padding">
        <Container>
          <div className="terms_item">
            <h2>1. Acceptance of Terms</h2>
            <p>
              By accessing and using the Yatri Service B2B travel portal, you
              agree to be bound by these terms and conditions. If you do not
              agree to these terms, please refrain from using our services.
            </p>
          </div>

          <div className="terms_item">
            <h2>2. Registration and Account Security</h2>
            <p>
              You must register for an account to access certain features of
              Yatri Service. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account.
            </p>
            <p>
              Yatri Service reserves the right to suspend or terminate your
              account at its discretion, without prior notice, if it is believed
              that you have violated these terms or engaged in any fraudulent
              activities.
            </p>
          </div>

          <div className="terms_item">
            <h2>3. Use of Services</h2>
            <p>
              Yatri Service provides a platform for B2B travel-related
              transactions. Users agree to use the services for legitimate
              business purposes and comply with all applicable laws and
              regulations.
            </p>
            <p>
              Users are responsible for the accuracy of the information provided
              during bookings and transactions. Yatri Service is not liable for
              any consequences resulting from inaccurate or incomplete
              information.
            </p>
          </div>

          <div className="terms_item">
            <h2>4. Payments and Fees</h2>
            <p>
              Payments for services provided by Yatri Service are subject to the
              terms agreed upon during the booking process.
            </p>
            <p>
              Yatri Service reserves the right to modify fees, charges, or
              payment terms at any time, with notice provided to users.
            </p>
          </div>

          <div className="terms_item">
            <h2>5. Intellectual Property</h2>
            <p>
              The content, logos, and trademarks on the Yatri Service platform
              are the intellectual property of Yatri Service. Users agree not to
              reproduce, distribute, or modify any content without explicit
              permission.
            </p>
          </div>

          <div className="terms_item">
            <h2>6. Privacy</h2>
            <p>
              Yatri Service values user privacy and handles personal information
              in accordance with its Privacy Policy. By using our services, you
              consent to the collection and use of your information as described
              in the Privacy Policy.
            </p>
          </div>

          <div className="terms_item">
            <h2>7. Liability and Disclaimers</h2>
            <p>
              Yatri Service is not liable for any direct, indirect, incidental,
              special, or consequential damages arising from the use of our
              services.
            </p>
            <p>
              Yatri Service does not guarantee the accuracy, completeness, or
              reliability of information provided by third-party suppliers.
            </p>
          </div>

          <div className="terms_item">
            <h2>8. Changes to Terms</h2>
            <p>
              Yatri Service reserves the right to update or modify these terms
              and conditions at any time. Users are encouraged to review the
              terms periodically. Continued use of our services after changes
              constitutes acceptance of the modified terms.
            </p>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default TermCondition;
