import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DailySalesReport.css";

const DailySalesReport = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredHotelSalesData, setFilteredHotelSalesData] = useState([]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (fromDate && toDate) {
      const filteredData = hotelSalesData.filter((hotelsales) => {
        const hotelsalesDate = new Date(hotelsales.date);
        return hotelsalesDate >= fromDate && hotelsalesDate <= toDate;
      });
      setFilteredHotelSalesData(filteredData);
    } else {
      setFilteredHotelSalesData(hotelSalesData);
    }
  };

  const hotelSalesData = [
    {
      id: 1,
      agentID: "AG001",
      hotel: "Hotel A",
      totalSales: "2000",
      date: "2024-02-10",
    },
    {
      id: 2,
      agentID: "AG002",
      hotel: "Hotel B",
      totalSales: "3000",
      date: "2024-02-17",
    },
    {
      id: 3,
      agentID: "AG003",
      hotel: "Hotel C",
      totalSales: "2500",
      date: "2024-02-16",
    },
  ];

  useState(() => {
    setFilteredHotelSalesData(hotelSalesData);
  }, []);

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Daily Sales Report</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Daily Sales Report</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <ul
                      className="nav nav-tabs nav_custom_tabs"
                      id="custom-tab"
                      role="tablist">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          id="all_report-tab"
                          data-toggle="pill"
                          to="#all_report"
                          role="tab"
                          aria-controls="all_report">
                          B2B Daily Sales Report
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content" id="custom-tab-content">
                      <div
                        className="tab-pane active show"
                        id="all_report"
                        role="tabpanel"
                        aria-labelledby="all_report-tab">
                        <div className="all_sales_report common_report">
                          <h4>Refine Your Results</h4>
                          <div className="cus_report_field">
                            <Form
                              method="get"
                              action="https://tripoholidays.com/agent/report/dailysale"
                              autoComplete="off"
                              onSubmit={handleSearch}>
                              <input type="hidden" name="type" value="b2b" />
                              <Row>
                                <Col sm={4}>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      Submission Date From
                                    </Form.Label>
                                    <DatePicker
                                      selected={fromDate}
                                      onChange={handleFromDateChange}
                                      dateFormat="yyyy-MM-dd"
                                      className="form-control commondate"
                                      placeholderText="mm/dd/yyyy"
                                      maxDate={toDate || new Date()}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group className="form-group">
                                    <Form.Label>Submission Date To</Form.Label>
                                    <DatePicker
                                      selected={toDate}
                                      onChange={handleToDateChange}
                                      dateFormat="yyyy-MM-dd"
                                      className="form-control commondate"
                                      placeholderText="mm/dd/yyyy"
                                      minDate={fromDate}
                                      maxDate={new Date()}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col
                                  sm={12}
                                  className="generate_dsr_btn text-right">
                                  <Button type="submit" className="cus_btn">
                                    Generate DSR
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="table-responsive">
                                <h4 className="table_heading">Hotel Results</h4>
                                <Table bordered hover className="domesticdata">
                                  <thead>
                                    <tr>
                                      <th>SrNo</th>
                                      <th>AgentID</th>
                                      <th>Hotel</th>
                                      <th>Total Sales</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {filteredHotelSalesData.map(
                                      (sales, index) => (
                                        <tr key={index}>
                                          <td>{sales.id}</td>
                                          <td>{sales.agentID}</td>
                                          <td>{sales.hotel}</td>
                                          <td>{sales.totalSales}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="4"></td>
                                    </tr>
                                    <tr>
                                      <td colSpan="3">
                                        <b>Gross Total</b>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "600",
                                        }}>
                                        ₹{" "}
                                        {filteredHotelSalesData
                                          .reduce(
                                            (acc, curr) =>
                                              acc + parseInt(curr.totalSales),
                                            0
                                          )
                                          .toFixed(2)}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default DailySalesReport;
