import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Breadcrumb,
} from "react-bootstrap";
import { FaFileExcel, FaPrint } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx"; // Importing xlsx library

const data = [
  {
    sn: 1,
    journeyDetail: "Dummy Journey Detail 1",
    bookingDetail: "Dummy Booking Detail 1",
    passengerDetail: "Dummy Passenger Detail 1",
    status: "Success",
    totalFare: "1000",
    adminProfit: "200",
  },
  {
    sn: 2,
    journeyDetail: "Dummy Journey Detail 2",
    bookingDetail: "Dummy Booking Detail 2",
    passengerDetail: "Dummy Passenger Detail 2",
    status: "Dummy Status 2",
    totalFare: "1500",
    adminProfit: "300",
  },
];

const exportToExcel = () => {
  const fileName = "Profit_Loss.xlsx";
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Profit_Loss");
  XLSX.writeFile(workbook, fileName);
};



const CardCustomMenu = () => {
  return (
    <div className="full-container">
      <div>
        <ul className="custom-menu-container">
          <li className="active">
            <Link to="">Hotel</Link>
          </li>
          <li>
            <Link to="">Package</Link>
          </li>
          <li>
            <Link to="">Sightseeing</Link>
          </li>
          <li>
            <Link to="">Transfer</Link>
          </li>
        </ul>
      </div>
      <div>
        <Button
          // dataurl="https://tripoholidays.com/agent/profitloss?type=&amp;action=print"
          // href="javascript:;"
          variant="theme"
          size="sm"
          className="filter_btn"
          style={{
            background: "#a1c849",
            border: "none",
            color: "#fff",
            margin: "5px",
          }}
          //   onClick={toggleFilter}
        >
          <FaPrint className="filter-icon" /> Print
        </Button>
        <Button
          // href="/agent/profitloss?type=&amp;action=excel"
          variant="theme"
          size="sm"
          className="filter_btn"
          style={{
            background: "#ffb935",
            border: "none",
            color: "#fff",
            margin: "5px",
          }}
          onClick={exportToExcel}
        >
          <FaFileExcel className="filter-icon" /> Export
        </Button>
      </div>
    </div>
  );
};


const ProfitLoss = () => {

  // Calculate total admin profit
  const totalAdminProfit = data.reduce(
    (accumulator, currentValue) =>
      accumulator + parseInt(currentValue.adminProfit),
    0
  );

  

  return (
    <div className="content-wrapper">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Profit & Loss</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: "/agent/bookings" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Profit & Loss</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <div className="card_custom_menu">
                      <CardCustomMenu />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Col md={12}>
                      <div className="table-responsive">
                        <Table bordered hover className="no-footer">
                          <thead>
                            <tr role="row">
                              <th className="no-sort sorting_disabled">
                                S. N.
                              </th>
                              <th className="sorting_disabled">
                                Journey Detail
                              </th>
                              <th className="sorting_disabled">
                                Booking Detail
                              </th>
                              <th className="sorting_disabled">
                                Passenger Detail
                              </th>
                              <th className="sorting_disabled">Status</th>
                              <th className="sorting_disabled">Total Fare</th>
                              <th className="sorting_disabled">Admin Profit</th>
                            </tr>
                          </thead>
                          <tbody className="tdata booking_data">
                            {data.map((data, index) => (
                              <tr key={index} role="row">
                                <td>{data.sn}</td>
                                <td>{data.journeyDetail}</td>
                                <td>{data.bookingDetail}</td>
                                <td>{data.passengerDetail}</td>
                                <td>
                                  <div className="check_status">
                                    <Button
                                      variant="link"
                                      className={`chk_stat_btn ${
                                        data.status === "Pending"
                                          ? "yellow_clr"
                                          : data.status === "Success"
                                          ? "green_clr"
                                          : "red_clr"
                                      }`}>
                                      {data.status}
                                    </Button>
                                  </div>
                                </td>
                                <td>{data.totalFare}</td>
                                <td style={{ fontWeight: 600 }}>
                                  ₹ {data.adminProfit}
                                </td>
                              </tr>
                            ))}
                            {data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="6">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="7">
                                <div className="total_value">
                                  <span style={{ textAlign: "right", fontSize: "16px", lineHeight: "21px", color: "#ffb935", display: "block", marginTop: "5px" }}>Total Admin Profit: ₹ {totalAdminProfit.toFixed(2)}</span>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default ProfitLoss;
