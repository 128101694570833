import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import Slider from "rc-slider";
import styled from "styled-components";
import "rc-slider/assets/index.css";
import "./RoundTrips.css";
import { ImPriceTags } from "react-icons/im";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { DayPickerRangeController } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// import flight from './flight.png'
// import plane from "/Images/plane.png";
import { MdFlight, MdOutlineFlight } from "react-icons/md";
import { FaArrowRightArrowLeft, FaClock } from "react-icons/fa6";
// import { ImCross } from "react-icons/im";
import { GiAirplaneArrival, GiAirplaneDeparture } from "react-icons/gi";
import { GoStopwatch } from "react-icons/go";
import { FaChevronDown, FaFilter } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import {
  IoRadioButtonOff,
  IoRadioButtonOffSharp,
  IoRadioButtonOnOutline,
  IoRadioButtonOnSharp,
} from "react-icons/io5";
import RoundTripSkeleton from "./RoundTripSkeleton";
import RoundTripDetail from "./RoundTripDetail";
// import { cities } from "../Flight/BookingSearch/FlightBooking";

import { RiRadioButtonLine } from "react-icons/ri";
import axios from "axios";
import RoundList from "./RoundList";
import FlightForm from "../OneWay/FlightForm";
import FilterBar from "./FilterBar";
import { flightSearch } from "../../../redux/services/operations/flight";
import { LuSearchCode } from "react-icons/lu";
import { cities12 } from "../../../Cities";
import { Markup } from "../../../redux/services/operations/markup";
import { resetTimer } from "../../../redux/slices/flightSlice";














const flightClassDefaultValue = (flightCabinClass) => {
  switch (parseInt(flightCabinClass)) {
    case 2:
      return "2"; // Economy
    case 3:
      return "3"; // PremiumEconomy
    case 4:
      return "4"; // Business
    case 6:
      return "6"; // First
    default:
      return ""; // Default to no selection
  }
};

const sliderItems = [
  { date: "Oct 03", price: "$ 7845" },
  { date: "Oct 04", price: "$ 5954" },
  { date: "Oct 05", price: "$ 4155" },
  { date: "Oct 06", price: "$ 5953" },
  { date: "Oct 07", price: "$ 5495" },
  { date: "Oct 08", price: "$ 5953" },
  { date: "Oct 09", price: "$ 4155" },
];

const CalenderSliderContent = styled.div`
  border: 1px solid rgb(228, 228, 228);
  border-right: none;
  border-block: none;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RoundTrips = () => {
  // const [minFare, setMinFare] = useState(500);
  // const [maxFare, setMaxFare] = useState(10000);
  // const [sliderValue, setSliderValue] = useState([500, 5000]);

  const fares = {
    0: "Normal",
    1: "Special",
    3: "UserFare",
    4: "CorporateFare",
    5: "CouponFare",
    6: "SMEFare",
    7: "GDSFare",
    8: "Default",
    9: "GoSmart",
    10: "GoSpecial",
    11: "GoSmartCorporate",
    12: "GoMarine",
    13: "GoBusiness",
    14: "GoBusinessCorporat",
    15: "GoRound",
    16: "SpiceSaver",
    17: "SpiceCorp",
    18: "SpiceSME",
    19: "SpiceCoupon",
    20: "GoFlexi",
    21: "SpiceCorporate",
    22: "UKSpecial",
    23: "GoSmartCorporateSp",
    24: "CrpcFare",
    25: "GoSpecialV2",
    26: "AkasaNormal",
    27: "AkasaAV",
    28: "AkasaSpecial",
    29: "AkasaCorporate",
    30: "AkasaCorpSelect",
    31: "EchoPECO",
    32: "EchoPCLS",
    33: "IndgoFlexi",
    34: "IndigoTactial",
    35: "IndigoCoupon",
    36: "VistaFlex",
    37: "AISME",
    38: "STU",
    39: "IXSme",
    40: "VVIP",
    41: "SUP6E",
    42: "AIBUS",
  };

  const [tripsActive, setTripsActive] = useState(false);
  const [travellerActive, setTravellerActive] = useState(false);
  // const [activeId, setActiveId] = useState(1);
  // const { token } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data: routeParams } = useParams();
  const dispatch = useDispatch();
  const [search, setSearch] = useState([]);
  const searched = useSelector((state) => state.flight.search);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [activeId2, setActiveId2] = useState(null);
  const [rooms, setRooms] = useState([{ adults: 1, children: 0, infants: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [calVisible, setCalVisible] = useState(false);
  const [clickDestination, SetClickDestination] = useState(false);
  const [clickDestination2, SetClickDestination2] = useState(false);
  const [destinationCity, setDestinationCity] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [destinationCity2, setDestinationCity2] = useState("");
  const [selectedCityCode2, setSelectedCityCode2] = useState("");
  const [showModal, setShowModal] = useState(null);
  const [Visible, setVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [checkedStops, setCheckedStops] = useState([
    "non-stop",
    "1-stop",
    "2-stops",
  ]);
  const timer = useSelector((state) => state.flight.timer);
 
  const [showsessionModal, setShowsessionModal] = useState(false);

  // Effect to open the modal when the timer hits 0
  useEffect(() => {
    if (timer.minutes === 0 && timer.seconds === 0) {
      setShowsessionModal(true);
    }
  }, [timer]);

  // Function to handle closing the modal and resetting the timer or starting a new search
  const handleOk = () => {
    setShowsessionModal(false);
    dispatch(resetTimer());
    window.location.reload();
    // Optionally, navigate to the search page or trigger a new search
  };

  const InboundResullt = (flight) => {
    console.log("Selected Inbound flight:", flight);
    setSelectedFlight(flight);
  };

  const InboundResullt2 = (flight) => {
    console.log("Selected Outbound flight:", flight);
    setSelectedFlight2(flight);
  };
  const [deptimeRange, setdepTimeRange] = useState([0, 0]);
  const [arrtimeRange, setarrTimeRange] = useState([0, 0]);
  const [filteredInboundData, setFilteredInboundData] = useState(
    search.length > 0 ? search[0] : []
  );
  const [filteredOutboundData, setFilteredOutboundData] = useState(
    search.length > 1 ? search[1] : []
  );
  const [airlines, setAirlines] = useState([]);
  const [minFare, setMinFare] = useState(0);
  const [maxFare, setMaxFare] = useState(0);
  const [cityData, setCityData] = useState([]);
  const { walletData } = useSelector((state) => state.auth);

  useEffect(() => {
    const searchData = parseSearchParams(routeParams);
    setDataSearch(searchData);
    updateRoomsData(searchData);
    updateDates(searchData);
    if (parseInt(searchData.JourneyType) === 0) handleSearchFlight();
    else if (parseInt(searchData.JourneyType) === 1) handleSearchFlightRound();
    else if (parseInt(searchData.JourneyType) === 2)
      handleSearchFlightMultiPle();
    setDestinationCity(searchData.Segments[0].Origin);
    setDestinationCity2(searchData.Segments[0].Destination);
    setSearchInput(searchData.Segments[0].Origin);
    setSearchInput2(searchData.Segments[0].Destination);
    // console.log("cabin class flight", searchData.Segments[0].FlightCabinClass)
    setSelectedOption(parseInt(searchData.Segments[0].FlightCabinClass));
    const destCity = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Origin
    );
    const destCity2 = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Destination
    );
    setDestination1(destCity);
    setDestination2(destCity2);
  }, [routeParams]);

  console.log("searchedddddd", searched);
  const [sortedSearch, setSortedSearch] = useState([]);
  useEffect(() => {
    const sortedSearch = [...searched].sort(
      (a, b) => a.AirlineFare.NetFare - b.AirlineFare.NetFare0
    );
    setSortedSearch(sortedSearch);
    console.log("Sorted Data:", sortedSearch);
  }, [searched]);

  useEffect(() => {
    if (dataSearch) {
      setIsLoading(true);
      const updatedSearchData = { ...dataSearch };
      updatedSearchData.ApiToken = token;
      // updatedSearchData.EndUserIp = "192.168.10.10";
      console.log("Search Data", updatedSearchData);
      const searchDataAgain = {
        // updatedSearchData.ApiToken : token;
        APIToken: updatedSearchData.ApiToken,
        Id: walletData && walletData.Email,
        AdultCount: updatedSearchData.AdultCount,
        ChildCount: updatedSearchData.ChildCount,
        InfantCount: updatedSearchData.InfantCount,
        Routs: [
          {
            Source: updatedSearchData.Segments[0].Origin,
            Destination: updatedSearchData.Segments[0].Destination,
            TravelDate: updatedSearchData.Segments[0].PreferredDepartureTime,
          },
          {
            Source: updatedSearchData.Segments[0].Destination,
            Destination: updatedSearchData.Segments[0].Origin,
            TravelDate: updatedSearchData.Segments[0].PreferredArrivalTime,
          },
        ],
        TripType: updatedSearchData.JourneyType,
        TypeOfClass: updatedSearchData.Segments[0].FlightCabinClass,
      };
      console.log("SEARCHDATA", searchDataAgain);
      dispatch(flightSearch(searchDataAgain, navigate))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [dataSearch, dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    setBreakpoints();
    return () => window.removeEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (search.length > 1) {
      setFilteredInboundData(search[0]);
      setFilteredOutboundData(search[1]);
    }
  }, [search]);

  useEffect(() => {
    if (search && search[0] && search[1]) {
      const airlineNames = [].concat(
        search[0] &&
          search[0].map((flight) => ({
            name: flight.AirSegments[0].AirlineName,
            selected: true,
          })),
        search[1] &&
          search[1].map((flight) => ({
            name: flight.AirSegments[0].AirlineName,
            selected: true,
          }))
      );
      const uniqueAirlines = Array.from(
        new Set(airlineNames.map((airline) => airline.name))
      );
      const uniqueAirlinesWithSelected = uniqueAirlines.map((airlineName) => ({
        name: airlineName,
        selected: true,
      }));
      setAirlines(uniqueAirlinesWithSelected);
      console.log("airlines", uniqueAirlines);
    }
  }, [search]);
  const [domesticMarkup, setDomesticMarkup] = useState(0);
  useEffect(() => {
    const storedFare = localStorage.getItem("add-dom");

    if (storedFare) {
      let finalPrice =
        storedFare !== undefined && storedFare !== null ? storedFare : 0;
      setDomesticMarkup(parseInt(finalPrice));
    } else {
      setDomesticMarkup(0);
    }
  }, [search]);
  useEffect(() => {
    if (search.length > 0) {
      const allFares = sortedSearch.reduce((all, flight) => {
        return all.concat(flight.AirlineFare.NetFare);
      }, []);
      // if (search.length > 0) {
      //   const allFares = search.reduce((all, flight) => {
      //     return all.concat(flight.AirlineFare.NetFare);
      //   }, []);

      const minFare = Math.min(...allFares);
      const maxFare = Math.max(...allFares);
      setMinFare(minFare);
      setMaxFare(maxFare);

      setSliderValue([minFare, maxFare]);
    }
  }, [search]);

  useEffect(() => {
    applyFilters();
  }, [search, sliderValue, deptimeRange, arrtimeRange, airlines, checkedStops]);

  const parseSearchParams = (data) => {
    const searchData = {
      Segments: [{}, {}],
    };
    const params = decodeURIComponent(data).split("*");

    params.forEach((param) => {
      const [key, value] = param.split("_");
      switch (key) {
        case "dest":
          searchData.Segments[0].Destination = value;
          searchData.Segments[1].Origin = value;
          break;
        case "org":
          searchData.Segments[0].Origin = value;
          searchData.Segments[1].Destination = value;
          break;
        case "dep":
          searchData.Segments[0].PreferredDepartureTime = value;
          searchData.Segments[0].PreferredArrivalTime = value;
          break;
        case "arr":
          searchData.Segments[1].PreferredArrivalTime = value;
          searchData.Segments[1].PreferredDepartureTime = value;

          break;
        case "px":
          const [adultCount, childCount, infantCount] = value.split("-");
          searchData.AdultCount = adultCount;
          searchData.ChildCount = childCount;
          searchData.InfantCount = infantCount;
          break;
        case "jt":
          searchData.JourneyType = value;
          break;
        case "cbn":
          searchData.Segments[0].FlightCabinClass = value;
          searchData.Segments[1].FlightCabinClass = value;
          break;
        default:
          break;
      }
    });

    return searchData;
  };

  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    if (sortedSearch.length > 0) {
      const onwardContracts = sortedSearch.filter(
        (contract) => contract.ContractType === "Onword"
      );
      const returnContracts = sortedSearch.filter(
        (contract) => contract.ContractType === "Return"
      );
      setSearch([onwardContracts, returnContracts]);
    }
  }, [sortedSearch]);
  console.log("dvhfdhfbgufdbhfbdefdsfbehbrejhb", search);

  const handleOptionChange = (val) => {
    setSelectedOption(parseInt(val));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tripTypeMapping = {
      OneWay: 0,
      RoundTrip: 1,
      MultiCity: 2,
    };

    // const cabin = event.target.cabinClass.value;
    const tripType = tripTypeMapping[!active ? "RoundTrip" : "OneWay"];

    const SearchData = {
      EndUserIp: "192.168.10.10",
      AdultCount: rooms[0].adults,
      ChildCount: rooms[0].children,
      InfantCount: rooms[0].infants,
      JourneyType: tripType,
      Segments: [
        {
          Origin: event.target.from.value,
          Destination: event.target.to.value,
          FlightCabinClass: selectedOption,
          PreferredDepartureTime: startDate.startOf("day").format("YYYY-MM-DD"),
          PreferredArrivalTime: endDate
            ? endDate.startOf("day").format("YYYY-MM-DD")
            : startDate.add(1, "day").startOf("day").format("YYYY-MM-DD"),
        },
      ],
    };
    if (active) {
      window.location.assign(
        `/agent/flightList/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
    }

    if (active2) {
      if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
        window.location.assign(
          `/agent/international-round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      } else {
        window.location.assign(
          `/agent/round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      }
    }
  };

  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992;

    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30);
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60);
    } else {
      setDateRangeConfig(2, 40, 50);
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize) => {
    setNumberOfMonths(numberOfMonths);
  };

  const isSameDay = (date1, date2) => date1.isSame(date2, "day");

  const renderDayContents = (day) => {
    // const fare = fares.find((item) => isSameDay(day, item.date));
    const isStartDate = startDate && isSameDay(day, startDate);
    const isEndDate = endDate && isSameDay(day, endDate);
    const isInRange =
      startDate && endDate && day.isBetween(startDate, endDate, "day", "[]");

    let classNames = ["DayPicker-Day"];
    if (isStartDate) classNames.push("DayPicker-Day--start");
    if (isEndDate) classNames.push("DayPicker-Day--end");
    if (isInRange) classNames.push("DayPicker-Day--range");

    return (
      <div className={classNames.join(" ")}>
        <span style={{ fontWeight: "600", fontSize: "13px" }}>
          {day.format("D")}
        </span>
        <br />
        {/* {fare && (
          <span
            style={{ fontSize: "10px", color: "#2d3290", fontWeight: "600" }}
          >
            {fare.fare}
          </span>
        )} */}
      </div>
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFocusedInput(null);
      setCalVisible(false);
    } else if (startDate) {
      setFocusedInput("endDate");
    }
  };

  const toggleCalendar = (input) => {
    setCalVisible(!calVisible);
    if (!calVisible) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        setCalVisible(!calVisible);
        if (!calVisible) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const updateRoomsData = (searchData) => {
    const updatedRooms = [
      {
        adults: parseInt(searchData.AdultCount),
        children: parseInt(searchData.ChildCount),
        infants: parseInt(searchData.InfantCount),
      },
    ];
    setRooms(updatedRooms);
  };

  const updateDates = (searchData) => {
    const departureTime = moment(searchData.Segments[0].PreferredDepartureTime);
    const arrivalTime = moment(searchData.Segments[1].PreferredArrivalTime);
    setStartDate(departureTime);
    // active2 &&
    setEndDate(arrivalTime);
  };

  const handleClick = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const [isItemSelected, setIsItemSelected] = useState(false);
  const [isItemSelected2, setIsItemSelected2] = useState(false);
  const [destination1, setDestination1] = useState("");
  const [destination2, setDestination2] = useState("");
  const [netFare, setNetFare] = useState(false);

  const [searchInput, setSearchInput] = useState(destinationCity);
  const [searchInput2, setSearchInput2] = useState(destinationCity2);
  const [cities2, setCities2] = useState([]);
  const [cities22, setCities22] = useState([]);
  const fetchDatas = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        console.log("RESULTS", results);
        setCities2(results);
      });
  };

  const handleInputChange = (value) => {
    // value.toLowerCase();
    setSearchInput(value.toLowerCase());
    fetchDatas(value);
    SetClickDestination(true);
  };
  const fetchDatass = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        // console.log("RESULTS",results)
        setCities22(results);
      });
  };
  const handleInputChange2 = (value) => {
    setSearchInput2(value.toLowerCase());
    fetchDatass(value);
    SetClickDestination2(true);
  };
  const handleCitySelect = (city) => {
    setDestinationCity(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode(city["AIRPORTCODE"]);
    SetClickDestination(false); // Close the city suggestion div
    setSearchInput(`${city["CITYCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected(true);
    setDestination1(city);
  };

  const handleCitySelect2 = (city) => {
    setDestinationCity2(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode2(city["AIRPORTCODE"]);
    SetClickDestination2(false); // Close the city suggestion div
    setSearchInput2(`${city["CITYCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected2(true);
    setDestination2(city);
  };

  const handleMoreFare = (idx) => {
    setShowModal(showModal === idx ? null : idx);
  };
  const handlebookmodal = (idx) => {
    navigate(`/agent/flight-detail/${encodeURIComponent(idx)}/false`);
    setShowModal(false);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setVisible(scrollTop > 300);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleCheckedstops = (stopType) => {
    if (checkedStops.includes(stopType)) {
      setCheckedStops(checkedStops.filter((stop) => stop !== stopType));
    } else {
      setCheckedStops([...checkedStops, stopType]);
    }
  };

  const handledepTimeFilter = (range) => {
    if (deptimeRange[0] === range[0] && deptimeRange[1] === range[1])
      setdepTimeRange([0, 0]);
    else setdepTimeRange(range);
  };

  const handlearrTimeFilter = (range) => {
    if (arrtimeRange[0] === range[0] && arrtimeRange[1] === range[1])
      setarrTimeRange([0, 0]);
    else setarrTimeRange(range);
  };

  const applyFilters = () => {
    if (search.length === 0 || isLoading) {
      return;
    }

    const selectedAirlines = airlines
      .filter((airline) => airline.selected)
      .map((airline) => airline.name);

    const newFilteredInboundData = search[0].filter((e) => {
      const fareInRange =
        e.AirlineFare.NetFare >= sliderValue[0] &&
        e.AirlineFare.NetFare <= sliderValue[1];

      const depTimeInRange =
        (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
        (new Date(e.AirSegments[0].DepartureDateTime).getHours() >=
          deptimeRange[0] &&
          new Date(e.AirSegments[0].DepartureDateTime).getHours() <=
            deptimeRange[1]);

      const arrTimeInRange =
        (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
        (new Date(e.AirSegments[0].ArrivalDateTime).getHours() >=
          arrtimeRange[0] &&
          new Date(e.AirSegments[0].ArrivalDateTime).getHours() <=
            arrtimeRange[1]);

      const isAirlineSelected =
        selectedAirlines.length === 0 ||
        selectedAirlines.includes(e.AirSegments[0].AirlineName);

      let stopCountMatch = false;
      if (checkedStops.length === 0) {
        stopCountMatch = true;
      } else {
        const stopCount = e.AirSegments.length - 1;
        if (checkedStops.includes("non-stop") && stopCount === 0) {
          stopCountMatch = true;
        } else if (checkedStops.includes("1-stop") && stopCount === 1) {
          stopCountMatch = true;
        } else if (checkedStops.includes("2-stop") && stopCount === 2) {
          stopCountMatch = true;
        } else if (checkedStops.includes("3-stop") && stopCount >= 3) {
          stopCountMatch = true;
        }
      }
      return (
        fareInRange &&
        depTimeInRange &&
        arrTimeInRange &&
        isAirlineSelected &&
        stopCountMatch
      );
    });

    const newFilteredOutboundData =
      search[1] &&
      search[1].filter((e) => {
        const fareInRange =
          e.AirlineFare.NetFare >= sliderValue[0] &&
          e.AirlineFare.NetFare <= sliderValue[1];

        const depTimeInRange =
          (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
          (new Date(e.AirSegments[0].DepartureDateTime).getHours() >=
            deptimeRange[0] &&
            new Date(e.AirSegments[0].DepartureDateTime).getHours() <=
              deptimeRange[1]);

        const arrTimeInRange =
          (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
          (new Date(e.AirSegments[0].ArrivalDateTime).getHours() >=
            arrtimeRange[0] &&
            new Date(e.AirSegments[0].ArrivalDateTime).getHours() <=
              arrtimeRange[1]);

        const isAirlineSelected =
          selectedAirlines.length === 0 ||
          selectedAirlines.includes(e.AirSegments[0].AirlineName);

        let stopCountMatch = false;
        if (checkedStops.length === 0) {
          stopCountMatch = true;
        } else {
          const stopCount = e.AirSegments.length - 1;
          if (checkedStops.includes("non-stop") && stopCount === 0) {
            stopCountMatch = true;
          } else if (checkedStops.includes("1-stop") && stopCount === 1) {
            stopCountMatch = true;
          } else if (checkedStops.includes("2-stop") && stopCount >= 2) {
            stopCountMatch = true;
          }
          //  else if (checkedStops.includes("3-stop") && stopCount >= 3) {
          //   stopCountMatch = true;
          // }
        }
        return (
          fareInRange &&
          depTimeInRange &&
          arrTimeInRange &&
          isAirlineSelected &&
          stopCountMatch
        );
      });
    setFilteredInboundData(newFilteredInboundData);
    InboundResullt(newFilteredInboundData[0]);
    console.log("Filtered Inbound Data:", newFilteredInboundData);
    setFilteredOutboundData(newFilteredOutboundData);
    InboundResullt2(newFilteredOutboundData && newFilteredOutboundData[1]);
    console.log("Filtered Outbound Data:", newFilteredOutboundData);
  };

  const handleChecked = (airlineName) => {
    const updatedAirlines = airlines.map((airline) =>
      airline.name === airlineName
        ? { ...airline, selected: !airline.selected }
        : airline
    );
    setAirlines(updatedAirlines);
  };

  const handleShowAllairlinenames = (event) => {
    const isChecked = event.target.checked;
    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: isChecked,
    }));
    setAirlines(updatedAirlines);
  };

  const handleShowAllStops = (event) => {
    if (event.target.checked) {
      setCheckedStops(["non-stop", "1-stop", "2-stops"]);
    } else {
      setCheckedStops([]);
    }
  };

  const clearAllFilters = () => {
    setSliderValue([minFare, maxFare]);
    setdepTimeRange([0, 0]);
    setarrTimeRange([0, 0]);
    setCheckedStops(["non-stop", "1-stop", "2-stops"]);

    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: true,
    }));
    setAirlines(updatedAirlines);
  };

  const [selectedFlight, setSelectedFlight] = useState(
    filteredInboundData && filteredInboundData[0]
  );
  const [selectedFlight2, setSelectedFlight2] = useState(
    filteredOutboundData && filteredOutboundData[0]
  );
  console.log("Selectet flu8t4gligewriylfgkw", selectedFlight);

  // const InboundResullt = (flight) => {
  //   console.log("Selected Inbound flight:", flight);
  //   setSelectedFlight(flight);
  // };

  // const InboundResullt2 = (flight) => {
  //   console.log("Selected Outbound flight:", flight);
  //   setSelectedFlight2(flight);
  // };

  const storedBookingKey = String(sessionStorage.getItem("bookingId"));
  const storedToken = String(localStorage.getItem("token"));
  const [tokenss, setTokenss] = useState(storedToken);
  const [bookingKeyss, setBookingKeyss] = useState(storedBookingKey);
  const [tokensss, setTokensss] = useState();
  const [bookingKey, setBookingKey] = useState();
  useEffect(() => {
    const storedBookingKey = String(sessionStorage.getItem("bookingId"));
    const storedToken = String(localStorage.getItem("token"));

    if (storedBookingKey && storedToken) {
      setBookingKey(storedBookingKey);
      setTokensss(storedToken);
      // console.log("token", storedToken);
      // console.log("traceId", storedTraceId);
    } else {
      setBookingKey(undefined);
      setTokensss(undefined);
    }
    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
  }, [search]);
  const [fareRules, setFareRules] = useState("");
  const handleClickDetail = async (id) => {
    setActiveId(activeId === id ? null : id);
    const response1 = await axios.post(
      "https://admin.yatriservice.com/api/flightfarerule",
      {
        APIToken: tokensss || tokenss,
        BookingKey: bookingKey || bookingKeyss,
        ContractId: id,
      }
    );
    setFareRules(response1.data.data.FareRule);
  };
  const [errorss, setErrorss] = useState(null);
  const handleBookRound = () => {
    if (selectedFlight.FareType === selectedFlight2.FareType) {
      navigate(
        `/agent/flight-detail/${encodeURIComponent(
          selectedFlight.ContractId
        )}/${encodeURIComponent(selectedFlight2.ContractId)}`
      );
    } else {
      setErrorss(
        "This Combination is not available Please Check with another combination"
      );
    }
    // navigate(
    //   `/agent/flight-detail/${encodeURIComponent(
    //     selectedFlight.ContractId
    //   )}/${encodeURIComponent(selectedFlight2.ContractId)}`
    // );
  };

  const filteredCities = cities2.filter(
    (city) =>
      city["AIRPORTNAME"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["AIRPORTCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["CITYCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["COUNTRYNAME"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["COUNTRYCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["CITYNAME"].toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredCities2 = cities2.filter(
    (city) =>
      city["AIRPORTNAME"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["AIRPORTCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["CITYCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["COUNTRYNAME"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["COUNTRYCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["CITYNAME"].toLowerCase().includes(searchInput2.toLowerCase())
  );
  console.log("SEARCHHHHHHHH", search);
  const { adminDm, agentDm } = useSelector((state) => state.markUp);
  console.log("admindm agentdm", adminDm, agentDm);


    const calculateTotalFare = (response, markup, agentmarkup) => {
      const PexFareDetailss =
        (selectedFlight && selectedFlight?.PexFareDetails) || [];
      
      // Calculate total passengers (TotPax)
      const totalPax = PexFareDetailss.reduce(
        (sum, detail) => sum + (detail.TotPax || 0),
        0
      );
      const totalFare = response + markup * totalPax + agentmarkup * totalPax;

      return totalFare;
    };






  return (
    <div id="full-container" className="roundtrippg">
      <FlightForm
        dataSearch={dataSearch}
        handleSubmit={handleSubmit}
        tripsActive={tripsActive}
        active={active}
        active2={active2}
        active3={active3}
        handleSearchFlight={handleSearchFlight}
        handleSearchFlightRound={handleSearchFlightRound}
        handleSearchFlightMultiPle={handleSearchFlightMultiPle}
        setTripsActive={setTripsActive}
        searchInput={searchInput}
        searchInput2={searchInput2}
        SetClickDestination={SetClickDestination}
        SetClickDestination2={SetClickDestination2}
        isItemSelected={isItemSelected}
        isItemSelected2={isItemSelected2}
        handleInputChange={handleInputChange}
        handleInputChange2={handleInputChange2}
        filteredCities={cities2}
        filteredCities2={cities22}
        clickDestination={clickDestination}
        clickDestination2={clickDestination2}
        handleCitySelect={handleCitySelect}
        handleCitySelect2={handleCitySelect2}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        toggleCalendar={toggleCalendar}
        rooms={rooms}
        setTravellerActive={setTravellerActive}
        travellerActive={travellerActive}
        updateRoom={updateRoom}
        selectedOption={selectedOption}
        handleOptionChange={handleOptionChange}
        calVisible={calVisible}
        setCalVisible={setCalVisible}
        handleDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
        renderDayContents={renderDayContents}
        numberOfMonths={numberOfMonths}
      />

      <section id="content">
        <div className="content-wrap">
          <div className="section-flat single_sec_flat">
            <div className="section-content">
              <div className="modify_search_sec">
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className="page-single-content sidebar-left mt-10 roundtrip_search custom_page_search">
                        <Row>
                          <FilterBar
                            showFilter={showFilter}
                            minFare={minFare}
                            maxFare={maxFare}
                            markup={adminDm}
                            calculateTotalFare={calculateTotalFare}
                            agentmarkup={agentDm}
                            sliderValue={sliderValue}
                            clearAllFilters={clearAllFilters}
                            handleSliderChange={handleSliderChange}
                            handledepTimeFilter={handledepTimeFilter}
                            deptimeRange={deptimeRange}
                            arrtimeRange={arrtimeRange}
                            handlearrTimeFilter={handlearrTimeFilter}
                            handleShowAllStops={handleShowAllStops}
                            checkedStops={checkedStops}
                            handleCheckedstops={handleCheckedstops}
                            handleShowAllairlinenames={
                              handleShowAllairlinenames
                            }
                            airlines={airlines}
                            handleChecked={handleChecked}
                            setShowFilter={setShowFilter}
                            applyFilters={applyFilters}
                          />
                          <Col
                            lg={9}
                            md={9}
                            // lgPush={3}
                            // mdPush={3}
                            sm={12}
                            className="pad_xs_10 cus_col_9"
                          >
                            <div
                              className="round-trip-fair"
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                gap: "4px",
                              }}
                            >
                              <p style={{ fontSize: "12px" }}>NetFare : </p>{" "}
                              <div className="sfp-change-trip">
                                <div
                                  onClick={() => setNetFare(!netFare)}
                                  className={`sfp-trip ${
                                    netFare ? "active" : ""
                                  }`}
                                >
                                  Show
                                </div>
                                <div
                                  onClick={() => setNetFare(!netFare)}
                                  className={`sfp-trip ${
                                    netFare ? "" : "active"
                                  }`}
                                >
                                  Hide
                                </div>
                              </div>
                            </div>
                            <div className="search_result_inner" id="offer_1">
                              <Row>
                                <RoundList
                                  search={search[0]}
                                  type="Onword"
                                  isLoading={isLoading}
                                  destinationCity={destinationCity}
                                  destinationCity2={destinationCity2}
                                  startDate={startDate}
                                  filteredInboundData={filteredInboundData}
                                  selectedFlight={selectedFlight}
                                  InboundResullt={InboundResullt}
                                  handleClickDetail={handleClickDetail}
                                  activeId={activeId}
                                  fareRules={fareRules}
                                  calculateTotalFare={calculateTotalFare}
                                  netFare={netFare}
                                  fares={fares}
                                  agentDm={agentDm}
                                  adminDm={adminDm}
                                />
                                <RoundList
                                  search={search[1]}
                                  type="Return"
                                  fares={fares}
                                  isLoading={isLoading}
                                  destinationCity={destinationCity2}
                                  destinationCity2={destinationCity}
                                  startDate={endDate}
                                  calculateTotalFare={calculateTotalFare}
                                  filteredInboundData={filteredOutboundData}
                                  selectedFlight={selectedFlight2}
                                  InboundResullt={InboundResullt2}
                                  handleClickDetail={handleClickDetail}
                                  activeId={activeId}
                                  fareRules={fareRules}
                                  netFare={netFare}
                                  adminDm={adminDm}
                                  agentDm={agentDm}
                                />
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div
        className={`round-trip_apply_filter applyFilter ${Visible && "show"}`}
        onClick={() => setShowFilter(true)}
      >
        <FaFilter /> Filter
      </div> */}
      <div className="sticky_bottom">
        <div className="container">
          <div className="row col_mob_60">
            <div className="col-sm-4 col-xs-6 brder_rgt stick_col_4">
              {selectedFlight && !isLoading ? (
                <div>
                  <div className="stk_btm_sec" id="InboundFlight">
                    <ul>
                      {" "}
                      <li className="flight_txt">
                        <img
                          className="hide_mob"
                          src={`/Images/${selectedFlight.AirlineCode}.png`}
                          alt="Air India"
                        />{" "}
                        <div className="flight_name">
                          {selectedFlight.AirSegments[0].AirlineName}
                          <span className="flight_no">
                            {selectedFlight.AirlineCode}-{" "}
                            {selectedFlight.AirSegments[0].FlightNumber}
                          </span>
                        </div>{" "}
                      </li>{" "}
                      <li className="flight_duration hide_mob">
                        {" "}
                        <div className="depart_time cus_time">
                          <span>
                            {" "}
                            {new Date(
                              selectedFlight.AirSegments[0].DepartureDateTime
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            })}
                          </span>
                          {selectedFlight.AirSegments[0].Origen}
                        </div>{" "}
                        <div className="arrow">
                          {" "}
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          />{" "}
                        </div>{" "}
                        <div className="arrive_time cus_time">
                          <span>
                            {" "}
                            {new Date(
                              selectedFlight.AirSegments[0].ArrivalDateTime
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            })}
                          </span>
                          {
                            selectedFlight.AirSegments[
                              selectedFlight.AirSegments.length - 1
                            ].Destination
                          }
                        </div>{" "}
                      </li>{" "}
                      <li className="flight_price">
                        {" "}
                        ₹
                        {calculateTotalFare(selectedFlight.AirlineFare.GrossFare,
                          adminDm,
                          agentDm)}
                        <p
                          style={{
                            fontSize: "13px",
                            textAlign: "center",
                            margin: "auto",
                            paddingLeft: "8px",
                          }}
                        >
                          {" "}
                          {netFare &&
                            `₹${selectedFlight.AirlineFare.NetFare + adminDm}`}
                        </p>
                      </li>{" "}
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-4 col-xs-6 brder_rgt stick_col_4">
              {selectedFlight2 && !isLoading ? (
                <div className="stk_btm_sec" id="OutboundFlight">
                  <ul>
                    {" "}
                    <li className="flight_txt">
                      {" "}
                      <img
                        className="hide_mob"
                        src={`/Images/${selectedFlight2.AirlineCode}.png`}
                        alt="Vistara"
                      />{" "}
                      <div className="flight_name">
                        {selectedFlight2.AirSegments[0].AirlineName}
                        <span className="flight_no">
                          {" "}
                          {selectedFlight2.AirlineCode}-{" "}
                          {selectedFlight2.AirSegments[0].FlightNumber}
                        </span>
                      </div>{" "}
                    </li>{" "}
                    <li className="flight_duration hide_mob">
                      {" "}
                      <div className="depart_time cus_time">
                        <span>
                          {" "}
                          {new Date(
                            selectedFlight2.AirSegments[0].DepartureDateTime
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </span>
                        {selectedFlight2.AirSegments[0].Origen}
                      </div>{" "}
                      <div className="arrow">
                        {" "}
                        <i
                          className="fa fa-arrow-right"
                          aria-hidden="true"
                        />{" "}
                      </div>{" "}
                      <div className="arrive_time cus_time">
                        <span>
                          {new Date(
                            selectedFlight2.AirSegments[0].ArrivalDateTime
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </span>{" "}
                        {
                          selectedFlight2.AirSegments[
                            selectedFlight2.AirSegments.length - 1
                          ].Destination
                        }
                      </div>{" "}
                    </li>{" "}
                    <li className="flight_price">
                      {" "}
                      ₹
                      {calculateTotalFare(selectedFlight2.AirlineFare.GrossFare,
                        adminDm,
                        agentDm)}
                      <p
                        style={{
                          fontSize: "13px",
                          textAlign: "center",
                          margin: "auto",
                          paddingLeft: "8px",
                        }}
                      >
                        {" "}
                        {netFare
                          ? `₹${selectedFlight2.AirlineFare.NetFare + adminDm} `
                          : null}
                      </p>
                    </li>{" "}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-4 col-xs-12 stick_col_4">
              <div className="stk_grand_total">
                {selectedFlight && selectedFlight2 && !isLoading ? (
                  <ul>
                    <li className="grandtotal_txt">
                      Grand Total{" "}
                      <span>
                        <span id="totalamount">
                          {" "}
                          ₹
                          {calculateTotalFare(selectedFlight.AirlineFare.GrossFare,
                            adminDm,
                            agentDm) +
                            calculateTotalFare(selectedFlight2.AirlineFare.GrossFare,
                            adminDm,
                            agentDm)}
                          {/* {parseInt(selectedFlight.Fare.PublishedFare) +
                            parseInt(selectedFlight2.Fare.PublishedFare)} */}
                          <p
                            style={{
                              fontSize: "13px",
                              textAlign: "center",
                              margin: "auto",
                              paddingLeft: "4px",
                            }}
                          >
                            {" "}
                            {netFare &&
                              `₹${
                                selectedFlight.AirlineFare.NetFare +
                                adminDm +
                                selectedFlight2.AirlineFare.NetFare +
                                adminDm
                              }`}
                          </p>
                        </span>
                      </span>
                    </li>
                    <li className="grandtotal_btn hide_mob">
                      <button onClick={() => handleBookRound()} className="btn">
                        BooK Now
                      </button>
                      {errorss && (
                        <p
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          {errorss}
                        </p>
                      )}
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                <div className="clearfix" />
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="col_mob_40 hide_desk">
            <div className="grandtotal_btn">
              <button onClick={() => handleBookRound()} className="btn">
                BooK Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          textAlign: "center",
          position: "fixed",
          bottom: "0px",
          background: "linear-gradient(to right, #8b3eea, #4a125d)",
          color: "#fff",
          paddingBlock: "3px",
          fontSize: "12px",
          letterSpacing: "1.5px",
          width: "100%",
          zIndex: "999",
        }}
      >
        {timer.minutes !== null
          ? `${timer.minutes} minutes ${timer.seconds} seconds`
          : "loading..."}
      </div>

      <Modal show={showsessionModal} onHide={handleOk} centered>
        <Modal.Body className="text-center">
          <FaClock size={50} style={{ color: "#8b3eea" }} />
          <h4 className="mt-3">Your session is expired</h4>
          <p>Click OK to continue with a new search</p>
          <Button
            variant="primary"
            onClick={handleOk}
            style={{
              background: "#8b3eea",
              border: "none",
              padding: "10px 20px",
              fontSize: "16px",
            }}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoundTrips;
