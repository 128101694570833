import React from "react";
import { FaRegThumbsUp } from "react-icons/fa";
import './FareRule.css'

const FareRule = ({fareRule}) => {
  return (
    <div className="m-info-tips2 mar20" style={{ display: "block" }}>
      {/* <div className="bg-head">
        <div className="fltl-mr">
          <FaRegThumbsUp style={{ textAlign: "center", marginTop: "5px" }} />
        </div>
        <div className="fltl">
          <h2 className="m-info-tips2_tit">Good to Know</h2>
          <p className="grn-txt 25">Information you should know &nbsp;</p>
        </div>
        <div className="clr"></div>
      </div> */}
      <div className="left_gtk" style={{maxHeight:"300px", overflow:"auto", scrollbarWidth:"thin"}}>
        <div>
          <div>
            <ul style={{padding:'10px'}} className="fare-rule_div">
                <li dangerouslySetInnerHTML={{ __html: fareRule }}></li>
              {/* {fareRule.map((item, index) => (
                <li key={index} className="m-info-tips2_item">
                  <p className="txt-sb">{item}</p>
                </li>
              ))} */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FareRule;
