import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import datass from "./data";
const Invoice = () => {
  const [item, setItem] = useState(datass);
  console.log("ITEMSSS", item);
  const { walletData } = useSelector((state) => state.auth);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const formatDateTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();
    const formattedTime = `${hours}:${String(minutes).padStart(
      2,
      "0"
    )} ${ampm}`;
    const formattedDate = `${day}-${month}-${year}`;
    return `${formattedDate}`;
  };

  return (
    <>
      <div
        style={{
          maxWidth: 1200,
          margin: "15px auto",
          width: "100%",
          padding: 25,
          fontSize: 14,
          background: "#fff",
        }}
      >
        <table
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%", borderSpacing: 0, borderCollapse: "colapse" }}
        >
          <tbody>
            <tr>
              <td style={{ border: "0px none", paddingBottom: 15 }}>
                <table
                  style={{
                    width: "100%",
                    borderSpacing: 0,
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <b>From</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 16 }}>
                        <b> YatriServices </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address: </strong>
                        Delhi, India
                        <br />
                        +91-9721647550
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Email: </strong> Support@yatriservice.com
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>phone:</strong> +91-9721647550
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td
                style={{
                  textAlign: "right",
                  border: "0px none",
                  paddingBottom: 15,
                }}
              >
                <table
                  style={{
                    width: "100%",
                    borderSpacing: 0,
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <b>To</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 16 }}>
                        <b> {walletData && walletData.CompanyName}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>phone:</strong> {walletData && walletData.Phone}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Email: </strong>{" "}
                        {walletData && walletData.Email}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Airline OB PNR :</strong>{" "}
                        {item.booking_response.AirlinePnr}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {item.booking_response.Contracts[0] && (
              <>
                <tr>
                  <td
                    colSpan={2}
                    style={{
                      padding: "7px 5px",
                      background: "#eee",
                      borderLeft: "3px solid #e3111a",
                    }}
                  >
                    Out Bound Detail
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ paddingBottom: 15, paddingTop: 5 }}>
                    <table
                      style={{
                        width: "100%",
                        borderSpacing: 0,
                        borderCollapse: "collapse",
                        border: "1px solid #000",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Passenger
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Sector
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Travel on
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Airline PNR
                          </th>
                        </tr>
                      </thead>
                      {item.booking_response.Contracts[0] && (
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                borderRight: "1px solid #000",
                              }}
                            >
                              {item.booking_response.Contracts[0] &&
                                item.booking_response.Flightpassenger.map(
                                  (item) => (
                                    <p>
                                      {item.FirstName} {item.LastName}
                                    </p>
                                  )
                                )}
                            </td>
                            {item.booking_response.Contracts[0] &&
                              item.booking_response.Contracts[0].AirSegments.map(
                                (items) => (
                                  <td
                                    style={{
                                      borderLeft: "1px solid #000",
                                      padding: "7px 12px",
                                      borderRight: "1px solid #000",
                                    }}
                                  >
                                    {items.Origen} - {items.Destination}
                                  </td>
                                )
                              )}
                            {item.booking_response.Contracts[0].AirSegments.map(
                              (items) => (
                                <td
                                  style={{
                                    borderLeft: "1px solid #000",
                                    padding: "7px 12px",
                                    borderRight: "1px solid #000",
                                  }}
                                >
                                  {" "}
                                  {formatDateTime(items.DepartureDateTime)}
                                </td>
                              )
                            )}
                            <td
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                borderRight: "1px solid #000",
                              }}
                            >
                              {" "}
                              {item.booking_response.AirlinePnr}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
              </>
            )}
            {item.booking_response.Contracts[1] && (
              <>
                <tr>
                  <td
                    colSpan={2}
                    style={{
                      padding: "7px 5px",
                      background: "#eee",
                      borderLeft: "3px solid #e3111a",
                    }}
                  >
                    In Bound Detail
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ paddingBottom: 15, paddingTop: 5 }}>
                    <table
                      style={{
                        width: "100%",
                        borderSpacing: 0,
                        borderCollapse: "collapse",
                        border: "1px solid #000",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Passenger
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Sector
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Travel on
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              backgroundColor: "#f3f3f3",
                              borderBottom: "1px solid #000",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Airline PNR
                          </th>
                        </tr>
                      </thead>
                      {item.booking_response.Contracts[1] && (
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                borderRight: "1px solid #000",
                              }}
                            >
                              {item.booking_response.Contracts[1] &&
                                item.booking_response.Flightpassenger.map(
                                  (item) => (
                                    <p>
                                      {item.FirstName} {item.LastName}
                                    </p>
                                  )
                                )}
                            </td>
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[1].AirSegments.map(
                                (items) => (
                                  <td
                                    style={{
                                      borderLeft: "1px solid #000",
                                      padding: "7px 12px",
                                      borderRight: "1px solid #000",
                                    }}
                                  >
                                    {items.Origen} - {items.Destination}
                                  </td>
                                )
                              )}
                            {item.booking_response.Contracts[1].AirSegments.map(
                              (items) => (
                                <td
                                  style={{
                                    borderLeft: "1px solid #000",
                                    padding: "7px 12px",
                                    borderRight: "1px solid #000",
                                  }}
                                >
                                  {" "}
                                  {formatDateTime(items.DepartureDateTime)}
                                </td>
                              )
                            )}
                            <td
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                borderRight: "1px solid #000",
                              }}
                            >
                              {" "}
                              {item.booking_response.AirlinePnr}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td
                colSpan={2}
                style={{
                  padding: "7px 5px",
                  background: "#eee",
                  borderLeft: "3px solid #e3111a",
                }}
              >
                Fare Details
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ paddingBottom: 15, paddingTop: 5 }}>
                <table
                  style={{
                    width: "100%",
                    borderSpacing: 0,
                    borderCollapse: "collapse",
                    border: "1px solid #000",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        YQ
                      </th>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Taxes
                      </th>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Discount
                      </th>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Service Fee{" "}
                      </th>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Basefare
                      </th>
                      {/* <th style="border-left:1px solid #000; padding: 7px 12px;background-color: #f3f3f3;border-bottom: 1px solid #000;border-right: 1px solid #000;">Netfare</th> */}
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        GrossFare
                      </th>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Markup
                      </th>
                      <th
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Total Fare
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item && (
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare.YQTx}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .YQTx +
                              item.booking_response.Contracts[1].AirlineFare
                                .YQTx}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .TaxFare}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .TaxFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .TaxFare}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .Discount}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .Discount +
                              item.booking_response.Contracts[1].AirlineFare
                                .Discount}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .ServiceCharge}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .BaseFare}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .BaseFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .BaseFare}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .GrossFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 0
                        </td>

                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .GrossFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        ₹ 0
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        {" "}
                        ₹ 651
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        {" "}
                        ₹ 0
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        {" "}
                        10
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        ₹ 2957
                      </td>

                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        ₹ 3608
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        ₹ 100.00
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        ₹ 3718
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ paddingBottom: 15 }}>
                <table
                  style={{
                    width: "100%",
                    borderSpacing: 0,
                    borderCollapse: "collapse",
                    border: "1px solid #000",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan={2}
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                          textAlign: "center",
                        }}
                      >
                        (Reimbursement of air ticket issued by airlines)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Tax And Additional Charge
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        ₹{" "}
                        {!item.booking_response.Contracts[1] &&
                          item.booking_response.Contracts[0].AirlineFare
                            .ServiceCharge +
                            item.booking_response.Contracts[0].AirlineFare
                              .TaxFare}
                        {item.booking_response.Contracts[1] &&
                          item.booking_response.Contracts[0].AirlineFare
                            .TaxFare +
                            item.booking_response.Contracts[0].AirlineFare
                              .ServiceCharge +
                            item.booking_response.Contracts[1].AirlineFare
                              .TaxFare +
                            item.booking_response.Contracts[1].AirlineFare
                              .ServiceCharge}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Gross Total{" "}
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                        }}
                      >
                        {" "}
                        ₹ 3718{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ paddingBottom: 15 }}>
                <table
                  style={{
                    width: "100%",
                    borderSpacing: 0,
                    borderCollapse: "collapse",
                    border: "1px solid #000",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan={2}
                        style={{
                          borderLeft: "1px solid #000",
                          padding: "7px 12px",
                          backgroundColor: "#f3f3f3",
                          borderBottom: "1px solid #000",
                          borderRight: "1px solid #000",
                          textAlign: "left",
                        }}
                      >
                        Terms
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: "3px 3px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Without original invoice no refund is permissible.
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "3px 3px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Interest @ 24% will be charged on delayed payment.
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "3px 3px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Cheque to be drawn in favour of "company name".
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "3px 3px",
                          borderRight: "1px solid #000",
                        }}
                      >
                        Kindly check all details carefully to avoid un-necessary
                        complications.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "3px 3px", fontSize: 13 }}>
                Receiver's Signature
              </td>
              <td
                style={{ padding: "3px 3px", fontSize: 13, textAlign: "right" }}
              >
                Authorized Signatory
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: "center",
                  fontSize: 13,
                  borderTop: "1px solid #000",
                  paddingTop: 2,
                }}
              >
                This is a Computer generated document and does not require any
                signature.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="" style={{ paddingTop: 10, paddingBottom: 15 }}>
          <div className="btn-group">
            <button onclick="window.close()" className="btn btn-danger">
              <span className="glyphicon glyphicon-remove" /> Close{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
