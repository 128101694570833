import { combineReducers } from "@reduxjs/toolkit";
import flightReducer from "../slices/flightSlice";
import authReducer from "../slices/auth";
import markUpReducer from '../slices/markUp';
// import flightReducer from "../slices/flightSlice";


const rootReducer = combineReducers({
  flight: flightReducer,
  auth:authReducer,
  markUp: markUpReducer,
  // markUp:markUpReducer
});

export default rootReducer;
