const imageSources = [
  "https://admin.yatriservice.com/public/images/emirates-airlines.png",
  "https://woxtt.com/public/assets/images/flogo/flogo3.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo4.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo5.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo6.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo8.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo9.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo10.jpg",
  "https://woxtt.com/public/assets/images/flogo/flogo12.jpg",
  "https://admin.yatriservice.com/public/images/turkish-airlinespng.png",
  "https://admin.yatriservice.com/public/images/american-airlines.png",
  "https://admin.yatriservice.com/public/images/united-airlinespng.png",
  "https://admin.yatriservice.com/public/images/gulfpng.png",
  "https://admin.yatriservice.com/public/images/FlyDubai-Logo.jpg",
  "https://admin.yatriservice.com/public/images/Kam_Air_.png",
  "https://admin.yatriservice.com/public/images/iran-air.png",
  "https://admin.yatriservice.com/public/images/mahan-logo.png",
  "https://admin.yatriservice.com/public/images/france-air.jpg",
];

export default imageSources;
