import React, { useEffect, useState } from "react";
import FlightBookingTable from "./FlightBookingTable";
// import flightBookingsData from './FlightBookingData';
import axios from "axios";
import { useSelector } from "react-redux";

const FlightBookingPage = () => {
  const [flightBookingsData, setFlightBookingsData] = useState([]);
  const {walletData}=useSelector((state)=>state.auth)
console.log("WALLLLLERDATA",walletData)
  useEffect(() => {
    const requestData={
      AgentId:walletData.Id
    }
    const fetchFlightBookingData = async () => {
      try {
        const response = await axios.post(
          "https://admin.yatriservice.com/api/details",requestData
        );
        console.log("dghfytdftyfv", response);
        if (response.data.success) {
          console.log("bookings from db", response.data.data);
          setFlightBookingsData(response.data.data);
        } else {
          console.error(
            "Failed to fetch flight booking data:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching flight booking data:", error);
      }
    };

    fetchFlightBookingData();
   
  }, []);
  // useEffect(() => {
  //   const classifyFlightResponse = (flightBookingsData) => {
  //     console.log("CLassify",flightBookingsData)
  //     if (contracts.length === 2) {
  //       return "Roundtrip Domestic";
  //     }

  //     if (contracts.length === 1) {
  //       const hasReturnSegment = airSegments.some(
  //         (segment) => segment.SegmentType === "Return"
  //       );

  //       if (hasReturnSegment) {
  //         return "International Roundtrip";
  //       } else {
  //         return "Oneway Flight";
  //       }
  //     }
  //   };
  // classifyFlightResponse(flightBookingsData)
  // }, [flightBookingsData]);
  return (
    <div>
      <FlightBookingTable heading="Flight Booking" data={flightBookingsData.reverse()} />
    </div>
  );
};

export default FlightBookingPage;
