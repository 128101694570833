import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./BookingTicket.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { RxCross2 } from "react-icons/rx";
import { FaRegFilePdf, FaWhatsapp } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { IoPrintSharp } from "react-icons/io5";
import { MdMarkEmailUnread, MdOutlineHideImage } from "react-icons/md";
import { LiaSmsSolid } from "react-icons/lia";
import { BsCurrencyDollar } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { cities12 } from "../../Cities";
import { Markup } from "../../redux/services/operations/markup";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}, ${dateNum} ${month} ${year}`;
};

const FlightBookingTickets = () => {

  const componentRef = React.useRef(null);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
    return Promise.resolve();
  }, []);

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "AwesomeFileName",
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });





  const fares = {
    0: "Normal",
    1: "Special",
    3: "UserFare",
    4: "CorporateFare",
    5: "CouponFare",
    6: "SMEFare",
    7: "GDSFare",
    8: "Default",
    9: "GoSmart",
    10: "GoSpecial",
    11: "GoSmartCorporate",
    12: "GoMarine",
    13: "GoBusiness",
    14: "GoBusinessCorporat",
    15: "GoRound",
    16: "SpiceSaver",
    17: "SpiceCorp",
    18: "SpiceSME",
    19: "SpiceCoupon",
    20: "GoFlexi",
    21: "SpiceCorporate",
    22: "UKSpecial",
    23: "GoSmartCorporateSp",
    24: "CrpcFare",
    25: "GoSpecialV2",
    26: "AkasaNormal",
    27: "AkasaAV",
    28: "AkasaSpecial",
    29: "AkasaCorporate",
    30: "AkasaCorpSelect",
    31: "EchoPECO",
    32: "EchoPCLS",
    33: "IndgoFlexi",
    34: "IndigoTactial",
    35: "IndigoCoupon",
    36: "VistaFlex",
    37: "AISME",
    38: "STU",
    39: "IXSme",
    40: "VVIP",
    41: "SUP6E",
    42: "AIBUS",
  };

  const [markupActive, setMarkupActive] = useState(false);
  const { walletData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [bookingDetails2, setBookingDetails2] = useState(null);
  const [bookingDetails, setbookingDetails] = useState(null);
  const [bookingDetailsTJ, setBookingDetailsTJ] = useState(null);
  const [refId, setRefId] = useState(null);
  const token = localStorage.getItem("token");
  const [money, setMoney] = useState("");
  const [price, setPrice] = useState(1000);
  const sessionId = localStorage.getItem("sessionId");
  const navigate = useNavigate();
  const [domesticMarkup, setDomesticMarkup] = useState(0);

  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 or 12 to 12
    return `${formattedHours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${period}`;
  };

  useEffect(() => {
    const storedFare = localStorage.getItem("add-dom");

    if (storedFare) {
      let finalPrice =
        storedFare !== undefined && storedFare !== null ? storedFare : 0;
      setDomesticMarkup(parseInt(finalPrice));
    } else {
      setDomesticMarkup(0);
    }
    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    parseInt(setPrice(price + parseInt(money)));
    setMarkupActive(false);
  };
  useEffect(() => {
    const BookingIdd = sessionStorage.getItem("BookingIdd");
    const AirlinePnrs = sessionStorage.getItem("AirlinePnr");
    const tokens = localStorage.getItem("token");

    const fetchData = async () => {
      const data = {
        APIToken: tokens,
        BookingId: BookingIdd,
        AirlinePnr: AirlinePnrs,
      };
      console.log("DATATATATATA", data);
      const responseP = await axios.post(
        `https://admin.yatriservice.com/api/flightbookingdetails`,
        data
      );
      if (responseP) {
        console.log("responseP", responseP.data.data);

        setRefId(responseP.data.RefId);
        // setbookingDetails(data);

        const originDestinationOptions =
          responseP.data.data && responseP.data.data.Contracts[0].AirSegments;

        originDestinationOptions &&
          originDestinationOptions.forEach((segment) => {
            const departureCode = segment.Origen;
            const arrivalCode = segment.Destination;

            const departureCity = cities12.find(
              (city) => city.AIRPORTCODE === departureCode
            );
            const arrivalCity = cities12.find(
              (city) => city.AIRPORTCODE === arrivalCode
            );

            segment.DepartureCity = departureCity;
            segment.ArrivalCity = arrivalCity;
          });

        if (responseP.data.data.Contracts.length === 2) {
          const originDestinationOptions2 =
            responseP.data.data && responseP.data.data.Contracts[1].AirSegments;
          originDestinationOptions2 &&
            originDestinationOptions2.forEach((segment) => {
              const departureCode = segment.Origen;
              const arrivalCode = segment.Destination;

              const departureCity = cities12.find(
                (city) => city.AIRPORTCODE === departureCode
              );
              const arrivalCity = cities12.find(
                (city) => city.AIRPORTCODE === arrivalCode
              );

              segment.DepartureCity = departureCity;
              segment.ArrivalCity = arrivalCity;
            });
        }

        setbookingDetails(responseP.data.data);
      } else {
        // navigate("/agent/404");
        throw new Error("Failed to fetch parto data");
      }
    };

    fetchData();
  }, []);

  const downloadPdf = () => {
    const capture = document.querySelector(".ticketdata");
  
    html2canvas(capture, {
      scale: 1.4,// Adjust scale for quality; lower scale for smaller size
      useCORS: true, // Ensures images are loaded correctly
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Use JPEG format and reduce quality (0.7 is 70%)
      const doc = new jsPDF("p", "mm", "a4");
  
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
  
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
  
      // Calculate aspect ratio
      const ratio = Math.min(
        componentWidth / imageWidth,
        componentHeight / imageHeight
      );
  
      const imageX = 0; // Adjust X position (center align if needed)
      const imageY = 10; // Adjust Y position (top margin)
      const imageWidthScaled = imageWidth * ratio;
      const imageHeightScaled = imageHeight * ratio;
  
      // Add image with reduced quality
      doc.addImage(
        imgData,
        "JPEG", // Use JPEG for smaller size
        imageX,
        imageY,
        imageWidthScaled,
        imageHeightScaled
      );
  
      // Save the PDF
      doc.save("bookingDetail.pdf");
    });
  };
  const [active, setActive] = useState(false);
  const [hidePerson, setHidePerson] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);
  // const totalBaseFare = bookingDetails.Contracts.reduce((acc, current) => acc + current.AirlineFare.BaseFare, 0);

  const sendWhatsAppMessage = async () => {};
  console.log("bookingdeatailfcbfcgfdg", bookingDetails);
  const { adminDm, adminIm, agentDm, agentIm } = useSelector(
    (state) => state.markUp
  );
  console.log("Afabfhaefw", adminDm, adminIm, agentDm, agentIm);

  return (
    <div style={{ fontSize: "larger" }}>
      {bookingDetails && (
        <div
          className="BookingConfirmBookingHead"
          style={{ minWidth: "1280px" }}
        >
          <div className="flightBookingHeaderDiv">
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHidePerson(!hidePerson)}
            >
              <FaPerson />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                Hide Agent Info
              </span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHideLogo(!hideLogo)}
            >
              <MdOutlineHideImage />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                Hide Logo
              </span>
            </div>
            <div className="flightBookingUpperIcon" onClick={handlePrint}>
              <IoPrintSharp />
              <span className="hotelBookingHideDetails"> Print</span>
            </div>
            <div className="flightBookingUpperIcon" onClick={downloadPdf}>
              <FaRegFilePdf />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                {" "}
                Save Pdf
              </span>
            </div>
            <div className="flightBookingUpperIcon">
              <MdMarkEmailUnread />
              <span className="hotelBookingHideDetails"> Email </span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={sendWhatsAppMessage}
            >
              <FaWhatsapp />
              <span className="hotelBookingHideDetails"> Whatsapp</span>
            </div>
            <div className="flightBookingUpperIcon">
              <LiaSmsSolid />
              <span className="hotelBookingHideDetails"> Sms</span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHidePrice(!hidePrice)}
            >
              <BsCurrencyDollar />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                {" "}
                Hide Price
              </span>
            </div>
            <div className="flightBookingUpperIcon">
              <BsCurrencyDollar />
              <span
                className="hotelBookingHideDetails"
                onClick={() => setMarkupActive(!markupActive)}
              >
                {" "}
                Markup
              </span>
            </div>
            <div
              style={{
                padding: "3px 6px",
                backgroundColor: "#db0e0edb",
                fontSize: "20px",
                color: "white",
              }}
            >
              <RxCross2 />
            </div>
          </div>
          {markupActive ? (
            <div className="hotelBookingMarkupMain">
              <div className="hotelBookingMarkup">
                <p className="hotelBookingMarkuppara markup-margins">
                  {" "}
                  Additional Transaction fees / discount
                </p>
                <p
                  className="markup-margins"
                  style={{
                    color: "#2d3290",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  Existing Amount: Rs.
                </p>
                <p
                  className="markup-margins"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Reset Amount
                </p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    value={money}
                    onChange={(e) => setMoney(e.target.value)}
                    style={{ width: "100%", padding: "5px 10px" }}
                  />
                  <div className="hotelBookingCloseSub">
                    <button
                      className="hotelBookingMarkupClose"
                      onClick={() => setMarkupActive(false)}
                    >
                      close
                    </button>
                    <button className="hotelBookingMarkupSub" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
          <section className="container-fluid ticketdata" ref={componentRef}>
            <div className="container">
              <div className="">
                <div className="top-part">
                {!hideLogo && (
                    <div className="logo">
                      <img
                        src="/Images/yatri-service.png"
                        alt="Logo"
                        width={110}
                        height={45}
                      />
                    </div>
                  )}
                  <div className="ticket-details" style={{textAlign:'end',width:'100%'}}>
                    <h4 style={{ fontSize: "16px", fontWeight: "700" }}>
                      Ticket-Confirmed
                    </h4>
                  </div>
                </div>
                <div className="top-parts">
                {hidePerson ? (
                  <div></div>
                ) : (
                  <div className="ticket-details">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="booking-ticket-upper-sections">
                       Company Name : <b>{walletData && walletData.CompanyName}</b>
                      </div>
                    </div>{" "}
                    <div className="booking-ticket-upper-sections">
                      Address : <b> {walletData && walletData.Address}</b>
                    </div>
                    <div className="booking-ticket-upper-sections">
                      Phone No :<b> {walletData && walletData.Phone}</b>
                    </div>
                    <div className="booking-ticket-upper-sections">
                      <p> Email Id : </p> &nbsp;
                      <b>{walletData && walletData.Email}</b>
                    </div>
                  </div>
                  )}
                  <div className="ticket-details">
                    <div className="">
                      {/* <div className="booking-ticket-upper-sections">
                        <p>Refund : </p>
                        <b>
                          {bookingDetails &&
                          bookingDetails.Contracts[0].Refundable &&
                          bookingDetails.Contracts[0].Refundable === true
                            ? "Refundable"
                            : "Non Refundable"}{" "}
                          <br />
                        </b>
                      </div> */}
                      <div className="booking-ticket-upper-sections">
                        <p>Refrence Id :</p>
                        <b> {refId} </b>
                      </div>
                      {bookingDetails && bookingDetails.Contracts[0] && (
                        <div className="booking-ticket-upper-sections">
                          <span>
                            Inbound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <b> {bookingDetails.Contracts[0].AirlinePnr} </b>
                        </div>
                      )}
                      {bookingDetails && bookingDetails.Contracts[1] && (
                        <p>
                          OutBound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>{bookingDetails.Contracts[1].AirlinePnr} </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
               
                  <div className="passenger-details">
                  <h4>
                        {" "}
                        Passenger Detail{" "}
                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                          ({bookingDetails && bookingDetails.Flightpassenger[0].Email})
                        </span>
                      </h4>
                    <table className="flight-ticket_table table table-bordered text-center">
                      <thead>
                        <tr>
                          <th scope="col">Passenger</th>
                          {/* <th scope="col">Airline</th> */}
                          <th scope="col">Status</th>
                          {/* <th scope="col">Sector</th> */}
                          <th scope="col">Airline PNR</th>
                          <th scope="col">Ticket Number</th>
                          <th scope="col">Refundable</th>
                          <th scope="col">Seat No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingDetails &&
                          bookingDetails.Flightpassenger.map((item) => (
                            <tr>
                              <td>
                                <p>
                                  {item.FirstName} {item.LastName}
                                </p>
                              </td>
                              {/* <td>
                                
                                {
                                  bookingDetails.Contracts[0].AirSegments[0]
                                    .AirlineCode
                                }
                                
                              </td> */}

                              <td>
                                Success
                                {/* {passenger.Ticket && passenger.Ticket.Status} */}
                              </td>
                              {/* <td>
                                {bookingDetails.Contracts.map((item) => (
                                  <p>
                                    {item.AirSegments.map((item) => (
                                      <p>
                                        {item.Origen} - {item.Destination},
                                      </p>
                                    ))}
                                  </p>
                                ))}
                              </td> */}
                              <td>{bookingDetails.AirlinePnr}</td>
                              <td>{item.TicketNumber}</td>
                              <td>
                                {" "}
                                {bookingDetails &&
                                bookingDetails.Contracts[0].Refundable &&
                                bookingDetails.Contracts[0].Refundable === true
                                  ? "Refundable"
                                  : "Non Refundable"}
                              </td>
                              <td>{item.SeatCode}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                
                <div className="flight-details">
                  <h4> Flight Details </h4>
                  <table className="flight-ticket_table table text-center table-bordered">
                    <thead className="table-primary">
                      <tr>
                        <th scope="col" colSpan="2">
                          Airline Details
                        </th>
                        <th scope="col">Departure</th>
                        <th scope="col">Arrival</th>
                        <th scope="col">Duration</th>
                      </tr>
                    </thead>

                    {bookingDetails &&
                      bookingDetails.Contracts.map((data) => (
                        <tbody>
                          {data.AirSegments.map((item) => (
                            <tr style={{ background: "#6e25c129" }}>
                              <td>
                                <img
                                  src={`/Images/AirlineLogo/${item.AirlineCode}.gif`}
                                  className="img-fluid"
                                  alt=""
                                  style={{ height: "42px", width: "40px" }}
                                />
                                <div className="flightInfoDivtag1">
                                  <p
                                    className=""
                                    style={{
                                      fontSize: "14px",
                                      margin: "0px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {item.AirlineName}- {item.FlightNumber}
                                  </p>
                                </div>
                                {/* <i
                                  className="fa-solid fa-plane-circle-check fa-fw"
                                  style={{ color: "#8b3eea" }}
                                ></i> */}
                              </td>
                              <td>
                                <p>
                                  <span style={{ fontWeight: 600 }}>
                                    {/* {detail.fD.aI.name} */}
                                  </span>
                                  <br />
                                  {item.AirlineCode} {item.FlightNumber} <br />
                                  Cabin Class: (
                                  {item.TypeOfClass === 0 && "Economy"}
                                  {item.TypeOfClass === 1 && "First"}
                                  {item.TypeOfClass === 2 && "Business"}
                                  {item.TypeOfClass === 3 &&
                                    "Premium_Economy"}) <br />
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#8b3eea",
                                      fontWeight: "600",
                                    }}
                                  >
                                    ({item.SegmentType})
                                  </span>
                                </p>
                              </td>
                              <td>
                                <span>
                                  {/* {item.SourceAirport.city_name}  */}(
                                  {item.Origen})
                                </span>
                                <br />
                                <p style={{ marginBottom: "0px" }}>
                                  <span style={{ fontWeight: 600 }}>
                                    {item.DepartureTime === null ||
                                    item.DepartureTime === "" ? (
                                      <>
                                        {item.DepartureTime} <br />{" "}
                                        {new Date(
                                          item.DepartureDateTime
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                        })}
                                        {formatTime(item.DepartureDateTime)}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {convertToAmPm(item.DepartureTime)}
                                        <br />
                                        {item.DepartureDateTime}
                                      </>
                                    )}
                                    {/* {formatTime(item.DepartureDateTime)} &nbsp;
                                {new Date(
                                  item.DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })} */}
                                  </span>
                                </p>
                              </td>
                              <td>
                                <span>
                                  {/* {item.DestinationAirport.city_name} ( */}(
                                  {item.Destination}){" "}
                                </span>
                                <br />
                                <p style={{ marginBottom: "0px" }}>
                                  <span style={{ fontWeight: 600 }}>
                                    {/* {item.ArrivalTime === null ||
                                    item.ArrivalTime === ""
                                      ? item.ArrivalTime
                                      : convertToAmPm(item.ArrivalTime)}
                                    <br />
                                    {item.ArrivalDateTime} */}

                                    {item.ArrivalTime === null ||
                                    item.ArrivalTime === "" ? (
                                      <>
                                        {item.ArrivalTime}
                                        {new Date(
                                          item.ArrivalDateTime
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                        })}{" "}
                                        <br />
                                        {formatTime(item.ArrivalDateTime)}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {convertToAmPm(item.ArrivalTime)}
                                        <br />
                                        {item.ArrivalDateTime}
                                      </>
                                    )}

                                    {/* {formatTime(item.ArrivalDateTime)} &nbsp;
                                {new Date(
                                  item.ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })} */}
                                  </span>
                                  {/* {detail.aa.name} */}
                                </p>
                              </td>
                              <td>{item.Duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      ))}
                  </table>
                </div>

                <div className="baggage-flight">
                  <h4> Baggage Info </h4>
                  <table className="flight-ticket_table table text-center table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">
                          Airline
                        </th>
                        <th scope="col" rowSpan="2">
                          Sector
                        </th>
                        <th scope="col" colSpan="2">
                          Baggage
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Check-In</th>
                        <th scope="col">Cabin</th>
                      </tr>
                    </thead>

                    {bookingDetails &&
                      bookingDetails.Contracts.map((data) => (
                        <tbody>
                          {data.AirSegments.map((item) => (
                            <tr>
                              <td>
                                {item.AirlineName}- {item.FlightNumber} <br />
                                {/* {item.AirlineCode} */}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "4px",
                                    color: "#8b3eea",
                                    fontWeight: "600",
                                  }}
                                >
                                  ({item.SegmentType})
                                </span>
                              </td>
                              <td>
                                ( {item.Origen}-{item.Destination})
                              </td>
                              <td>{item.BaggageAllowed.CheckInBaggage}</td>
                              <td>{item.BaggageAllowed.HandBaggage}</td>
                            </tr>
                          ))}
                        </tbody>
                      ))}
                  </table>
                </div>

                {!hidePrice && (
                  <div className="flight-details">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        background: "#8b3eea",
                        alignItems: "center",
                      }}
                    >
                      <h4> Fare Info </h4>
                      <h6 style={{ color: "#fff", marginRight: "10px" }}>
                        Fare Type :{" "}
                        {
                          fares[
                            bookingDetails.Contracts[0] &&
                              bookingDetails.Contracts[0].FareType
                          ]
                        }
                      </h6>
                    </div>
                    <table className="flight-ticket_table table text-center table-bordered">
                      <thead className=" table-primary">
                        <tr style={{ backgroundColor: "#6e25c129 !important" }}>
                          {/* <th scope="col"> Base Fare</th> */}
                          <th scope="col">Taxes & Surcharges/Other Charges</th>
                          {/* <th scope="col">Discount</th> */}
                          {/* <th scope="col">Service Fee</th> */}
                          {/* <th scope="col">Excess Baggage</th> */}
                          {/* <th scope="col">Meal</th> */}
                          <th scope="col">Base Fare</th>
                          {/* <th scope="col">Other Charges</th> */}
                          <th scope="col">Total Fare</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingDetails && (
                          <tr>
                            {/* <td>
                              {" "}
                              ₹
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .BaseFare}
                           
                            </td> */}
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .TaxFare +
                                  adminIm +
                                  adminIm +
                                  agentIm +
                                  agentIm +
                                  (money && parseInt(money))}
                            </td>
                            {/* <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .Discount}
                            </td>
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .ServiceCharge}
                            </td>
                            <td>₹ 0</td>
                            <td>₹ 0</td>
                            <td>₹ 0</td> */}
                            <td>
                              ₹
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .BaseFare}
                            </td>
                            {/* <td>
                              ₹ {!bookingDetails.Contracts[1] && markup + (money && parseInt(money))}
                              {bookingDetails.Contracts[1] &&
                                markup + markup + (money && parseInt(money))}{" "}
                            </td> */}
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .GrossFare +
                                  adminIm +
                                  agentIm +
                                  adminIm +
                                  agentIm +
                                  (money && parseInt(money))}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* 
                <div className="personal-details">
                  <h4> Company Contact Details </h4>
                  <table className="flight-ticket_table table table-bordered">
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Name</td>
                        <td>Yatri Service </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Address</td>
                        <td>Delhi, India-41</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Contact</td>
                        <td>+91-9721647550 </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Email</td>
                        <td>Support@yatriservice.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}

                <div className="information-details">
                  <h5>Passport/Visa/Health</h5>
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingInlineStart: "20px",
                    }}
                  >
                    <li>
                      {" "}
                      Please ensure that you have all the required travel
                      documents for your entire journey i.e., valid passport &
                      necessary visas, and that you have had the recommended
                      inoculations for your destination(s).
                    </li>
                    <li>
                      {" "}
                      All passengers, including children and infants, have to
                      present their valid ID proof at the time of check-in.
                    </li>
                    <li>
                      {" "}
                      We recommend you check-in at least 3 hours prior to
                      departure of your domestic flight and 4 hours prior to
                      your international flight
                    </li>
                    <li>
                      Carriage and other facilities provided by the carrier are
                      subject to their Terms and Condition. We are not liable
                      for missing any facility of the carrier.
                    </li>
                    <li>
                      Recheck your baggage with your respective airline before
                      traveling for a hassle-free travel experience.
                    </li>
                    <li>
                      Group Booking Rules will be applicable if passengers are 9
                      or more in numbers.
                    </li>
                    <li>
                      Company is not responsible for any delay or cancellation
                      of flights from airline's end.
                    </li>
                    <li>
                      Unaccompanied Child: Children below the age of 12 will not
                      be accepted for carriage unless they are accompanied by a
                      person of at least 18 years of age. Such child/children
                      must be seated next to the accompanying adult. The
                      accompanying adult is solely responsible for the
                      well-being of the child/children traveling together with
                      him/her. This also includes ensuring that seats are booked
                      to ensure child/children and an accompanying adult are
                      seated together.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default FlightBookingTickets;
