import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { FaUser, FaKey, FaUserTie } from "react-icons/fa";
import "./Hero.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Wallentbalance, login } from "../../../redux/services/operations/auth";

const Hero = () => {
  const [UserName, setUserName] = useState("guruansh@holidaychacha.com");
  const [Password, setPassword] = useState("Hello123@123");
  const [rememberMe, setRememberMe] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    dispatch(login(UserName, Password, navigate));
    dispatch(Wallentbalance(UserName, Password, navigate));
    // navigate('/agent/dashboard')
  };

  const handleOtpToggle = () => {
    setShowOtp(!showOtp);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div className="travelagent travelagent_border">
      <div className="email_serv tboright">
        <b>Email: </b>
        <a href="mailto:support@yatriservice.com">support@yatriservice.com</a>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner_two_text">
              <h3 className="title">BEST TRAVEL AGENCY</h3>
              <h1 className="slider-sttle">Explore</h1>
              <h2 className="slider-pararp">The world with us!</h2>
              <h4 className="slider-pararp">
                Welcome to Yatri Service, your gateway to seamless and efficient
                travel management. Our B2B portal is designed to elevate your
                travel experience, providing a usessr-friendly platform for
                travel professionals like you.
              </h4>
              <a
                role="button"
                tabIndex={0}
                href="/agent-registration"
                className="btn btn-primaryss btn btn-primary"
                style={{ marginTop: 20 }}
              >
                Register with us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
