import React, { useState, useEffect } from "react";
// import DepatureDetail from "./DepatureDetail";
import "./FlightPayModal.css";
// import FlightDetailSkeleton from "./FlightDetailSkeleton";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import DepatureDetail from "./DepartureDetail";
import FlightDetailSkeleton from "./FlightDetailSkeleton";
import { Modal } from "react-bootstrap";
const FlightPayModal = ({
  flight,
  flight2,
  setOpenPayBtn,
  walletDatas,
  openPayBtn,
  formData,
  infant,
  childData,
  handleTicketBook,
  handleTicketBookRound,
  passengerSeatPreferences,
  passengerMealPreferences,
  passengerBaggagePreferences,
  domesticMarkup,
  InternationalMarkup,
  isinternational,
  calculateTotalFare,
  walletDataaa
}) => {
  const { adminDm, adminIm, agentDm, agentIm } = useSelector((state) => state.markUp);
  const markup = isinternational ? adminIm : adminDm;
  const agentmarkup = isinternational ? agentIm : agentDm;

  // const [walletDatas, setwalletDatas] = useState(null);
  // useEffect(() => {
  //   const data = localStorage.getItem("walletBalance");
  //   if (data) {
  //     setwalletDatas(data);
  //   }
  // }, [walletDatas]);

  const { walletData } = useSelector((state) => state.auth);

  //   let totalMealCharges = passengerMealPreferences.reduce((total, meal) => {
  //     if (meal && meal.Price) {
  //       total += meal.Price;
  //     }
  //     return total;
  //   }, 0);
  //   let totalMealWithCharges =
  //     flight && flight.Fare.TotalMealCharges + totalMealCharges;

  //   let totalSeatCharges = passengerSeatPreferences.reduce((total, meal) => {
  //     if (meal && meal.Price) {
  //       total += meal.Price;
  //     }
  //     return total;
  //   }, 0);
  //   let totalSeatWithCharges =
  //     flight && flight.Fare.TotalSeatCharges + totalSeatCharges;

  //   let totalBaggageCharges = passengerBaggagePreferences.reduce(
  //     (total, meal) => {
  //       if (meal && meal.Price) {
  //         total += meal.Price;
  //       }
  //       return total;
  //     },
  //     0
  //   );
  //   let totalBaggageWithCharges =
  //     flight && flight.Fare.TotalBaggageCharges + totalBaggageCharges;

  return (
    <Modal
      show={openPayBtn}
      onHide={() => {
        setOpenPayBtn(false);
      }}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Flight Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {flight ? (
          <DepatureDetail flight={flight} type="Departure" />
        ) : (
          <FlightDetailSkeleton />
        )}
        {flight2 && <DepatureDetail flight={flight2} type="Return" />}

        <div className="bor po-re m-bt">
          <div className="trheadbg">
            {/* <div className="edt1 po-ab">Edit</div> */}
            Traveller Details
          </div>
          <div className="tr-cn">
            <div className="ps-d mar20">
              <div className="ps1n">
                <span className="fnt">E-mail </span>

                <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty">
                  {/* {walletData.Email} */}
                  {walletDataaa.Email}
                </span>
              </div>
              <div className="ps2n">
                <span className="fnt">Contact No. </span>
                <span className="fnt-g ng-binding">
                  {/* {walletData.Phone} */}
                  {walletDataaa.Phone}
                </span>
              </div>
              <div className="ps3n">
                {formData && (
                  <span>
                    <span className="fnt">
                      Adult (
                      <span className="ng-binding">{formData.length}</span>){" "}
                    </span>

                    {formData.map((item, index) => (
                      <span className="fnt-g ng-binding ng-scope">
                        {item.title} {item.firstName} {item.lastName}
                      </span>
                    ))}
                  </span>
                )}
              </div>
              <div className="ps4n">
                {childData && (
                  <span>
                    {" "}
                    <span className="fnt">
                      Child (
                      <span className="ng-binding">{childData.length}</span>){" "}
                    </span>
                    {childData.map((item, index) => (
                      <span className="fnt-g ng-binding ng-scope">
                        {item.title} {item.firstName} {item.lastName}
                      </span>
                    ))}
                  </span>
                )}
              </div>
              <div className="ps5n">
                {infant && (
                  <span>
                    {" "}
                    <span className="fnt">
                      Infant (
                      <span className="ng-binding">{childData.length}</span>){" "}
                    </span>
                    {infant.map((item, index) => (
                      <span className="fnt-g ng-binding ng-scope">
                        {item.title} {item.firstName} {item.lastName}
                      </span>
                    ))}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bor po-re m-bt">
          <div className="trheadbg">
            <div className="edt1 po-ab">Pay using eWallet</div>
          </div>
          <div className="tr-cn">
            {flight && !flight2 && (
              <div>
                <div className="ps-d mar20">
                  <div className="ps1n1">
                    <span className="fnt">Wallet Balance </span>

                    <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty walletBalanceBold">
                      ₹{walletDatas}
                    </span>
                  </div>
                  <div className="ps1n1">
                    <span className="fnt">Flight Price </span>
                    <span className="fnt-g ng-binding walletBalanceBold">
                      ₹
                      {calculateTotalFare(
                        flight.AirlineFare.GrossFare, markup, agentmarkup)
                        //   +
                        //     totalBaggageWithCharges +
                        //     totalMealWithCharges +
                        //     totalSeatWithCharges
                      }
                    </span>
                  </div>
                  {/* <div className="ps1n1">
                    <span className="fnt">Amount to Deduct </span>
                    <span className="fnt-g ng-binding walletBalanceBold">
                     - ₹
                      {
                        flight.AirlineFare.NetFare + markup
                       
                      }
                    </span>
                  </div> */}
                  {/* <div className="ps1n1">
                    <span className="fnt">Remaining Balance </span>

                    <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty">
                      ₹
                      {
                        walletDatas -
                          (flight.AirlineFare.GrossFare + markup)
                       
                      }
                    </span>
                  </div> */}
                </div>
              </div>
            )}
            {flight && flight2 && (
              <div>
                <div className="ps-d mar20">
                  <div className="ps1n1">
                    <span className="fnt">Wallet Balance </span>

                    <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty walletBalanceBold">
                      ₹{walletDatas}
                    </span>
                  </div>
                  <div className="ps1n1">
                    <span className="fnt">Flight Price </span>
                    <span className="fnt-g ng-binding walletBalanceBold">
                      ₹
                      {
                        flight.AirlineFare.GrossFare +
                        markup + agentmarkup + agentmarkup +
                          flight2.AirlineFare.GrossFare +
                          markup

                        //   +
                        //     totalBaggageWithCharges +
                        //     totalMealWithCharges +
                        //     totalSeatWithCharges
                      }
                    </span>
                  </div>
                  {/* <div className="ps1n1">
                    <span className="fnt">Amount to Deduct </span>
                    <span className="fnt-g ng-binding walletBalanceBold">
                    -  ₹
                      {
                        flight.AirlineFare.NetFare +
                        markup +
                          flight2.AirlineFare.NetFare +
                          markup

                      }
                    </span>
                  </div> */}
                  
                </div>
              </div>
            )}
          </div>
          <div
            className="flightPay"
            style={{ width: "100%", textAlign: "end" }}
          >
            <button
              className="flightPaybtn"
              onClick={() => {
                // setOpenPayBtn(!openPayBtn);
                !flight2 && handleTicketBook();
                flight2 && handleTicketBookRound();
              }}
            >
              {" "}
              Confirm & Pay
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FlightPayModal;
