import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import FlightDetailSide from "./FlightDetailSide";
import { HiMiniMinusSmall } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ChargesRound = ({
  flight,
  flight2,
  setShowdetail,
  showdetail,
  handleChangeCurrency,
  walletData,
  walletDatas,
  setOpenPayBtn,
  openPayBtn,
  validatePassengerData,
  domesticMarkup,
  InternationalMarkup,
  isinternational,
  creditLimits,
  calculateTotalFare,
  calculateTotalFareDeduct,
  setCreditLimits,
}) => {
  const { adminDm, adminIm, agentDm, agentIm } = useSelector(
    (state) => state.markUp
  );
  const markup = isinternational ? adminIm : adminDm;
  const agentmarkup = isinternational ? agentIm : agentDm;
  const [showData, setShowData] = useState(false);

  console.log("AGENTMARKUP IN ROUND DETAIL", agentmarkup);
  // const { walletData } = useSelector((state) => state.auth);

  return (
    <Col md={3}>
      <Row>
        <Col md={12}>
          {flight ? (
            <div className="flightBookingsecDiv">
              <Row className="flightBookingSecDivRow3">
                <Col xs={12} md={12}>
                  <span
                    className="lbl"
                    onClick={() => setShowdetail(!showdetail)}
                  >
                    Base Fare (
                    {flight.PexFareDetails.reduce(
                      (total, item) => total + item.TotPax,
                      0
                    )}{" "}
                    Travellers)
                  </span>{" "}
                  <FaAngleDown onClick={() => setShowdetail(!showdetail)} />{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}  */}₹
                    {flight.AirlineFare.BaseFare + flight2.AirlineFare.BaseFare}
                  </span>
                </Col>
              </Row>
              {showdetail && (
                <Row
                  className="flightBookingSecDivRow3"
                  style={{ width: "90%", marginInline: "auto" }}
                >
                  <Col xs={12} md={12}>
                    <span className="lbl">
                      {flight.PexFareDetails[0].TotPax} x Adult
                    </span>{" "}
                    <span className="val">
                      {flight.PexFareDetails[0].BaseFare +
                        flight2.PexFareDetails[0].BaseFare}
                    </span>
                  </Col>
                  {flight.PexFareDetails[1] && (
                    <Col xs={12} md={12}>
                      <span className="lbl">
                        {flight.PexFareDetails[1].TotPax} x Children
                      </span>{" "}
                      <span className="val">
                        {flight.PexFareDetails[1].BaseFare +
                          flight2.PexFareDetails[1].BaseFare}
                      </span>
                    </Col>
                  )}
                  {flight.PexFareDetails[2] && (
                    <Col xs={12} md={12}>
                      <span className="lbl">
                        {flight.PexFareDetails[2].TotPax} x Infants
                      </span>{" "}
                      <span className="val">
                        {flight.PexFareDetails[2].BaseFare +
                          flight2.PexFareDetails[2].BaseFare}
                      </span>
                    </Col>
                  )}
                </Row>
              )}
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Taxes & Surcharges</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency} */}₹
                    {calculateTotalFare(
                      flight.AirlineFare.TaxFare,
                      markup,
                      agentmarkup
                    ) +
                      calculateTotalFare(
                        flight2.AirlineFare.TaxFare,
                        markup,
                        agentmarkup
                      )}
                  </span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Discount</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency} */}₹
                    {flight.AirlineFare.Discount + flight2.AirlineFare.Discount}
                  </span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Service Fee</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}  */}₹
                    {flight.AirlineFare.ServiceCharge +
                      flight2.AirlineFare.ServiceCharge}
                  </span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Excess Baggage (0KG )</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}{" "} */}₹
                    {/* {
                      flight.Fare.TotalBaggageCharges +
                        flight2.Fare.TotalBaggageCharges
                    } */}
                    0
                  </span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Meal (0)</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}{" "} */}₹
                    {/* {
                      flight.Fare.TotalMealCharges +
                        flight2.Fare.TotalMealCharges
                    } */}
                    0
                  </span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Seat Charges</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}{" "} */}₹0
                    {/* {
                      flight.Fare.TotalSeatCharges +
                        flight2.Fare.TotalSeatCharges
                    } */}
                  </span>
                </Col>
              </Row>

              <Row
                className="flightBookingSecDivRow2"
                style={{ paddingBlock: "8px" }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Total Fare</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}{" "} */}₹
                    {calculateTotalFare(
                      flight.AirlineFare.GrossFare,
                      markup,
                      agentmarkup
                    ) +
                      calculateTotalFare(
                        flight2.AirlineFare.GrossFare,
                        markup,
                        agentmarkup
                      )}
                  </span>
                </Col>
              </Row>
              <Row className="flightBookingsecDivRow">
                <Col xs={12} md={12}>
                  <span className="lbl">You Pay:</span> -{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}{" "} */}₹
                    {calculateTotalFare(
                      flight.AirlineFare.GrossFare,
                      markup,
                      agentmarkup
                    ) +
                      calculateTotalFare(
                        flight2.AirlineFare.GrossFare,
                        markup,
                        agentmarkup
                      )}
                  </span>
                </Col>
              </Row>
            </div>
          ) : (
            <FlightDetailSide />
          )}
        </Col>
      </Row>
      {flight && (
        <Row style={{ marginTop: "15px" }}>
          {parseInt(walletDatas >= 0 ? walletDatas : 0) +
            parseInt(creditLimits) >
          calculateTotalFareDeduct(flight.AirlineFare.NetFare, markup) +
            calculateTotalFareDeduct(flight2.AirlineFare.NetFare, markup) ? (
            <Col md={12} className="resp-mt-20">
              <Card className="dashboardbox " style={{ marginBottom: "5px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <h2
                      className="boxheading walletMainDiv"
                      style={{
                        background: "#f4f4f4",
                        color: "black",
                        paddingInline: "15px",
                      }}
                    >
                      Pay using eWallet
                    </h2>

                    <h2
                      style={{
                        color: "black",
                        paddingInline: "10px",
                        paddingTop: "10px",
                        fontSize: "10px",
                        textAlign: "end",
                        cursor: "pointer",
                        width: "35%",
                      }}
                      onClick={() => setShowData(!showData)}
                    >
                      NetFare
                    </h2>
                  </div>
                  <div className="dashinnerboxs">
                    <div
                      className="ewalletMainDiv"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                      <p>Current Balance </p>
                      <p>₹{walletDatas}</p>
                    </div>
                    <div
                      className="ewalletMainDiv"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                      <p>Flight Amount </p>
                      <p>
                        ₹
                        {calculateTotalFare(
                          flight.AirlineFare.GrossFare,
                          markup,
                          agentmarkup
                        ) +
                          calculateTotalFare(
                            flight2.AirlineFare.GrossFare,
                            markup,
                            agentmarkup
                          )}
                      </p>
                    </div>
                    {showData && (
                      <div
                        className="ewalletMainDiv"
                        style={{ borderBottom: "1px solid #e4e4e4" }}
                      >
                        <p> Amount to Deduct</p>
                        <p>
                          - ₹
                          {calculateTotalFareDeduct(
                            flight.AirlineFare.NetFare,
                            markup
                          ) +
                            calculateTotalFareDeduct(
                              flight2.AirlineFare.NetFare,
                              markup
                            )}
                        </p>
                      </div>
                    )}

                    <div
                      // style={{ width: "100%" }}
                      onClick={() => {
                        if (validatePassengerData()) {
                          setOpenPayBtn(true);
                          // setPaymentGateway(true);
                        }
                      }}
                    >
                      {" "}
                      <button className="ewalletbtn">Pay Now</button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <Col md={12} className="resp-mt-20">
              <Card className="dashboardbox " style={{ marginBottom: "5px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <h2 className="boxheading walletMainDiv">
                    Pay using eWallet
                  </h2>
                  <div className="dashinnerboxs">
                    <div className="ewalletMainDiv">
                      <p>Current Balance </p>
                      <div style={{ width: "45px" }}>
                        <p>
                          ₹{walletDatas}
                          <div
                            style={{
                              fontSize: "11px",
                              marginLeft: "-74px",
                              fontWeight: "500",
                              color: "red",
                            }}
                          >
                            (Insufficient Balance)
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="ewalletMainDiv">
                      <p>Flight Amount </p>
                      <p>
                        ₹
                        {calculateTotalFare(
                          flight.AirlineFare.GrossFare,
                          markup,
                          agentmarkup
                        ) +
                          calculateTotalFare(
                            flight2.AirlineFare.GrossFare,
                            markup,
                            agentmarkup
                          )}
                      </p>
                    </div>
                    <Link style={{ width: "100%" }} to="/agent/wallet">
                      <button className="ewalletbtn">Top Up Request</button>
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      )}
    </Col>
  );
};

export default ChargesRound;
