import React, { useState } from "react";
import FareRule from "../../../FlightDetails/FareRule";

const DepatureDetail = ({
  flight,
  type,
  fareRule,
  setFareActive,
  fareActive,
}) => {
  const formatTime = (arrTime) => {
    const date = new Date(arrTime);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = days[date.getDay()];
    const dateNum = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${dateNum} ${month} ${year}`;
  };

  const formatLayoverTime = (arrivalTime, nextDepartureTime) => {
    const arrival = new Date(arrivalTime);
    const nextDeparture = new Date(nextDepartureTime);
    const layoverMinutes = Math.floor(
      (nextDeparture.getTime() - arrival.getTime()) / (1000 * 60)
    );
    const layoverHours = Math.floor(layoverMinutes / 60);
    const layoverMinutesRemainder = layoverMinutes % 60;
    return `${layoverHours}h : ${layoverMinutesRemainder}m`;
  };
  const [active, setActive] = useState(false);
  console.log("DAFAYfDYFYATFd", flight);
  return (
    <div>
      <div className="block-content-2 custom_block_content">
        <div className="box-result custom_box_result">
          <div className="flight_tags depart_tags">
            <span style={{ zIndex: 2 }}>Departure</span>
          </div>

          <div>
            {flight[0].AirSegments[0].map((segment, index) => (
              <React.Fragment key={index}>
                <ul className="list-search-result booking_list forMinScreenul">
                  <li className="flight_name">
                    <img
                      src={`/Images/AirlineLogo/${segment.AirlineCode}.gif`}
                      alt=""
                      style={{ width: "50px" }}
                    />

                    <div className="name">
                      {" "}
                      {segment.AirlineName}
                      <span className="flight_no">
                        {/* {segment.Airline.AirlineCode}- */}
                        {segment.FlightNumber}
                      </span>
                      {/* <span className="flightBookingHiddenTitle">
                          Economy
                        </span> */}
                    </div>
                  </li>
                  <li className="flight_time">
                    <span className="flightBookingHiddenDelhi">
                      {" "}
                      {segment.Origen}{" "}
                      {/* {segment.Origin.Airport.CountryCode} */}
                    </span>
                    <strong>
                      {new Date(segment.DepartureDateTime).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        }
                      )}
                    </strong>
                    <span className="date">
                      {formatTime(segment.DepartureDateTime)}
                    </span>
                    <span className="flightBookingHiddenTitle">
                      {" "}
                      {/* {segment.DepartureCity.COUNTRYNAME},{" "}
                    {segment.DepartureCity.COUNTRYCODE} */}
                    </span>
                    <span className="airport">
                      {" "}
                      {segment.SourceAirport.airport_name}
                      {/* , Terminal{" "} */}
                      {/* {segment.Origin.Airport.Terminal} */}
                    </span>
                  </li>
                  <li className="flight_amenties">
                    <div className="top">
                      <span className="duration">
                        <i
                          className="fa fa-clock"
                          style={{ marginRight: "3px" }}
                        />
                        {segment.Duration}
                      </span>
                      <span className="flightDetailSpantag"></span>
                      <span className="grey_rtbrder">|</span>{" "}
                      <span className="flightBookingHiddenDelhi">
                        {segment.TypeOfClass === 0 && "Economy"}
                        {segment.TypeOfClass === 1 && "First"}

                        {segment.TypeOfClass === 3 && "Business"}
                        {segment.TypeOfClass === 4 && "Premium_Economy"}
                      </span>
                    </div>

                    <div className="middle">
                      <span className="txt flighttxt">
                        <i className="fa fa-plane" />
                      </span>
                    </div>

                    <div className="bottom">
                      {segment.BaggageAllowed && (
                        <span className="wght">
                          {segment.BaggageAllowed.CheckInBaggage}
                        </span>
                      )}

                      {/* {segment.AirSegments[0].BaggageAllowed &&
                          flight.Refundable && (
                            <span className="grey_rtbrder">|</span>
                          )} */}

                      {flight.Refundable && (
                        <>
                          <span
                            className="refundable"
                            style={{ marginLeft: "5px" }}
                          >
                            Refundable
                          </span>
                        </>
                      )}
                    </div>
                  </li>
                  <li className="flight_time">
                    <span className="flightBookingHiddenDelhi">
                      {segment.Destination}{" "}
                      {/* {segment.Destination.Airport.CountryCode} */}
                    </span>
                    <strong>
                      {new Date(segment.ArrivalDateTime).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        }
                      )}
                    </strong>
                    <span className="date">
                      {formatTime(segment.ArrivalDateTime)}
                    </span>
                    <span className="flightBookingHiddenTitle"> Pune, IN</span>
                    <span className="airport">
                      {segment.DestinationAirport.airport_name}{" "}
                      {/* {segment.ArrivalCity.AIRPORTNAME} */}
                      {/* , Terminal{" "} */}
                      {/* {segment.Destination.Airport.Terminal}{" "} */}
                    </span>
                  </li>
                </ul>
                <div className="clearfix" />
                {index < flight.length - 1 && (
                  <div className="layover_time">
                    <div className="layover_txt">
                      Layover:
                      <span>
                        {formatLayoverTime(
                          segment.ArrivalDateTime,
                          flight.AirSegments[index + 1].DepartureDateTime
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* .box-result end */}
      </div>
      <div className="block-content-2 custom_block_content">
        <div className="box-result custom_box_result">
          <div className="flight_tags depart_tags">
            <span style={{ zIndex: 2 }}>Return</span>
          </div>

          <div>
            {flight[0].AirSegments[1].map((segment, index) => (
              <React.Fragment key={index}>
                <ul className="list-search-result booking_list forMinScreenul">
                  <li className="flight_name">
                    <img
                      src={`/Images/AirlineLogo/${segment.AirlineCode}.gif`}
                      alt=""
                      style={{ width: "50px" }}
                    />

                    <div className="name">
                      {" "}
                      {segment.AirlineName}
                      <span className="flight_no">
                        {/* {segment.Airline.AirlineCode}- */}
                        {segment.FlightNumber}
                      </span>
                      {/* <span className="flightBookingHiddenTitle">
                          Economy
                        </span> */}
                    </div>
                  </li>
                  <li className="flight_time">
                    <span className="flightBookingHiddenDelhi">
                      {" "}
                      {segment.Origen}{" "}
                      {/* {segment.Origin.Airport.CountryCode} */}
                    </span>
                    <strong>
                      {new Date(segment.DepartureDateTime).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        }
                      )}
                    </strong>
                    <span className="date">
                      {formatTime(segment.DepartureDateTime)}
                    </span>
                    <span className="flightBookingHiddenTitle">
                      {" "}
                      {/* {segment.DepartureCity.COUNTRYNAME},{" "}
                    {segment.DepartureCity.COUNTRYCODE} */}
                    </span>
                    <span className="airport">
                      {segment.SourceAirport.airport_name}{" "}
                      {/* {segment.DepartureCity.AIRPORTNAME} */}
                      {/* , Terminal{" "} */}
                      {/* {segment.Origin.Airport.Terminal} */}
                    </span>
                  </li>
                  <li className="flight_amenties">
                    <div className="top">
                      <span className="duration">
                        <i
                          className="fa fa-clock"
                          style={{ marginRight: "3px" }}
                        />
                        {segment.Duration}
                      </span>
                      <span className="flightDetailSpantag"></span>
                      <span className="grey_rtbrder">|</span>{" "}
                      <span className="flightBookingHiddenDelhi">
                        {segment.TypeOfClass === 0 && "Economy"}
                        {segment.TypeOfClass === 1 && "First"}

                        {segment.TypeOfClass === 3 && "Business"}
                        {segment.TypeOfClass === 4 && "Premium_Economy"}
                      </span>
                    </div>

                    <div className="middle">
                      <span className="txt flighttxt">
                        <i className="fa fa-plane" />
                      </span>
                    </div>

                    <div className="bottom">
                      {segment.BaggageAllowed && (
                        <span className="wght">
                          {segment.BaggageAllowed.CheckInBaggage}
                        </span>
                      )}

                      {/* {segment.AirSegments[0].BaggageAllowed &&
                          flight.Refundable && (
                            <span className="grey_rtbrder">|</span>
                          )} */}

                      {flight.Refundable && (
                        <>
                          <span
                            className="refundable"
                            style={{ marginLeft: "5px" }}
                          >
                            Refundable
                          </span>
                        </>
                      )}
                    </div>
                  </li>
                  <li className="flight_time">
                    <span className="flightBookingHiddenDelhi">
                      {segment.Destination}{" "}
                      {/* {segment.Destination.Airport.CountryCode} */}
                    </span>
                    <strong>
                      {new Date(segment.ArrivalDateTime).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        }
                      )}
                    </strong>
                    <span className="date">
                      {formatTime(segment.ArrivalDateTime)}
                    </span>
                    <span className="flightBookingHiddenTitle"> Pune, IN</span>
                    <span className="airport">
                      {segment.DestinationAirport.airport_name}{" "}
                      {/* {segment.ArrivalCity.AIRPORTNAME} */}
                      {/* , Terminal{" "} */}
                      {/* {segment.Destination.Airport.Terminal}{" "} */}
                    </span>
                  </li>
                </ul>
                <div className="clearfix" />
                {index < flight.length - 1 && (
                  <div className="layover_time">
                    <div className="layover_txt">
                      Layover:
                      <span>
                        {formatLayoverTime(
                          segment.ArrivalDateTime,
                          flight.AirSegments[index + 1].DepartureDateTime
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div>
          {" "}
          <button className="detail-fair" onClick={() => setActive(!active)}>
            Fare Rule
          </button>
          {active ? (
            <div>
              <FareRule fareRule={fareRule} />
            </div>
          ) : null}
        </div>

        {/* .box-result end */}
      </div>
    </div>
  );
};
// {fareRule ? <FareRule fareRule={fareRule} /> : <GoodToKnow />}
export default DepatureDetail;
