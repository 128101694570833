import React from "react";
import "./Home.css";
import Hero from "./Hero";
import MyComponent from "./MyComponent";
import ExclusiveOffer from "./Exclusiveoffer";
import Partner from "./Partner";
import WhyChoose from "./WhyChoose";
import FlightDeal from "./FlightDeal";

function Home() {
  return (
    <div>
      <Hero />
      {/* <MyComponent /> */}
      <MyComponent />
      
      <ExclusiveOffer />
      <Partner />
      <WhyChoose />
      <FlightDeal />
      
    </div>
  );
}

export default Home;
