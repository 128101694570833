import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ExclusiveOffer.css";
function ExclusiveOffer() {
  return (
    <section className="about-area bottom-padding1 position-relative">
      <Container>
        <Row>
          <Col xl={8}>
            <div className="section-title mb-30">
              {/* <span className="highlights">about us</span> */}
              <h4 className="title">
                Exclusive Benefits for Yatri Service Agents
              </h4>
              {/* <p className="pera">
                
Exclusive Benefits for Yatri Service Agents
              </p> */}
            </div>
          </Col>
        </Row>
        <Row className="g-4">
          <Col xl={8} lg={7}>
            <div className="tab-content" id="v-pills-tabContent">
              <div className="about-banner imgEffect4">
                <img
                  src="https://compote.slate.com/images/bae987ac-78e9-455d-a4c4-1d83707390d7.jpeg?crop=1560%2C1040%2Cx0%2Cy0"
                  alt="yatri service"
                />
              </div>
            </div>
          </Col>
          <Col xl={4} lg={5}>
            <div
              className="key-points position-relative z-12"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div className="key-point active" id="v-pills-one-tab">
                <div className="key-icon">
                  <span>$</span>
                </div>
                <div className="key-content">
                  <h4 className="title">Personalized Support </h4>
                  <p className="pera">
                    Our dedicated account managers are here to assist you
                    personally. Get tailored advice, industry insights, and
                    support to help you succeed.
                  </p>
                </div>
              </div>
              <div className="key-point " id="v-pills-one-tab">
                <div className="key-icon">
                  <span>$</span>
                </div>
                <div className="key-content">
                  <h4 className="title">Collaborate with Leading Airlines </h4>
                  <p className="pera">
                    Benefit from our partnerships with renowned airlines. Access
                    special deals, discounts, and unique offerings for your
                    clients.
                  </p>
                </div>
              </div>
              {/* <div
  className="key-point "
  id="v-pills-one-tab"
 
>
  <div className="key-icon">
    <span>$</span>
  </div>
  <div className="key-content">
    <h4 className="title">Hotel and Car Rental Packages</h4>
    <p className="pera">
    Extend your services beyond flights. Explore bundled packages with exclusive rates for hotels and car rentals, providing your clients with a complete travel experience.
    </p>
  </div>
</div> */}
              <div className="key-point" id="v-pills-one-tab">
                <div className="key-icon">
                  <span>$</span>
                </div>
                <div className="key-content">
                  <h4 className="title">Enhanced Security and Compliance</h4>
                  <p className="pera">
                    Rest easy knowing that Yatri Service prioritizes the
                    security of your data and transactions. Our platform
                    complies with industry standards, providing a secure
                    environment for all your business activities.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <div className="shape-bg-about">
        <img src="assets/images/icon/bg-shape-2.png" alt="travello" />
      </div> */}
    </section>
  );
}

export default ExclusiveOffer;
