import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const TravellerInformation = ({
  flight,
  handleInputChange,
  handleInputChange1,
  handleInputChange2,
  handleExpiryDateChange,
  handleExpiryDateChange1,
  handleExpiryDateChange2,
  handleDOBChange,
  handleDOBChange1,
  handleDOBChange2,
  isinternational,
}) => {
  return (
    <div className="traveller_info">
      <h4>Traveller Information</h4>

      {flight && (
        <Row>
          <React.Fragment>
            {[
              ...Array(
                flight.PexFareDetails[0] && flight.PexFareDetails[0].TotPax
              ),
            ].map((_, index) => (
              <React.Fragment key={index}>
                <Col sm={12} className="passport_field">
                  <Row style={{marginBottom:'5px'}}>
                    <Col sm={2} className="form_field_col2">
                      <div className="contact_label cus_label">
                        <Form.Label>Adults {index + 1}</Form.Label>
                      </div>
                    </Col>
                    <Col sm={10} className="form_field_col10">
                      <div className="form_field form_select_field">
                        <div className="cus_field_input cus_select_title">
                          <Form.Control
                            as="select"
                            name={`title${index}`}
                            className="title form-control commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          >
                            <option>Title</option>
                            <option value="Mr">Mr.</option>
                            <option value="Ms">Ms.</option>
                            <option value="Mrs">Mrs.</option>
                          </Form.Control>
                        </div>
                        <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">Title & First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name={`firstName${index}`}
                            placeholder="First Name"
                            className="form-control firstname commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form_field">
                        <div className="cus_field_input cus_name_field">
                        <Form.Label className="travellers-service-form-controll">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name={`lastName${index}`}
                            placeholder="Last Name"
                            className="form-control firstname commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="date_field">
                        <div className="cus_field_input">
                        <Form.Label className="travellers-service-form-controll">Date Of Birth</Form.Label>
                          <Form.Control
                            type="date"
                            name={`date${index}`}
                            style={{ height: "auto" }}
                            className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                            onChange={(e) => handleDOBChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {isinternational && (
                    <Row>
                      <Col sm={2} className="form_field_col2">
                        <div className="contact_label cus_label">
                          <Form.Label>Passport Detail</Form.Label>
                        </div>
                      </Col>
                      <Col sm={10} className="form_field_col10 ">
                        {/* <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                        <div className="form_field">
                          <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">Passport Number</Form.Label>
                            <Form.Control
                              type="text"
                              name={`passport${index}`}
                              placeholder="Passport No."
                              className="form-control firstname commoninputreset"
                              onChange={(e) => handleInputChange(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>

                        {/* <div
                        className="date_field"
                        style={{ marginRight: "15px" }}
                      >
                        <div className="cus_field_input">
                          <Form.Control
                            type="date"
                            name={`issuedate${index}`}
                            style={{ height: "auto" }}
                            placeholder="Issue Date"
                            className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div> */}

                        <div className="date_field">
                          <div className="cus_field_input">
                          <Form.Label className="travellers-service-form-controll">Passport Expiry Date</Form.Label>
                            <Form.Control
                              type="date"
                              name={`expirydate${index}`}
                              style={{ height: "auto" }}
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              onChange={(e) => handleExpiryDateChange(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </React.Fragment>
            ))}
          </React.Fragment>

          {flight.PexFareDetails[1] && (
            <React.Fragment>
              {[...Array(flight.PexFareDetails[1].TotPax)].map((_, index) => (
                <React.Fragment key={index}>
                  <Col sm={12} className="passport_field">
                  <Row style={{marginBottom:'5px'}}>
                      <Col sm={2} className="form_field_col2">
                        <div className="contact_label cus_label">
                          <Form.Label>Child {index + 1}</Form.Label>
                        </div>
                      </Col>
                      <Col sm={10} className="form_field_col10">
                        <div className="form_field form_select_field">
                          <div className="cus_field_input cus_select_title">
                            <Form.Control
                              as="select"
                              name={`title${index}`}
                              className="title form-control commoninputreset"
                              onChange={(e) => handleInputChange1(index, e)}
                              autoComplete="off"
                            >
                              <option>Title</option>
                              <option value="Mr">Mr.</option>
                              <option value="Ms">Ms.</option>
                              <option value="Mrs">Mrs.</option>
                            </Form.Control>
                          </div>
                          <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">Title & First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name={`firstName${index}`}
                              placeholder="First Name"
                              className="form-control firstname commoninputreset"
                              onChange={(e) => handleInputChange1(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form_field">
                          <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name={`lastName${index}`}
                              placeholder="Last Name"
                              className="form-control firstname commoninputreset"
                              onChange={(e) => handleInputChange1(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>

                        <div className="date_field">
                          <div className="cus_field_input">
                          <Form.Label className="travellers-service-form-controll">Date Of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              name={`date${index}`}
                              style={{ height: "auto" }}
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              onChange={(e) => handleDOBChange1(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {isinternational && (
                      <Row>
                        <Col sm={2} className="form_field_col2">
                          <div className="contact_label cus_label">
                            <Form.Label>Passport Detail</Form.Label>
                          </div>
                        </Col>
                        <Col sm={10} className="form_field_col10 ">
                          {/* <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                          <div className="form_field">
                            <div className="cus_field_input cus_name_field">
                            <Form.Label className="travellers-service-form-controll">Passport Number</Form.Label>
                              <Form.Control
                                type="text"
                                name={`passport${index}`}
                                placeholder="Passport No."
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange1(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          {/* <div
                          className="date_field"
                          style={{ marginRight: "15px" }}
                        >
                          <div className="cus_field_input">
                            <Form.Control
                              type="date"
                              name={`issuedate${index}`}
                              style={{ height: "auto" }}
                              placeholder="Issue Date"
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              onChange={(e) => handleInputChange1(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div> */}

                          <div className="date_field">
                            <div className="cus_field_input">
                            <Form.Label className="travellers-service-form-controll">Passport Expiry Date</Form.Label>
                              <Form.Control
                                type="date"
                                name={`expirydate${index}`}
                                style={{ height: "auto" }}
                                className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                onChange={(e) =>
                                  handleExpiryDateChange1(index, e)
                                }
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}

          {flight.PexFareDetails[2] && (
            <React.Fragment>
              {[...Array(flight.PexFareDetails[2].TotPax)].map((_, index) => (
                <React.Fragment key={index}>
                  <Col sm={12} className="passport_field">
                  <Row style={{marginBottom:'5px'}}>
                      <Col sm={2} className="form_field_col2">
                        <div className="contact_label cus_label">
                          <Form.Label>Infant {index + 1}</Form.Label>
                        </div>
                      </Col>
                      <Col sm={10} className="form_field_col10">
                        <div className="form_field form_select_field">
                          <div className="cus_field_input cus_select_title">
                            <Form.Control
                              as="select"
                              name={`title${index}`}
                              className="title form-control commoninputreset"
                              onChange={(e) => handleInputChange2(index, e)}
                              autoComplete="off"
                            >
                              <option>Title</option>
                              <option value="Mr">Mr.</option>
                              <option value="Ms">Ms.</option>
                              <option value="Mrs">Mrs.</option>
                            </Form.Control>
                          </div>
                          <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">Title & First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name={`firstName${index}`}
                              placeholder="First Name"
                              className="form-control firstname commoninputreset"
                              onChange={(e) => handleInputChange2(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form_field">
                          <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name={`lastName${index}`}
                              placeholder="Last Name"
                              className="form-control firstname commoninputreset"
                              onChange={(e) => handleInputChange2(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="date_field">
                          <div className="cus_field_input">
                          <Form.Label className="travellers-service-form-controll">Date Of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              name={`date${index}`}
                              style={{ height: "auto" }}
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              autoComplete="off"
                              onChange={(e) => handleDOBChange2(index, e)}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {isinternational && (
                      <Row>
                        <Col sm={2} className="form_field_col2">
                          <div className="contact_label cus_label">
                            <Form.Label>Passport Detail</Form.Label>
                          </div>
                        </Col>
                        <Col sm={10} className="form_field_col10 ">
                          {/* <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                          <div className="form_field">
                            <div className="cus_field_input cus_name_field">
                            <Form.Label className="travellers-service-form-controll">Passport Number</Form.Label>
                              <Form.Control
                                type="text"
                                name={`passport${index}`}
                                placeholder="Passport No."
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          {/* <div
                            className="date_field"
                            style={{ marginRight: "15px" }}
                          >
                            <div className="cus_field_input">
                              <Form.Control
                                type="date"
                                name={`issuedate${index}`}
                                style={{ height: "auto" }}
                                placeholder="Issue Date"
                                className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}

                          <div className="date_field">
                            <div className="cus_field_input">
                            <Form.Label className="travellers-service-form-controll">Passport Expiry Date</Form.Label>
                              <Form.Control
                                type="date"
                                name={`expirydate${index}`}
                                style={{ height: "auto" }}
                                className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                onChange={(e) =>
                                  handleExpiryDateChange2(index, e)
                                }
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </Row>
      )}
      <div
        className="alert-wrapper makeFlex appendBottom12 appendTop12 travellerSectionAlert"
        style={{ backgroundColor: "#e2cff966", color: "#fff" }}
      >
        <div className="makeFlex flexOne column">
          <p className="darkText fontSize12 boldFont">
            <span className="alert-text">
              <b>Important: </b>Please ensure that the names of the passengers
              on the travel documents are the same as on their government-issued
              identity proof.
              <br />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TravellerInformation;
