import React, { useEffect, useState } from "react";
import { FaAngleDown, FaRegThumbsUp } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Nav,
  Modal,
  Tab,
  Card,
  Button,
} from "react-bootstrap";
import { HiPlusSmall } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiMiniMinusSmall } from "react-icons/hi2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
// import DepatureDetail from "./DepartureDetail";
// import FlightDetailSkeleton from "./FlightDetailSkeleton";
import GoodToKnow from "../../../FlightDetails/GoodToKnow";
import FareRule from "../../../FlightDetails/FareRule";
// import { cities2 } from "../../Cities";
// import { cities2 } from "../../../../Cities";
import ChargesOneWay from "../../../FlightDetails/ChargesOneWay";
import BDSend from "../../../FlightDetails/BDSend";
import FlightPayModal from "./FlightPayModal";
import ChargesRound from "../../../FlightDetails/ChargesRound";
import SSRContent from "../../../FlightDetails/SSRContent";
import TravellerInformation from "./TravellerInformation";
import DepatureDetail from "./DepartureDetail";
import FlightDetailSkeleton from "../../../FlightDetails/FlightDetailSkeleton";
import PriceDetail from "./PriceDetail";
import { Markup } from "../../../../redux/services/operations/markup";
import { FaClock } from "react-icons/fa6";
import { resetTimer } from "../../../../redux/slices/flightSlice";

const FlightInternationalDetail = () => {
  const dispatch = useDispatch();
  const [walletDatas, setWalletDatas] = useState(0);
  const [creditLimits, setCreditLimits] = useState(0);
  useEffect(() => {
    const data = localStorage.getItem("walletBalance");
    const datas = localStorage.getItem("CreditLimit");
    if (data) {
      setWalletDatas(data);
    }
    if (datas) {
      setCreditLimits(datas);
    }

    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
  }, []);
  const { adminIm, agentIm } = useSelector((state) => state.markUp);
  const [openPayBtn, setOpenPayBtn] = useState(false);
  const decodedIndex = decodeURIComponent(useParams().index);

  const [searched, setSearched] = useState(null);
  const [fareRule, setFareRule] = useState(null);
  const [ssrResponse, setSsrResponse] = useState(null);
  const [flight, setFlight] = useState(null);
  const [flight2, setFlight2] = useState(null);
  const [fareRule2, setFareRule2] = useState(null);
  const [ssrResponse2, setSsrResponse2] = useState(null);
  const [emiBtn, setEmiBtn] = useState(false);
  const search = useSelector((state) => state.flight.search);
  const [passengerBaggagePreferences, setPassengerBaggagePreferences] =
    useState([]);
  const [passengerSeatPreferences, setPassengerSeatPreferences] = useState([]);
  const [passengerMealPreferences, setPassengerMealPreferences] = useState([]);
  const [token, setToken] = useState();
  const [bookingKey, setBookingKey] = useState();
  const [fareActive, setFareActive] = useState(true);

  const parseFareRule = (fareRuleDetail) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = fareRuleDetail;
    const listItems = Array.from(tempDiv.querySelectorAll("li")).map((li) =>
      li.textContent.trim()
    );

    return listItems;
  };
  const storedBookingKey = String(sessionStorage.getItem("bookingId"));
  const storedToken = String(localStorage.getItem("token"));
  const [tokenss, setTokenss] = useState(storedToken);
  const [bookingKeyss, setBookingKeyss] = useState(storedBookingKey);

  const timer = useSelector((state) => state.flight.timer);
  const [showsessionModal, setShowsessionModal] = useState(false);

  // Effect to open the modal when the timer hits 0
  useEffect(() => {
    if (timer.minutes === 0 && timer.seconds === 0) {
      setShowsessionModal(true);
    }
  }, [timer]);

  // Function to handle closing the modal and resetting the timer or starting a new search
  const handleOk = () => {
    setShowsessionModal(false);
    dispatch(resetTimer());
    navigate(-1, { replace: true });
    // Optionally, navigate to the search page or trigger a new search
  };

  useEffect(() => {
    const storedBookingKey = String(sessionStorage.getItem("bookingId"));
    const storedToken = String(localStorage.getItem("token"));

    if (storedBookingKey && storedToken) {
      setBookingKey(storedBookingKey);
      setToken(storedToken);
    } else {
      setBookingKey(undefined);
      setToken(undefined);
    }
    console.log("TOKENNNN", token);
    console.log("bookinggggg", bookingKey);
    console.log("DECODE!", decodedIndex);

    const fetchData = async () => {
      try {
        const response1 = await axios.post(
          "https://admin.yatriservice.com/api/flightfarerule",
          {
            APIToken: token || tokenss,
            BookingKey: bookingKey || bookingKeyss,
            ContractId: decodedIndex,
          }
        );
        console.log("RESPONSE @@@@ 2222", response1);
        setFareActive(true);
        const response2 = await axios.post(
          "https://admin.yatriservice.com/api/flightsell",
          {
            APIToken: token || tokenss,
            BookingKey: bookingKey || bookingKeyss,
            ContractId: decodedIndex,
          }
        );
        console.log("DATA IN INTERNTATION DAYY", {
          APIToken: token || tokenss,
          BookingKey: bookingKey || bookingKeyss,
          ContractId: parseInt(decodedIndex),
        });
        console.log("RESPONSE @@@@ 2222", response2);
        if (response2.data.success) {
          setFareActive(false);
        }

        // setSearched(response2.data.data.Sell);

        // if (response2.data.success) {
        //   const originDestinationOptions =
        //     response2.data.data.Sell && response2.data.data.Sell.Contracts[0];

        //   originDestinationOptions &&
        //     originDestinationOptions.Contracts[0].AirSegments.forEach(
        //       (segment) => {
        //         const departureCode = segment.Origen;
        //         const arrivalCode = segment.Destination;

        //         const departureCity = cities2.find(
        //           (city) => city.AIRPORTCODE === departureCode
        //         );
        //         const arrivalCity = cities2.find(
        //           (city) => city.AIRPORTCODE === arrivalCode
        //         );

        //         segment.DepartureCity = departureCity;
        //         segment.ArrivalCity = arrivalCity;
        //       }
        //     );
        //   // if (decodedIndex2 !== "" && decodedIndex2 !== "undefined") {
        //   //   const originDestinationOptions2 =
        //   //     response2.data.data.Sell &&
        //   //     response2.data.data.Sell.Contracts[1].AirSegments;

        //   //   originDestinationOptions2 &&
        //   //     originDestinationOptions2.forEach((segment) => {
        //   //       const departureCode = segment.Origen;
        //   //       const arrivalCode = segment.Destination;

        //   //       const departureCity = cities2.find(
        //   //         (city) => city.AIRPORTCODE === departureCode
        //   //       );
        //   //       const arrivalCity = cities2.find(
        //   //         (city) => city.AIRPORTCODE === arrivalCode
        //   //       );

        //   //       segment.DepartureCity = departureCity;
        //   //       segment.ArrivalCity = arrivalCity;
        //   //     });
        //   // }
        //   // setFlight(response2.data.data.Sell);
        // }
        // console.log("response1", response1);
        // console.log("response2", response2);
        // console.log("response12", response12);

        setSearched(response2.data.data.Sell);
        // setFlight2(response2.data.data.Sell.Contracts[1]);

        setFareRule(response1.data.data.FareRule);
        const newPassengers =
          response2.data.data.Sell &&
          response2.data.data.Sell.Contracts[0].PexFareDetails.flatMap(
            ({ PaxType, TotPax }) => {
              const typeLabel =
                PaxType === 1 ? "Adult" : PaxType === 2 ? "Child" : "Infant";
              return Array.from({ length: TotPax }, (_, index) => ({
                id: index + 1,
                type: typeLabel,
                name: `Passenger ${index + 1}`,
              }));
            }
          );

        setPassengers(newPassengers);
        console.log("newPassenger", newPassengers);
      } catch (error) {
        console.error("Error fetching flight data:", error);
      }
    };
    fetchData();
  }, [decodedIndex, token, bookingKey]);
  console.log("FLEIWGIBFI", searched);
  console.log("FARE RULE", fareRule);

  const restructureSegments = (search) => {
    return (
      searched &&
      searched.Contracts.map((item) => {
        const onwardSegments = item.AirSegments.filter(
          (segment) => segment.SegmentType === "Onword"
        );
        const returnSegments = item.AirSegments.filter(
          (segment) => segment.SegmentType === "Return"
        );
        return {
          ...item,
          AirSegments: [onwardSegments, returnSegments],
        };
      })
    );
  };

  useEffect(() => {
    if (searched) {
      const data = restructureSegments(searched);
      setFlight(data);
      console.log("SERCHED UFFERECT DATA", data);
    }
  }, [searched]);
  const [internationalMarkup, setInternationalMarkup] = useState(0);
  useEffect(() => {
    const storedFare = localStorage.getItem("add-int");

    if (storedFare) {
      let finalPrice =
        storedFare !== undefined && storedFare !== null ? storedFare : 0;
      setInternationalMarkup(parseInt(finalPrice));
    } else {
      setInternationalMarkup(0);
    }
  }, [searched]);
  console.log("FLEIWGIBFIwreyttrhrthrthtrhgrthbrtghrthbgrt6dgh", flight);
  const [promoCode, setPromoCode] = useState("");
  const [appliedCode, setAppliedCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleApply = () => {
    // Apply logic here
    setAppliedCode(promoCode);
    setSuccessMessage("Promo code applied successfully!");
    setPromoCode("");
  };

  const handleClear = () => {
    // Clear logic here
    setAppliedCode("");
    setSuccessMessage("");
  };

  const navigate = useNavigate();
  const handlePayment = () => {
    navigate("/agent/flight-ticket");
  };

  const formatTime = (arrTime) => {
    const date = new Date(arrTime);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = days[date.getDay()];
    const dateNum = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${dateNum} ${month} ${year}`;
  };

  const [showdetail, setShowdetail] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [passengers, setPassengers] = useState([]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const [formData, setFormData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [infant, setInfant] = useState([]);

  // const handleInputChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const cleanedName = name.replace(/\d+/g, "");
  //   const list = [...formData];
  //   if (!list[index]) {
  //     list[index] = {};
  //   }
  //   list[index][cleanedName] = value;
  //   setFormData(list);
  //   console.log("adultdata", formData);
  // };

  // const handleInputChange1 = (index, e) => {
  //   const { name, value } = e.target;
  //   const cleanedName = name.replace(/\d+/g, "");

  //   const list = [...childData];
  //   if (!list[index]) {
  //     list[index] = {};
  //   }
  //   list[index][cleanedName] = value;
  //   setChildData(list);
  // };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");
    const list = [...formData];

    // Function to convert the date from YYYY-MM-DD to DD-MM-YYYY
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    // Check if the field is the expiry date and format it
    let formattedValue = value;
    if (cleanedName === "expirydate") {
      formattedValue = formatDate(value);
    }

    if (!list[index]) {
      list[index] = {};
    }

    list[index][cleanedName] = formattedValue; // Set the formatted date or other values
    setFormData(list);

    console.log("adultdata", formData);
  };

  const handleInputChange1 = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");

    const list = [...childData];

    // Function to convert the date from YYYY-MM-DD to DD-MM-YYYY
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    // Check if the field is the expiry date and format it
    let formattedValue = value;
    if (cleanedName === "expirydate") {
      formattedValue = formatDate(value);
    }

    if (!list[index]) {
      list[index] = {};
    }

    list[index][cleanedName] = formattedValue; // Set the formatted date or other values
    setChildData(list);
  };

  const handleInputChange2 = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");

    const list = [...infant];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setInfant(list);
  };
  const isValidExpiryDate = (index, date) => {
    const flightArrTime = new Date(
      flight[0].AirSegments[1][
        flight[0].AirSegments[1].length - 1
      ].ArrivalDateTime
    );

    const expiryDate = new Date(date);

    const sixMonthsAfterArrTime = new Date(
      flightArrTime.setMonth(flightArrTime.getMonth() + 6)
    );

    return expiryDate >= sixMonthsAfterArrTime;
  };

  const handleExpiryDateChange = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange(index, e);
    }
  };

  const handleExpiryDateChange1 = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange1(index, e);
    }
  };

  const handleExpiryDateChange2 = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange2(index, e);
    }
  };

  const isValidDateOfBirth = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 12;
  };

  const isValidDateOfBirth1 = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 2 && age < 12;
  };

  const isValidDateOfBirth2 = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age < 2;
  };

  const handleDOBChange = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth(value)) {
      alert("The age must be greater than 12 years.");
    } else {
      handleInputChange(index, e);
    }
  };

  const handleDOBChange1 = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth1(value)) {
      alert("The age must be between 2 to 12 years.");
    } else {
      handleInputChange1(index, e);
    }
  };

  const handleDOBChange2 = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth2(value)) {
      alert("The age must be less than 2 years.");
    } else {
      handleInputChange2(index, e);
    }
  };
  const handleTicketBook = async () => {
    function generateUUID() {
      const timestamp = new Date().getTime().toString(16); // Convert timestamp to hexadecimal
      const randomChars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let randomString = "";

      for (let i = 0; i < 8; i++) {
        randomString += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }

      return `${timestamp}-${randomString}`;
    }

    let isFirstAdult = true;

    const adultPassengers = formData.map((data, index) => {
      const isLeadPax = isFirstAdult;
      isFirstAdult = false;
      return {
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 1,
        DateOfBirth: data.date,
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || "P4366918null",
        PassportExpiry: data.expirydate || "25-07-2030",
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare: flight[0].PexFareDetails[0].BaseFare * 1.0 + 0.0,
          TaxFare: flight[0].PexFareDetails[0].TaxFare * 1.0,
          GrossFare: flight[0].PexFareDetails[0].GrossFare * 1.0,
          YQTax: flight[0].PexFareDetails[0].YQTax * 1.0,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengers = (passenger) => {
      if (flight[0].PexFareDetails[0].TotPax > 1) {
        const divisor = flight.PexFareDetails[0].TotPax;
        const adjustedPaxFare = {
          BaseFare: flight[0].PexFareDetails[0].BaseFare / divisor,
          TaxFare: flight[0].PexFareDetails[0].TaxFare / divisor,
          GrossFare: flight[0].PexFareDetails[0].GrossFare / divisor,
          YQTax: flight[0].PexFareDetails[0].YQTax / divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };

    let isChildAdult = true;
    const childPassengers = childData.map((data) => {
      const isLeadPax = isChildAdult;
      isChildAdult = false;
      return {
        Title:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 2,
        DateOfBirth: data.date,
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || null,
        PassportExpiry: data.expirydate || null,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare: flight[0].PexFareDetails[1].BaseFare * 1.0,
          TaxFare: flight[0].PexFareDetails[1].TaxFare * 1.0,
          GrossFare: flight[0].PexFareDetails[1].GrossFare * 1.0,
          YQTax: flight[0].PexFareDetails[1].YQTax * 1.0,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengersChild = (passenger) => {
      if (flight[0] && flight[0].PexFareDetails[1].TotPax > 1) {
        const divisor = flight[0].PexFareDetails[1].TotPax;
        const adjustedPaxFare = {
          BaseFare: flight[0].PexFareDetails[1].BaseFare / divisor,
          TaxFare: flight[0].PexFareDetails[1].TaxFare / divisor,
          GrossFare: flight[0].PexFareDetails[1].GrossFare / divisor,
          YQTax: flight[0].PexFareDetails[1].YQTax / divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };

    let isInfantAdult = true;
    const infantPassengers = infant.map((data) => {
      const isLeadPax = isInfantAdult;
      isInfantAdult = false;
      return {
        Title:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 3,
        DateOfBirth: data.date,
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport,
        PassportExpiry: data.expirydate,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare: flight[0].PexFareDetails[2].BaseFare * 1.0,
          TaxFare: flight[0].PexFareDetails[2].TaxFare * 1.0,
          GrossFare: flight[0].PexFareDetails[2].GrossFare * 1.0,
          YQTax: flight[0].PexFareDetails[2].YQTax * 1.0,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengersInfant = (passenger) => {
      if (flight[0] && flight[0].PexFareDetails[2].TotPax > 1) {
        const divisor = flight[0].PexFareDetails[2].TotPax;
        const adjustedPaxFare = {
          BaseFare: flight[0].PexFareDetails[2].BaseFare / divisor,
          TaxFare: flight[0].PexFareDetails[2].TaxFare / divisor,
          GrossFare: flight[0].PexFareDetails[2].GrossFare / divisor,
          YQTax: flight[0].PexFareDetails[2].YQTax / divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };

    let preferenceIndex = 0; // Initialize a counter for the preference index

    const allPassengers = [
      ...adultPassengers.map((passenger) => {
        const adjustedPassenger = adjustPaxFareForMultiplePassengers(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),

      ...childPassengers.map((passenger) => {
        const adjustedPassenger =
          adjustPaxFareForMultiplePassengersChild(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),

      ...infantPassengers.map((passenger) => {
        const adjustedPassenger =
          adjustPaxFareForMultiplePassengersInfant(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),
    ];

    const requestData = {
      // PreferredCurrency: null,
      // ResultIndex: decodedIndex,
      APIToken: token || tokenss,
      BookingKey: bookingKey || bookingKeyss,
      ContractId: parseInt(decodedIndex),
      AgentId: walletData && walletData.Id,
      Flightpassenger: allPassengers,
      CountryCode: "IN",
      APIGst: null,
      CountryCode: "IN",
      BookingId: generateUUID(),
      IsHoldRequest: false,
      AccountNo: "27",
    };
    console.log("requestData", requestData);

    // Make the API call
    const apiUrl = "https://admin.yatriservice.com/api/flightbooking";
    const deductData = {
      AgentEmail: walletData && walletData.Email,
      FlightAmount: flight[0].AirlineFare.NetFare + adminIm + adminIm,
    };
    // flight &&
    //   flight.IsLCC &&
    const deductUrl =
      "https://admin.yatriservice.com/api/deduct-wallet-balance";
    // flight &&
    //   flight.IsLCC &&

    const data = await axios.post(apiUrl, requestData);
    if (data) {
      console.log("LCC Flight ticket booked successfully:", data);

      const respons = await axios.post(deductUrl, deductData);
      if (respons) {
        console.log("DEDUCT AMT DATA", respons.data.data.WalletBalalnce);
        localStorage.setItem("walletBalance", respons.data.data.WalletBalalnce);
        localStorage.setItem("CreditLimit", respons.data.data.CreditLimit);
      }
      sessionStorage.setItem(
        "BookingIdd",
        parseInt(data.data.data.flightbook.BookingId)
      );
      sessionStorage.setItem(
        "AirlinePnr",
        data.data.data.flightbook.AirlinePnr
      );
      window.location.assign("/agent/flight-tickets");
    } else {
      console.log("LCC ERROR FOUND IN BOOKING");
    }
  };
  const { walletData } = useSelector((state) => state.auth);
  const [walletDataa, setWalletDataa] = useState({
    Phone: walletData.Phone,
    Email: walletData.Email,
  });

  const handleDataUpdate = (updatedData) => {
    setWalletDataa((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  const handleChangeFlight = () => {
    navigate(-1);
  };
  const validatePassengerData = () => {
    // Define the required fields for each passenger type
    const requiredFields = ["title", "firstName", "lastName"];
    const passportFields = ["passport", "issuedate", "expirydate"];

    // Check if passport details are mandatory
    // const isPassportMandatory =
    //   flight.PricedItinerary.IsPassportIssueDateMandatory ||
    //   flight.PricedItinerary.IsPassportMandatory ||
    //   flight.PricedItinerary.IsPassportNameWithSpace;

    // Helper function to check if an object has all required fields filled
    const isValid = (data, index, type) => {
      for (const field of requiredFields) {
        if (!data[field] || data[field].trim() === "") {
          alert(`Please fill out the ${field} for ${type} ${index + 1}`);
          return false;
        }
      }
      // Check for passport fields if they are mandatory
      // if (isPassportMandatory) {
      //   for (const field of passportFields) {
      //     if (!data[field] || data[field].trim() === "") {
      //       alert(`Please fill out the ${field} for ${type} ${index + 1}`);
      //       return false;
      //     }
      //   }
      // }
      return true;
    };

    // Check if data is present for all passengers
    const adultCount = flight[0] && flight[0].PexFareDetails[0].TotPax;
    const childCount = flight[0].PexFareDetails[1]
      ? flight[0].PexFareDetails[1].TotPax
      : 0;

    const infantCount = flight[0].PexFareDetails[1]
      ? flight[0].PexFareDetails[2].TotPax
      : 0;

    // Validate adult data
    if (formData.length !== adultCount) {
      alert(`Please fill out details for all ${adultCount} adults`);
      return false;
    }
    for (let i = 0; i < formData.length; i++) {
      if (!isValid(formData[i], i, "adult")) return false;
    }

    // Validate child data
    if (childData.length !== childCount) {
      alert(`Please fill out details for all ${childCount} children`);
      return false;
    }
    for (let i = 0; i < childData.length; i++) {
      if (!isValid(childData[i], i, "child")) return false;
    }

    // Validate infant data
    if (infant.length !== infantCount) {
      alert(`Please fill out details for all ${infantCount} infants`);
      return false;
    }
    for (let i = 0; i < infant.length; i++) {
      if (!isValid(infant[i], i, "infant")) return false;
    }

    return true;
  };
  console.log("flightttttttttttt", flight);
  return (
    <div className="roundtrippg">
      <div style={{ position: "relative" }}>
        <Container className="flightBookingMainMain">
          <Row>
            <Col md={9}>
              <div className="booking_title">
                <h3> Review Your Booking</h3>
                <Link
                  className="change_flight"
                  onClick={() => handleChangeFlight()}
                >
                  Change Flight
                </Link>
              </div>
              {/* {flight2 && <DepatureDetail flight={flight2} type="Return" />} */}
              {flight ? (
                <DepatureDetail
                  fareRule={fareRule}
                  flight={flight}
                  type="Departure"
                  fareActive={fareActive}
                  setFareActive={setFareActive}
                />
              ) : (
                <FlightDetailSkeleton />
              )}

              <Row>
                <Col md={12} xs={12}>
                  <div
                    id="TRAVELLER_DETAIL"
                    className="oneCard-element"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="componentContainer ">
                      <div className="paxd">
                        <TravellerInformation
                          flight={flight}
                          handleInputChange={handleInputChange}
                          handleInputChange1={handleInputChange1}
                          handleInputChange2={handleInputChange2}
                          handleExpiryDateChange={handleExpiryDateChange}
                          handleExpiryDateChange1={handleExpiryDateChange1}
                          handleExpiryDateChange2={handleExpiryDateChange2}
                          handleDOBChange={handleDOBChange}
                          handleDOBChange1={handleDOBChange1}
                          handleDOBChange2={handleDOBChange2}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="componentContainer "
                    style={{ padding: "10px 10px", marginBottom: "20px" }}
                  >
                    <div className="myseatinfo">
                      <div className="booking_title">
                        <h3>Addon Services</h3>
                      </div>
                      <div
                        className="block-content-2 custom_block_content"
                        style={{ border: "none", boxShadow: "none" }}
                      >
                        {flight && (
                          <div
                            className="box-result custom_box_result"
                            style={{ display: "flex" }}
                          >
                            <div
                              className="ssrtabs ssrlists"
                              style={{ maxHeight: "400px" }}
                            >
                              <p className="fw-bold mb-2">Select Passenger</p>
                              <Nav variant="tabs" role="tablist">
                                {passengers &&
                                  passengers.map((passenger, index) => (
                                    <Nav.Item key={index}>
                                      <Nav.Link
                                        id={`passenger-tab${index}`}
                                        eventKey={index}
                                        active={index === activeTab}
                                        onClick={() => handleTabChange(index)}
                                      >
                                        <div className="d-flex">
                                          <span className="asradio"></span>{" "}
                                          <label htmlFor={`rdbSSR_${index}`}>
                                            {passenger.type} {passenger.id}
                                          </label>
                                          <tt id={`adultName_${index}`}>
                                            {/* {passenger.name} */}
                                          </tt>
                                        </div>
                                        <ul
                                          className="ssrtabstabs_itinerary"
                                          style={{ padding: "0px" }}
                                        >
                                          <li style={{ marginRight: 5 }}>
                                            <span className="icon">
                                              <i
                                                className="fa fa-suitcase"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <tt id={`bagdetail_${index}`}>0</tt>{" "}
                                            kg
                                          </li>
                                          <li style={{ marginRight: 5 }}>
                                            <span className="icon">
                                              <i
                                                className="fa fa-cutlery"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <tt id={`mealdetail_${index}`}>
                                              0
                                            </tt>{" "}
                                            Platter
                                          </li>
                                          <li style={{ marginRight: 5 }}>
                                            <span className="icon">
                                              <i
                                                className="fa fa-chair"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <tt id={`seatdetail_${index}`}>
                                              {" "}
                                              No Seat
                                            </tt>
                                            <tt
                                              id={`seatdetailinput_${index}`}
                                            ></tt>
                                          </li>
                                        </ul>
                                      </Nav.Link>
                                      {/* </a> */}
                                      {/* </li> */}
                                    </Nav.Item>
                                  ))}
                                {/* </ul> */}
                              </Nav>
                            </div>
                            {/* {ssrResponse && ( */}
                            <div className="ssr_content tab-content">
                              {passengers.map((passenger, index) => (
                                <SSRContent
                                  // srdvIdx={srdvIdx}
                                  key={index}
                                  index={index}
                                  passenger={passenger}
                                  passengers={passengers}
                                  activeTab={activeTab}
                                  ssrResponse={ssrResponse}
                                  ssrResponse2={ssrResponse2}
                                  // handleChangeCurrency={handleChangeCurrency}
                                  passengerBaggagePreferences={
                                    passengerBaggagePreferences
                                  }
                                  setPassengerBaggagePreferences={
                                    setPassengerBaggagePreferences
                                  }
                                  passengerMealPreferences={
                                    passengerMealPreferences
                                  }
                                  setPassengerMealPreferences={
                                    setPassengerMealPreferences
                                  }
                                  passengerSeatPreferences={
                                    passengerSeatPreferences
                                  }
                                  setPassengerSeatPreferences={
                                    setPassengerSeatPreferences
                                  }
                                />
                              ))}
                            </div>
                            {/* )} */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <BDSend
                    walletData={walletDataa}
                    onUpdate={handleDataUpdate}
                  />
                </Col>
              </Row>
            </Col>
            {!flight2 && (
              <PriceDetail
                flight={flight}
                
                walletDatas={walletDatas}
                setShowdetail={setShowdetail}
                adminIm={adminIm}
                agentIm={agentIm}
                creditLimits={creditLimits}
                internationalMarkup={internationalMarkup}
                showdetail={showdetail}
                walletData={walletData}
                setOpenPayBtn={setOpenPayBtn}
                openPayBtn={openPayBtn}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
                validatePassengerData={validatePassengerData}
              />
            )}
          </Row>
        </Container>
        {openPayBtn ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "0px",
              height: "100%",
              zIndex: "10",
            }}
          >
            <FlightPayModal
              flight={flight}
              openPayBtn={openPayBtn}
              setOpenPayBtn={setOpenPayBtn}
              formData={formData}
              childData={childData}
              walletDatas={walletDatas}
              adminIm={adminIm}
              agentIm={agentIm}
              internationalMarkup={internationalMarkup}
              infant={infant}
              handleTicketBook={handleTicketBook}
              passengerSeatPreferences={passengerSeatPreferences}
              passengerMealPreferences={passengerMealPreferences}
              passengerBaggagePreferences={passengerBaggagePreferences}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        style={{
          textAlign: "center",
          position: "sticky",
          bottom: "0px",
          background: "linear-gradient(to right, #8b3eea, #4a125d)",
          color: "#fff",
          paddingBlock: "3px",
          fontSize: "larger",
          letterSpacing: "3px",
        }}
      >
        {timer.minutes !== null
          ? `${timer.minutes} minutes ${timer.seconds} seconds`
          : "loading..."}
      </div>

      <Modal show={showsessionModal} onHide={handleOk} centered>
        <Modal.Body className="text-center">
          <FaClock size={50} style={{ color: "#8b3eea" }} />
          <h4 className="mt-3">Your session is expired</h4>
          <p>Click OK to continue with a new search</p>
          <Button
            variant="primary"
            onClick={handleOk}
            style={{
              background: "#8b3eea",
              border: "none",
              padding: "10px 20px",
              fontSize: "16px",
            }}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FlightInternationalDetail;
