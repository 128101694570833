import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // signupData: null,
  traceId: sessionStorage.getItem("traceId")
    ? sessionStorage.getItem("traceId")
    : null,
  loading: false,
  search: [],
  errors: null,
  timer: {
    minutes: null,
    seconds: null,
  },
};

const flightSlice = createSlice({
  name: "flight",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    setTraceId(state, value) {
      state.token = value.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    startTimer(state, action) {
      state.timer.minutes = Math.floor(action.payload / 60);
      state.timer.seconds = action.payload % 60;
    },
    decrementTimer(state) {
      if (state.timer.seconds > 0) {
        state.timer.seconds -= 1;
      } else if (state.timer.minutes > 0) {
        state.timer.minutes -= 1;
        state.timer.seconds = 59;
      }
    },
    resetTimer(state) {
      state.timer.minutes = 0;
      state.timer.seconds = 0;
    },
    navigateToHomePage(state) {
      state.navigateToHomePage = true;
    },
    resetNavigation(state) {
      state.navigateToHomePage = false;
    },
  },
});

export const {
  setLoading,
  setSearch,
  setTraceId,
  setErrors,
  startTimer,
  decrementTimer,
  resetTimer,
  resetNavigation,
  navigateToHomePage,
} = flightSlice.actions;
export default flightSlice.reducer;
