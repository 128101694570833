import { apiConnector } from "../apiconnector";
import {
  setAdminIm,
  setAdminDm,
  setAgentDm,
  setAgentIm,
} from "../../slices/markUp";

const URL = "https://admin.yatriservice.com/api/markup";
const URLONE = "https://admin.yatriservice.com/api/agent-markup/all";

export function Markup(AgentId) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      // Fetching agent-specific markup data
      const agentResponse = await apiConnector("POST", URL, AgentId);

      console.log("AGENT MARKUP API RESPONSE............", agentResponse);

      if (!agentResponse.data.success) {
        throw new Error(agentResponse.data.message);
      }

      // Dispatching agent-specific markup data to Redux
      agentResponse.data.data.forEach((item) => {
        if (item.flight_type === "domestic") {
          dispatch(setAdminDm(JSON.parse(item.service_fee)));
        } else if (item.flight_type === "international") {
          dispatch(setAdminIm(JSON.parse(item.service_fee)));
        }
      });

      // Fetching admin-specific markup data
      const adminMarkupResponse = await apiConnector("GET", URLONE);

      console.log("ADMIN MARKUP API RESPONSE............", adminMarkupResponse);

      if (!adminMarkupResponse.data.success) {
        throw new Error(adminMarkupResponse.data.message);
      }

      // Dispatching admin-specific markup data to Redux
      adminMarkupResponse.data.data.forEach((item) => {
        if (item.flight_type === "domestic") {
          dispatch(setAgentDm(JSON.parse(item.service_fee)));
        } else if (item.flight_type === "international") {
          dispatch(setAgentIm(JSON.parse(item.service_fee)));
        }
      });
    } catch (error) {
      // Optionally handle errors here, like navigating to an error page
      console.log("Markup API ERROR............", error);
    }
    // dispatch(setLoading(false));
  };
}
