import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-copyright bg-black-footer z-index-step1" >
      <Container>
        <Row className="justify-content-between">
          <Col
            xl="auto"
            className="align-self-center text-center py-3 py-xl-0 text-xl-start"
          >
            <p className="copywrite-text">
              Copyright © 2024 <a href="index.html">YatriService</a> All Rights
              Reserved{" "}
              {/* <a href="https://themeforest.net/user/vecuro_themes">Vecuro</a> */}
            </p>
          </Col>
          <Col xl="auto" className="d-none d-xl-block">
          <div className="footer-menu">
              <ul>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Term & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>

                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
