import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./WhyChoose.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function WhyChoose() {
  var settings = {
    dots: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    infinite: true,
    // nextArrow: <NextBtn />,
    // prevArrow: <PreviousBtn />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="why-area">
      <Container>
        <Row>
          <Col lg={12}>
            <div
              className="border-section-title"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="why_choose_line"></div>
              <h4
                className="title"
                style={{ fontSize: "1.5rem", marginBottom: "5px" }}
              >
                Why Choose Yatri Service?
              </h4>
              <div className="why_choose_line"></div>
            </div>
            <p className="text-center">
              Take your travel agency to new heights with Yatri Service. Sign up
              now and experience the ease and efficiency of our B2B travel
              portal. Elevate your business, exceed your clients' expectations,
              and embrace a future of limitless possibilities in the world of
              travel.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Slider {...settings} className="mb-5 mt-4">
              <div className="why-points">
                <div className="why-point">
                  <div className="why-icon">
                    <span>$</span>
                  </div>
                  <div className="why-content">
                    <h4 className="title line-clamp-1">
                      Extensive Flight Search
                    </h4>
                    <p className="pera line-clamp-3">
                      Explore a comprehensive range of flight options from
                      leading airlines worldwide. Our user-friendly interface
                      allows you to effortlessly search and book flights
                      tailored to your clients' needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="why-points">
                <div className="why-point">
                  <div className="why-icon">
                    <span>$</span>
                  </div>
                  <div className="why-content">
                    <h4 className="title line-clamp-1">
                      Efficient Booking System
                    </h4>
                    <p className="pera line-clamp-3">
                      Our streamlined booking system makes the reservation
                      process quick and efficient. Spend less time navigating
                      complex interfaces and more time catering to your clients'
                      travel preferences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="why-points">
                <div className="why-point">
                  <div className="why-icon">
                    <span>$</span>
                  </div>
                  <div className="why-content">
                    <h4 className="title line-clamp-1">24/7 Support</h4>
                    <p className="pera line-clamp-3">
                      We prioritize your success. Our dedicated support team is
                      available around the clock to assist you with any queries
                      or concerns. Count on us for prompt and reliable
                      assistance.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhyChoose;
