import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Breadcrumb,
  Dropdown,
  Pagination,
  Form,
  ListGroup,
} from "react-bootstrap";
import {
  FaBuilding,
  FaEye,
  FaFileDownload,
  FaFilter,
  FaPlane,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
// import "./FlightBookingTable.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { cities12 } from "../../Cities";
import "./FlightBooking.css";
import { useDispatch, useSelector } from "react-redux";
import { Markup } from "../../redux/services/operations/markup";
// import { cities12 } from "../../../Cities";

// UserTags component
const UserTags = ({ data }) => {
  // Initialize counts for each status
  let successCount = 0;
  let failedCount = 0;
  let pendingCount = 0;

  // Iterate over data to count occurrences of each status
  data.forEach((booking) => {
    const status =
      booking.booking_response.ResponseStatus &&
      booking.booking_response.ResponseStatus; // Convert status to lowercase

    if (status === 1) {
      successCount++;
    } else if (status === 0) {
      failedCount++;
    } else if (status === "pending") {
      pendingCount++;
    }
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        padding: "0px 4px",
      }}
    >
      <h4 style={{ fontWeight: "600", fontSize: "25px" }}>Booking Report</h4>
      <ListGroup>
        <ListGroup.Item
          style={{ cursor: "pointer" }}
          variant="success"
          className="success_tag cus_tag"
        >
          <span className="span_tag approvetag">{successCount}</span>{" "}
          <span className="tag_label">Success</span>
        </ListGroup.Item>
        <ListGroup.Item
          style={{ cursor: "pointer" }}
          variant="danger"
          className="failed_tag cus_tag"
        >
          <span className="span_tag failedtag">{failedCount}</span>{" "}
          <span className="tag_label">Failed</span>
        </ListGroup.Item>
        {/* <ListGroup.Item
        style={{ cursor: "pointer" }}
        variant="warning"
        className="pending_tag cus_tag"
      >
        <span className="span_tag pendingtag">{pendingCount}</span>{" "}
        <span className="tag_label">Pending</span>
      </ListGroup.Item> */}
      </ListGroup>
    </div>
  );
};

// CardCustomMenu component
const CardCustomMenu = ({ toggleFilter }) => {
  return (
    <div className="full-container">
      <div>
        <ul className="custom-menu-container">
          {/* <li>
            <Link to="/agent/bookings/hotel">
              <FaBuilding style={{ marginRight: "10px" }} />
              Hotel
            </Link>
          </li>
          <li>
            <Link to="/agent/bookings/tour">
              <FaSuitcaseRolling style={{ marginRight: "10px" }} />
              Package
            </Link>
          </li> */}
          <li className="active">
            <Link to="/agent/bookings/flight">
              <FaPlane style={{ marginRight: "10px" }} />
              Flight
            </Link>
          </li>
          {/* <li>
            <Link to="/agent/bookings/transfer">Transfer</Link>
          </li> */}
        </ul>
      </div>
      <div>
        <Button
          variant="theme"
          size="sm"
          className="filter_btn"
          onClick={toggleFilter}
        >
          <FaFilter className="filter-icon" /> Filter
        </Button>
      </div>
    </div>
  );
};

// Filter component
const Filter = ({ onFilter }) => {
  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);

  // const handleFromDateChange = (date) => {
  //   if (!toDate || date <= toDate) {
  //     setFromDate(date);
  //   }
  // };

  // const handleToDateChange = (date) => {
  //   if (!fromDate || date >= fromDate) {
  //     setToDate(date);
  //   }
  // };

  const [filters, setFilters] = React.useState({
    booking_id: "",
    fromDate: null,
    toDate: null,
    status: "", // Keep status as string initially for easier handling of select options
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleFromDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      fromDate: date,
    }));
  };

  const handleToDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      toDate: date,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Map status to 1 or 0 before submitting
    const mappedFilters = {
      ...filters,
      status:
        filters.status === "Success" ? 1 : filters.status === "Failed" ? 0 : "",
    };

    onFilter(mappedFilters);
  };

  return (
    <div className="filter_panel">
      <h4>Search By Details</h4>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="type" value="b2c" />
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>ID</Form.Label>
              <Form.Control
                autoComplete="off"
                placeholder="Enter ID"
                name="booking_id"
                type="text"
                value={filters.booking_id}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>From Booking Date</Form.Label>
              <DatePicker
                selected={filters.fromDate}
                onChange={handleFromDateChange}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                placeholderText="From Booking Date"
                maxDate={filters.toDate || new Date()}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>To Booking Date</Form.Label>
              <DatePicker
                selected={filters.toDate}
                onChange={handleToDateChange}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                placeholderText="To Booking Date"
                minDate={filters.fromDate}
                maxDate={new Date()}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Booking Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={filters.status}
                onChange={handleInputChange}
              >
                <option value="">- Select Type -</option>
                <option value="Success">Success</option>
                <option value="Failed">Failed</option>
                {/* <option value="Pending">Pending</option> */}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <Button
              variant="theme"
              size="md"
              type="submit"
              className="search-filter-btn"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}, ${dateNum} ${month} ${year}`;
};

const FlightBookingTable = ({ heading, data }) => {
  const formatDateTimeee  = (dateString) => {
    // Replace space with 'T' to make it ISO 8601 compatible
    const isoDateString = dateString.replace(' ', 'T');
    const date = new Date(isoDateString);
  
    if (isNaN(date)) {
      return 'Invalid Date';
    }
  
    // Format the time
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
  
    // Format the date
    const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-GB', optionsDate).format(date);
  
    return `${formattedTime} ${formattedDate}`;
  };
  // State to manage the visibility of the filter panel
  const [showFilter, setShowFilter] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  // const [currentPage, setCurrentPage] = useState(1);
  // const entriesPerPage = 5;

  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 or 12 to 12
    return `${formattedHours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${period}`;
  };
  // Function to toggle the filter panel visibility
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    const requestData = {
      AgentId: walletData.Id,
    };
    dispatch(Markup(requestData));
    setFilteredData(data);
  }, [data]);

  const ticketStatusMapping = {
    1: "Ticket",
    2: "Refund",
    3: "Void",
    4: "Unknown",
  };
  const [flightClassifications, setFlightClassifications] = useState([]);

  const [isInternational, setIsInternational] = useState(false);

  useEffect(() => {
    const classifyFlightResponse = (data) => {
      console.log("Classify", data);

      if (Array.isArray(data)) {
        const contractsCount = data.reduce((acc, booking) => {
          if (
            booking.booking_response &&
            Array.isArray(booking.booking_response.Contracts)
          ) {
            return acc + booking.booking_response.Contracts.length;
          }
          return acc;
        }, 0);

        if (contractsCount === 2) {
          return false; // Roundtrip Domestic
        }

        if (contractsCount === 1) {
          const hasReturnSegment = data.some((booking) => {
            return (
              booking.booking_response &&
              Array.isArray(booking.booking_response.Contracts) &&
              booking.booking_response.Contracts[0].AirSegments.some(
                (segment) => segment.SegmentType === "Return"
              )
            );
          });

          return hasReturnSegment; // true for International Roundtrip, false for Oneway Flight
        }
      }

      return false; // Default case if no conditions are met
    };

    const international = classifyFlightResponse(data);
    setIsInternational(international);
    console.log("Is International:", international);
  }, [data]);

  const { adminDm, adminIm, agentDm, agentIm } = useSelector(
    (state) => state.markUp
  );
  console.log("MARKUOS", adminDm, adminIm, agentDm, agentIm);
  // console.log("flightClassifications",flightClassifications)
  const markup = isInternational ? adminIm : adminDm;

  console.log("MARKUSJAJF", flightClassifications ? agentIm : agentDm);

  // const handleFilter = (filters) => {
  //   const { booking_id, fromDate, toDate, status } = filters;
  //   const filteredData = data.filter((booking) => {
  //     let matchesFilter = true;

  //     if (booking_id && !booking.booking_id.toString().includes(booking_id)) {

  //       matchesFilter = false;
  //     }

  //     if (fromDate && new Date(booking.bookingDate) < new Date(fromDate)) {
  //       matchesFilter = false;
  //     }

  //     if (toDate && new Date(booking.bookingDate) > new Date(toDate)) {
  //       matchesFilter = false;
  //     }

  //     if (status && booking.status !== status) {
  //       matchesFilter = false;
  //     }

  //     return matchesFilter;
  //   });

  //   setFilteredData(filteredData);
  //   setCurrentPage(1);
  // };

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 50; // Number of entries per page

  // Pagination
  // const indexOfLastEntry = currentPage * entriesPerPage;
  // const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // console.log("filtered bookings", filteredData);

  const statusMapping = {
    1: "Success",
    // 11: "Pending",
    // 12: "WaitList",
    // 20: "Ticket In Process",
    // 2: "Ticketed",
    // 22: "Ticketed-Changed",
    // 23: "Ticketed-Schedule Changed",
    // 24: "Ticketed-Cancelled",
    // 25: "Ticketed-Void",
    0: "Failed",
    // 40: "Not Booked (Exception)",
    // 41: "Not Booked (Gateway)",
    // 42: "Duplicate",
  };

  const statusMappingTBO = {
    0: "Failed",
    1: "Successful",
    2: "Not Saved",
    3: "Not Created",
    4: "Not Allowed",
    5: "In Progress",
    6: "Ticket Already Created",
    8: "Price Changed",
    9: "Other Error",
  };

  const cabinMapping = {
    1: "Economy",
    2: "Premium Economy",
    3: "Business",
    4: "Premium Business",
    5: "First",
    6: "Premium First",
    100: "",
  };

  const [filters, setFilters] = useState({
    booking_id: "",
    fromDate: null,
    toDate: null,
    status: "",
  });
  const handleFilter = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );
  const [filteredEntries, setFilteredEntries] = useState(currentEntries);
  const currentEntriesToShow = filteredEntries.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredEntries.length / entriesPerPage);

  useEffect(() => {
    // Filter entries when filters change
    const applyFilters = () => {
      const { booking_id, fromDate, toDate, status } = filters;
      const filtered = currentEntries.filter((booking) => {
        const bookingDate = new Date(booking.bookingDate);
        const from = fromDate ? new Date(fromDate) : null;
        const to = toDate ? new Date(toDate) : null;

        return (
          (booking_id === "" || booking.Refid.includes(booking_id)) &&
          (status === "" ||
            booking.booking_response.ResponseStatus === status) &&
          (from === null || bookingDate >= from) &&
          (to === null || bookingDate <= to)
        );
      });

      setFilteredEntries(filtered);
    };

    applyFilters();
  }, [filteredData]);
  const sessionId = localStorage.getItem("sessionId");
  // const [bookingDetailsParto, setBookingDetailsParto] = useState(null);
  // const [flightBookingsData, setFlightBookingsData] = useState(null);
  // const [filteredBooking, setFilteredBooking] = useState("");
  // useEffect(() => {
  //   if (flightBookingsData.length > 0) {
  //     const booking = flightBookingsData.find(
  //       (booking) => booking.id === parseInt(id)
  //     );
  //     setFilteredBooking(booking);
  //   }
  // }, [flightBookingsData, id]);
  const [bookingDetailsParto, setBookingDetailsParto] = useState(null);

  const downloadTicketParto = (booking) => {
    // const selected_booking = filteredData.filter(
    //   (bookingg) => bookingg.id === booking
    // );
    console.log("bookingdvvdjgdfgyvbvdv", booking);
    generatePdf(booking);
  };

  const generatePdf = (filteredData) => {
    const element = <TicketTemplate bookingDetailss={filteredData} />;
    const container = document.createElement("div");
    document.body.appendChild(container);
    ReactDOM.render(element, container);

    html2canvas(container.querySelector(".ticketdata"), {
      scale: 2, // Increase the scale for better quality
      useCORS: true, // Use CORS if necessary
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the PDF page
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const aspectRatio = imgWidth / imgHeight;

      // Adjust the height to fit the content on a single page
      const scaledWidth = pdfWidth;
      const scaledHeight = pdfWidth / aspectRatio;

      // If the content is taller than the PDF page, scale it down
      let yOffset = 0;
      if (scaledHeight > pdfHeight) {
        const scale = pdfHeight / scaledHeight;
        yOffset = 0; // No offset needed since we're scaling to fit height
      } else {
        yOffset = (pdfHeight - scaledHeight) / 5; // Center vertically
      }

      // Center the content vertically and draw the image
      pdf.addImage(imgData, "PNG", 0, yOffset, scaledWidth, scaledHeight);

      // Save the PDF
      pdf.save("ticket.pdf");

      // Clean up
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    });
  };

  const TicketTemplate = ({ bookingDetailss, API }) => {
    console.log("booking detailsssss", bookingDetailss);

    return (
      <section className="container-fluid ticketdata">
        <div className="container">
          <div className="">
            <div className="top-part">
              <div className="logo">
                <img
                  src="/Images/yatri-service.png"
                  alt="Logo"
                  width={110}
                  height={45}
                />
              </div>
              <div className="ticket-details">
                <h4 style={{ fontSize: "16px", fontWeight: "700" }}>
                  Ticket-{" "}
                  {
                    statusMapping[
                      bookingDetailss.booking_response.ResponseStatus
                    ]
                  }
                </h4>
              </div>
            </div>
            <div className="top-parts">
              <div className="ticket-details">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="booking-ticket-upper-sections">
                    <b>{walletData && walletData.CompanyName}</b>
                  </div>
                </div>{" "}
                <div className="booking-ticket-upper-sections">
                  Address :<b>{walletData && walletData.Address}</b>
                </div>
                <div className="booking-ticket-upper-sections">
                  Phone No :<b> {walletData && walletData.Phone}</b>
                </div>
                <div className="booking-ticket-upper-sections">
                  <p> Email Id : </p> &nbsp;
                  <b>{walletData && walletData.Email}</b>
                </div>
              </div>
              <div className="ticket-details">
                <div className="">
                  <div className="booking-ticket-upper-sections">
                    <p>Refund : </p>
                    <b>
                      {bookingDetailss &&
                      bookingDetailss.booking_response.Contracts[0]
                        .Refundable &&
                      bookingDetailss.booking_response.Contracts[0]
                        .Refundable === true
                        ? "Refundable"
                        : "Non Refundable"}{" "}
                      <br />
                    </b>
                  </div>
                  {/* <div className="booking-ticket-upper-sections">
                  <p>Refrence Id :</p>
                  <b> {bookingDetailss.booking_response} </b>
                </div> */}
                  {bookingDetailss &&
                    bookingDetailss.booking_response.Contracts[0] && (
                      <div className="booking-ticket-upper-sections">
                        <span>
                          Inbound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <b>
                          {" "}
                          {
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlinePnr
                          }{" "}
                        </b>
                      </div>
                    )}
                  {bookingDetailss &&
                    bookingDetailss.booking_response.Contracts[1] && (
                      <p>
                        OutBound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          {
                            bookingDetailss.booking_response.Contracts[1]
                              .AirlinePnr
                          }{" "}
                        </span>
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className="passenger-details">
              <h4>
                {" "}
                Passenger
                {/* - {passengerDetails.adults} Adult {passengerDetails.children} Child  */}
              </h4>
              <table className="flight-ticket_table table table-bordered text-center">
                <thead>
                  <tr>
                    <th scope="col">Passenger Name</th>
                    {/* <th scope="col">Airline</th> */}
                    <th scope="col">Status</th>
                    {/* <th scope="col">Sector</th> */}
                    <th scope="col">Airline PNR</th>
                    <th scope="col">Ticket Number</th>
                    <th scope="col">Seat No.</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingDetailss &&
                    bookingDetailss.booking_response.Flightpassenger.map(
                      (item) => (
                        <tr>
                          <td>
                            <p>
                              {item.FirstName} {item.LastName}
                            </p>
                          </td>
                          {/* <td>
                     
                        {
                          bookingDetails.Contracts[0].AirSegments[0]
                            .AirlineCode
                        }
                       
                      </td> */}

                          <td>
                            Success
                            {/* {passenger.Ticket && passenger.Ticket.Status} */}
                          </td>
                          {/* <td>
                        {bookingDetails.Contracts.map((item) => (
                          <p>
                            {item.AirSegments.map((item) => (
                              <p>
                                {item.Origen} - {item.Destination},
                              </p>
                            ))}
                          </p>
                        ))}
                      </td> */}
                          <td>{bookingDetailss.booking_response.AirlinePnr}</td>
                          <td>{item.TicketNumber}</td>
                          <td>{item.SeatCode}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>

            <div className="flight-details">
              <h4> Flight Details </h4>
              <table className="flight-ticket_table table text-center table-bordered">
                <thead className="table-primary">
                  <tr>
                    <th scope="col" colSpan="2">
                      Airline Details
                    </th>
                    <th scope="col">Departure</th>
                    <th scope="col">Arrival</th>
                    <th scope="col">Duration</th>
                  </tr>
                </thead>

                {bookingDetailss &&
                  bookingDetailss.booking_response.Contracts.map((data) => (
                    <tbody>
                      {data.AirSegments.map((item) => (
                        <tr style={{ background: "#6e25c129" }}>
                          <td>
                            <img
                              src={`/Images/AirlineLogo/${item.AirlineCode}.gif`}
                              className="img-fluid"
                              alt=""
                              style={{ height: "42px", width: "40px" }}
                            />
                            {/* <i
                          className="fa-solid fa-plane-circle-check fa-fw"
                          style={{ color: "#8b3eea" }}
                        ></i> */}
                          </td>
                          <td>
                            <div>
                              <p>
                                <span style={{ fontWeight: 600 }}>
                                  {/* {detail.fD.aI.name} */}
                                </span>
                                <br />
                                {item.AirlineCode} {item.FlightNumber} <br />
                                Cabin Class: (
                                {item.TypeOfClass === 0 && "Economy"}
                                {item.TypeOfClass === 1 && "First"}
                                {item.TypeOfClass === 2 && "Business"}
                                {item.TypeOfClass === 3 && "Premium_Economy"}
                                ) <br />
                                <span
                                  style={{
                                    fontSize: "13px",
                                    color: "#8b3eea",
                                    fontWeight: "600",
                                  }}
                                >
                                  ({item.SegmentType})
                                </span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <span>
                              {/* {item.SourceAirport.city_name}  */}(
                              {item.Origen})
                            </span>
                            <br />
                            <p style={{ marginBottom: "0px" }}>
                              <span style={{ fontWeight: 600 }}>
                                {item.DepartureTime === null ||
                                item.DepartureTime === "" ? (
                                  <>
                                    {item.DepartureTime}
                                    {new Date(
                                      item.DepartureDateTime
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    })}{" "}
                                    <br />
                                    {formatTime(item.DepartureDateTime)}{" "}
                                  </>
                                ) : (
                                  <>
                                    {convertToAmPm(item.DepartureTime)}
                                    <br />
                                    {item.DepartureDateTime}
                                  </>
                                )}
                              </span>
                            </p>
                          </td>
                          <td>
                            <span>
                              {/* {item.DestinationAirport.city_name} ( */}
                              {item.Destination}{" "}
                            </span>
                            <br />
                            <p style={{ marginBottom: "0px" }}>
                              <span style={{ fontWeight: 600 }}>
                                {/* {item.ArrivalTime === null ||
                            item.ArrivalTime === ""
                              ? item.ArrivalTime
                              : convertToAmPm(item.ArrivalTime)}
                            <br />
                            {item.ArrivalDateTime} */}

                                {item.ArrivalTime === null ||
                                item.ArrivalTime === "" ? (
                                  <>
                                    {item.ArrivalTime}
                                    {new Date(
                                      item.ArrivalDateTime
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    })}{" "}
                                    <br />
                                    {formatTime(item.ArrivalDateTime)}{" "}
                                  </>
                                ) : (
                                  <>
                                    {convertToAmPm(item.ArrivalTime)}
                                    <br />
                                    {item.ArrivalDateTime}
                                  </>
                                )}
                              </span>
                              {/* {detail.aa.name} */}
                            </p>
                          </td>
                          <td>{item.Duration}</td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
              </table>
            </div>

            <div className="baggage-flight">
              <h4> Baggage Info </h4>
              <table className="flight-ticket_table table text-center table-bordered">
                <thead>
                  <tr>
                    <th scope="col" rowSpan="2">
                      Airline
                    </th>
                    <th scope="col" rowSpan="2">
                      Sector
                    </th>
                    <th scope="col" colSpan="2">
                      Baggage
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Check-In</th>
                    <th scope="col">Cabin</th>
                  </tr>
                </thead>

                {bookingDetailss &&
                  bookingDetailss.booking_response.Contracts.map((data) => (
                    <tbody>
                      {data.AirSegments.map((item) => (
                        <tr>
                          <td>
                            {item.AirlineCode}
                            <span
                              style={{
                                fontSize: "12px",
                                marginLeft: "4px",
                                color: "#8b3eea",
                                fontWeight: "600",
                              }}
                            >
                              ({item.SegmentType})
                            </span>
                          </td>
                          <td>
                            ( {item.Origen}-{item.Destination})
                          </td>
                          <td>{item.BaggageAllowed.CheckInBaggage}</td>
                          <td>{item.BaggageAllowed.HandBaggage}</td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
              </table>
            </div>

            <div className="flight-details">
              <h4> Fare Info </h4>
              <table className="flight-ticket_table table text-center table-bordered">
                <thead className=" table-primary">
                  <tr style={{ backgroundColor: "#6e25c129 !important" }}>
                    <th scope="col">Taxes & Surcharges/Other Charges</th>
                    <th scope="col"> Base Fare</th>

                    {/* <th scope="col">Discount</th>
                  <th scope="col">Service Fee</th>
                  <th scope="col">Excess Baggage</th>
                  <th scope="col">Meal</th>
                  <th scope="col">Seat Charges</th>
                 */}
                    <th scope="col">Total Fare</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingDetailss && (
                    <tr>
                      <td>
                        ₹{" "}
                        {!bookingDetailss.booking_response.Contracts[1] &&
                          bookingDetailss.booking_response.Contracts[0]
                            .AirlineFare.TaxFare + markup}
                        {bookingDetailss.booking_response.Contracts[1] &&
                          bookingDetailss.booking_response.Contracts[0]
                            .AirlineFare.TaxFare +
                            markup +
                            (markup +
                              bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.TaxFare)}
                        {/* {
                          bookingDetails.TravelItinerary
                            .ItineraryInfo.ItineraryPricing.TotalTax +
                            bookingDetails.TravelItinerary
                              .ItineraryInfo.ItineraryPricing.ServiceTax
                        } */}
                      </td>
                      <td>
                        {" "}
                        ₹
                        {!bookingDetailss.booking_response.Contracts[1] &&
                          bookingDetailss.booking_response.Contracts[0]
                            .AirlineFare.BaseFare}
                        {bookingDetailss.booking_response.Contracts[1] &&
                          bookingDetailss.booking_response.Contracts[0]
                            .AirlineFare.BaseFare +
                            bookingDetailss.booking_response.Contracts[1]
                              .AirlineFare.BaseFare}
                      </td>

                      {/* <td>
                      ₹{" "}
                      {!bookingDetails.Contracts[1] &&
                        bookingDetails.Contracts[0].AirlineFare
                          .Discount}
                      {bookingDetails.Contracts[1] &&
                        bookingDetails.Contracts[0].AirlineFare
                          .Discount +
                          bookingDetails.Contracts[1].AirlineFare
                            .Discount}
                    </td> */}
                      {/* <td>
                      ₹{" "}
                      {!bookingDetails.Contracts[1] &&
                        bookingDetails.Contracts[0].AirlineFare
                          .ServiceCharge}
                      {bookingDetails.Contracts[1] &&
                        bookingDetails.Contracts[0].AirlineFare
                          .ServiceCharge +
                          bookingDetails.Contracts[1].AirlineFare
                            .ServiceCharge}
                    </td> */}
                      {/* <td>₹ 0</td>
                    <td>₹ 0</td>
                    <td>₹ 0</td> */}

                      <td>
                        ₹{" "}
                        {!bookingDetailss.booking_response.Contracts[1] &&
                          bookingDetailss.booking_response.Contracts[0]
                            .AirlineFare.GrossFare + markup}
                        {bookingDetailss.booking_response.Contracts[1] &&
                          bookingDetailss.booking_response.Contracts[0]
                            .AirlineFare.GrossFare +
                            markup +
                            bookingDetailss.booking_response.Contracts[1]
                              .AirlineFare.GrossFare +
                            markup}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="personal-details">
              <h4> Company Contact Details </h4>
              <table className="flight-ticket_table table table-bordered">
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Name</td>
                    <td>Yatri Service </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Address</td>
                    <td>Delhi, India-41</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Contact</td>
                    <td>+91-9721647550 </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Email</td>
                    <td>Support@yatriservice.com</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="information-details">
              <h5>Passport/Visa/Health</h5>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingInlineStart: "20px",
                }}
              >
                <li>
                  {" "}
                  Please ensure that you have all the required travel documents
                  for your entire journey i.e., valid passport & necessary
                  visas, and that you have had the recommended inoculations for
                  your destination(s).
                </li>
                <li>
                  {" "}
                  All passengers, including children and infants, have to
                  present their valid ID proof at the time of check-in.
                </li>
                <li>
                  {" "}
                  We recommend you check-in at least 3 hours prior to departure
                  of your domestic flight and 4 hours prior to your
                  international flight
                </li>
                <li>
                  Carriage and other facilities provided by the carrier are
                  subject to their Terms and Condition. We are not liable for
                  missing any facility of the carrier.
                </li>
                <li>
                  Recheck your baggage with your respective airline before
                  traveling for a hassle-free travel experience.
                </li>
                <li>
                  Group Booking Rules will be applicable if passengers are 9 or
                  more in numbers.
                </li>
                <li>
                  Company is not responsible for any delay or cancellation of
                  flights from airline's end.
                </li>
                <li>
                  Unaccompanied Child: Children below the age of 12 will not be
                  accepted for carriage unless they are accompanied by a person
                  of at least 18 years of age. Such child/children must be
                  seated next to the accompanying adult. The accompanying adult
                  is solely responsible for the well-being of the child/children
                  traveling together with him/her. This also includes ensuring
                  that seats are booked to ensure child/children and an
                  accompanying adult are seated together.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      // <section className="container-fluid ticketdata">
      //   <div className="container" style={{ width: "100%" }}>
      //     <div className="maindiv">
      //       {/* <div className="top-part">
      //         <div className="logo">
      //           <img
      //             src="/Images/yatri-service.png"
      //             alt="Logo"
      //             width={250}
      //             height={120}
      //           />
      //         </div>
      //         <div className="ticket-details">
      //           <div className="details">
      //             <h4>
      //               Ticket-
      //               {
      //                 statusMapping[
      //                   bookingDetailss.booking_response.ResponseStatus
      //                 ]
      //               }
      //             </h4>
      //             <p>
      //               <span>Refrence ID:</span> {bookingDetailss.Refid}
      //             </p>

      //             <p>
      //               <span>Airline PNR:</span>
      //               {bookingDetailss.booking_response.AirlinePnr}
      //             </p>
      //           </div>
      //         </div>
      //       </div> */}
      //       <div className="top-part">
      //             <div className="logo">
      //               <img
      //                 src="/Images/yatri-service.png"
      //                 alt="Logo"
      //                 width={110}
      //                 height={45}
      //               />
      //             </div>
      //             <div className="ticket-details">
      //               <h4 style={{ fontSize: "16px", fontWeight: "700" }}>
      //                 Ticket-Confirmed
      //               </h4>
      //             </div>
      //           </div>
      //           <div className="top-parts">
      //             <div className="ticket-details">
      //               <div
      //                 style={{
      //                   display: "flex",
      //                   justifyContent: "space-between",
      //                 }}
      //               >
      //                 <div className="booking-ticket-upper-sections">
      //                   <b>{walletData && walletData.CompanyName}</b>
      //                 </div>
      //               </div>{" "}
      //               <div className="booking-ticket-upper-sections">
      //                 Address :<b>{walletData && walletData.Address}</b>
      //               </div>
      //               <div className="booking-ticket-upper-sections">
      //                 Phone No :<b> {walletData && walletData.Phone}</b>
      //               </div>
      //               <div className="booking-ticket-upper-sections">
      //               <p>  Email Id : </p> &nbsp;
      //               <b>{walletData && walletData.Email}</b>
      //               </div>
      //             </div>
      //             <div className="ticket-details">
      //               <div className="">
      //                 <div className="booking-ticket-upper-sections">
      //                   <p>Refund : </p>
      //                   <b>
      //                     {bookingDetailss.booking_response &&
      //                     bookingDetailss.booking_response.Contracts[0].Refundable &&
      //                     bookingDetailss.booking_response.Contracts[0].Refundable === true
      //                       ? "Refundable"
      //                       : "Non Refundable"}{" "}
      //                     <br />
      //                   </b>
      //                 </div>
      //                 <div className="booking-ticket-upper-sections">
      //                   <p>Refrence Id :</p>
      //                   <b>{bookingDetailss.Refid} </b>
      //                 </div>
      //                 {bookingDetailss.booking_response && bookingDetailss.booking_response.Contracts[0] && (
      //                   <div className="booking-ticket-upper-sections">
      //                     <span>
      //                       Inbound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
      //                     </span>
      //                     <b>
      //                       {" "}
      //                       {bookingDetailss.booking_response.Contracts[0].AirlinePnr}{" "}
      //                     </b>
      //                     </div>
      //                 )}
      //                 {bookingDetailss.booking_response && bookingDetailss.booking_response.Contracts[1] && (
      //                   <p>
      //                     OutBound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
      //                     <span>{bookingDetailss.booking_response.Contracts[1].AirlinePnr} </span>
      //                   </p>
      //                 )}
      //               </div>
      //             </div>
      //           </div>
      //       <div className="passenger-details">
      //         <h4>
      //           {" "}
      //           Passenger
      //           {/* - {passengerDetails.adults} Adult {passengerDetails.children} Child  */}
      //         </h4>
      //         <table className="table table-bordered text-center">
      //           <thead>
      //             <tr>
      //               <th scope="col">Passenger</th>
      //               <th scope="col">Airline</th>
      //               <th scope="col">Status</th>
      //               <th scope="col">Sector</th>
      //               <th scope="col">Airline PNR</th>
      //               <th scope="col">Ticket Number</th>
      //               <th scope="col">Seat No.</th>
      //             </tr>
      //           </thead>
      //           <tbody>
      //             {bookingDetailss &&
      //               bookingDetailss.booking_response.Flightpassenger.map(
      //                 (passenger, index) => (
      //                   <tr key={index}>
      //                     <td>
      //                       {passenger.FirstName} {passenger.LastName}
      //                     </td>
      //                     <td>
      //                       {
      //                         bookingDetailss.booking_response.Contracts[0]
      //                           .AirSegments[0].AirlineCode
      //                       }
      //                     </td>
      //                     <td>
      //                       {
      //                         statusMapping[
      //                           bookingDetailss.booking_response.ResponseStatus
      //                         ]
      //                       }
      //                     </td>

      //                     <td>
      //                       {bookingDetailss.booking_response.Contracts.map(
      //                         (items) => (
      //                           <p>
      //                             {items.AirSegments.map((data) => (
      //                               <p>
      //                                 {data.Origen} - {data.Destination},
      //                               </p>
      //                             ))}
      //                           </p>
      //                         )
      //                       )}
      //                     </td>
      //                     <td>{bookingDetailss.booking_response.AirlinePnr}</td>
      //                     <td>{passenger.TicketNumber}</td>
      //                     <td>{passenger.SeatCode}</td>
      //                   </tr>
      //                 )
      //               )}
      //           </tbody>
      //         </table>
      //       </div>
      //       <div className="flight-details">
      //         <h4> Flight Details </h4>
      //         <table className="table text-center table-bordered">
      //           <thead className="table-primary">
      //             <tr>
      //               <th scope="col" colSpan="2">
      //                 Airline Details
      //               </th>
      //               <th scope="col">Departure</th>
      //               <th scope="col">Arrival</th>
      //               <th scope="col">Duration</th>
      //             </tr>
      //           </thead>
      //           {bookingDetailss &&
      //             bookingDetailss.booking_response.Contracts.map((data) => (
      //               <tbody>
      //                 {data.AirSegments.map((item) => (
      //                   <tr style={{ background: "#6e25c129" }}>
      //                     <td>
      //                       <i
      //                         className="fa-solid fa-plane-circle-check fa-fw"
      //                         style={{ color: "#8b3eea" }}
      //                       ></i>
      //                     </td>
      //                     <td>
      //                       <p>
      //                         <span style={{ fontWeight: 600 }}>
      //                           {/* {detail.fD.aI.name} */}
      //                         </span>
      //                         <br />
      //                         {item.AirlineCode} {item.FlightNumber} <br />
      //                         Cabin Class: (
      //                         {item.TypeOfClass === 0 && "Economy"}
      //                         {item.TypeOfClass === 1 && "First"}
      //                         {item.TypeOfClass === 2 && "Business"}
      //                         {item.TypeOfClass === 3 &&
      //                           "Premium_Economy"}) <br />
      //                         <span
      //                           style={{
      //                             fontSize: "13px",
      //                             color: "#8b3eea",
      //                             fontWeight: "600",
      //                           }}
      //                         >
      //                           ({item.SegmentType})
      //                         </span>
      //                       </p>
      //                     </td>
      //                     <td>
      //                       <span>
      //                         {item.SourceAirport.city_name} ({item.Origen})
      //                       </span>
      //                       <br />
      //                       <p style={{ marginBottom: "0px" }}>
      //                         <span style={{ fontWeight: 600 }}>
      //                           {item.DepartureTime === null ||
      //                           item.DepartureTime === "" ? (
      //                             <>
      //                               {item.DepartureTime} <br />{" "}
      //                               {new Date(
      //                                 item.DepartureDateTime
      //                               ).toLocaleTimeString([], {
      //                                 hour: "2-digit",
      //                                 minute: "2-digit",
      //                                 hour12: false,
      //                               })}
      //                               ,{formatTime(item.DepartureDateTime)}{" "}
      //                             </>
      //                           ) : (
      //                             <>
      //                               {convertToAmPm(item.DepartureTime)}
      //                               <br />
      //                               {item.DepartureDateTime}
      //                             </>
      //                           )}
      //                           {/* {formatTime(item.DepartureDateTime)} &nbsp;
      //                           {new Date(
      //                             item.DepartureDateTime
      //                           ).toLocaleTimeString([], {
      //                             hour: "2-digit",
      //                             minute: "2-digit",
      //                             hour12: false,
      //                           })} */}
      //                         </span>
      //                       </p>
      //                     </td>
      //                     <td>
      //                       <span>
      //                         {item.DestinationAirport.city_name} (
      //                         {item.Destination}){" "}
      //                       </span>
      //                       <br />
      //                       <p style={{ marginBottom: "0px" }}>
      //                         <span style={{ fontWeight: 600 }}>
      //                           {item.ArrivalTime === null ||
      //                           item.ArrivalTime === "" ? (
      //                             <>
      //                               {item.ArrivalTime} <br />{" "}
      //                               {new Date(
      //                                 item.ArrivalDateTime
      //                               ).toLocaleTimeString([], {
      //                                 hour: "2-digit",
      //                                 minute: "2-digit",
      //                                 hour12: false,
      //                               })}
      //                               ,{formatTime(item.ArrivalDateTime)}{" "}
      //                             </>
      //                           ) : (
      //                             <>
      //                               {convertToAmPm(item.ArrivalTime)}
      //                               <br />
      //                               {item.ArrivalDateTime}
      //                             </>
      //                           )}
      //                         </span>
      //                         {/* {detail.aa.name} */}
      //                       </p>
      //                     </td>
      //                     <td>{item.Duration}</td>
      //                   </tr>
      //                 ))}
      //               </tbody>
      //             ))}
      //         </table>
      //       </div>

      //       <div className="baggage-flight">
      //         <h4> Baggage Info </h4>
      //         <table className="table text-center table-bordered">
      //           <thead>
      //             <tr>
      //               <th scope="col" rowSpan="2">
      //                 Airline
      //               </th>
      //               <th scope="col" rowSpan="2">
      //                 Sector
      //               </th>
      //               <th scope="col" colSpan="2">
      //                 Baggage
      //               </th>
      //             </tr>
      //             <tr>
      //               <th scope="col">Check-In</th>
      //               <th scope="col">Cabin</th>
      //             </tr>
      //           </thead>
      //           {bookingDetailss &&
      //             bookingDetailss.booking_response.Contracts.map((data) => (
      //               <tbody>
      //                 {data.AirSegments.map((item) => (
      //                   <tr>
      //                     <td>
      //                       {item.AirlineCode}
      //                       <span
      //                         style={{
      //                           fontSize: "12px",
      //                           marginLeft: "4px",
      //                           color: "#8b3eea",
      //                           fontWeight: "600",
      //                         }}
      //                       >
      //                         ({item.SegmentType})
      //                       </span>
      //                     </td>
      //                     <td>
      //                       ( {item.Origen}-{item.Destination})
      //                     </td>
      //                     <td>{item.BaggageAllowed.CheckInBaggage}</td>
      //                     <td>{item.BaggageAllowed.HandBaggage}</td>
      //                   </tr>
      //                 ))}
      //               </tbody>
      //             ))}
      //         </table>
      //       </div>

      //       {
      //         <div className="flight-details">
      //           <h4> Fare Info </h4>
      //           <table className="table text-center table-bordered">
      //             <thead className=" table-primary">
      //               <tr>
      //                 <th scope="col"> Base Fare</th>
      //                 <th scope="col">Taxes & Surcharges</th>
      //                 <th scope="col">Discount</th>
      //                 <th scope="col">Service Fee</th>
      //                 <th scope="col">Excess Baggage</th>
      //                 <th scope="col">Meal</th>
      //                 <th scope="col">Seat Charges</th>
      //                 {/* <th scope="col">Other Charges</th> */}
      //                 <th scope="col">Total Fare</th>
      //               </tr>
      //             </thead>
      //             {/* <tbody>
      //               {bookingDetailsParto && (
      //                 <tr>
      //                   <td>
      //                     {" "}
      //                     ${" "}
      //                     {handleChangeCurrency(
      //                       handleChangeCurrency2(
      //                         bookingDetailsParto.TravelItinerary.ItineraryInfo
      //                           .ItineraryPricing.BaseFare
      //                       )
      //                     )}
      //                   </td>
      //                   <td>
      //                     ${" "}
      //                     {handleChangeCurrency(
      //                       handleChangeCurrency2(
      //                         bookingDetailsParto.TravelItinerary.ItineraryInfo
      //                           .ItineraryPricing.TotalTax +
      //                           bookingDetailsParto.TravelItinerary
      //                             .ItineraryInfo.ItineraryPricing.ServiceTax
      //                       )
      //                     )}
      //                   </td>
      //                   <td>$ 0</td>
      //                   <td>$ 0</td>
      //                   <td>$ 0</td>
      //                   <td>$ 0</td>
      //                   <td>$ 0</td>
      //                   <td>$ 0</td>
      //                   <td>
      //                     ${" "}
      //                     {handleChangeCurrency(
      //                       handleChangeCurrency2(
      //                         bookingDetailsParto.TravelItinerary.ItineraryInfo
      //                           .ItineraryPricing.TotalFare
      //                       )
      //                     )}
      //                   </td>
      //                 </tr>
      //               )}
      //             </tbody> */}
      //             <tbody>
      //               {bookingDetailss && (
      //                 <tr>
      //                   <td>
      //                     {" "}
      //                     ₹{/* {overallBaseFare}  */}
      //                     {!bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.BaseFare}
      //                     {bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.BaseFare +
      //                         bookingDetailss.booking_response.Contracts[1]
      //                           .AirlineFare.BaseFare}
      //                     {/* {
      //                             bookingDetails.TravelItinerary
      //                               .ItineraryInfo.ItineraryPricing.BaseFare
      //                           } */}
      //                   </td>
      //                   <td>
      //                     ₹{" "}
      //                     {!bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.TaxFare}
      //                     {bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.TaxFare +
      //                         +(+bookingDetailss.booking_response.Contracts[1]
      //                           .AirlineFare.TaxFare)}
      //                     {/* {
      //                             bookingDetails.TravelItinerary
      //                               .ItineraryInfo.ItineraryPricing.TotalTax +
      //                               bookingDetails.TravelItinerary
      //                                 .ItineraryInfo.ItineraryPricing.ServiceTax
      //                           } */}
      //                   </td>
      //                   <td>
      //                     ₹{" "}
      //                     {!bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.Discount}
      //                     {bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.Discount +
      //                         bookingDetailss.booking_response.Contracts[1]
      //                           .AirlineFare.Discount}
      //                   </td>
      //                   <td>
      //                     ₹{" "}
      //                     {!bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.ServiceCharge}
      //                     {bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.ServiceCharge +
      //                         bookingDetailss.booking_response.Contracts[1]
      //                           .AirlineFare.ServiceCharge}
      //                   </td>
      //                   <td>₹ 0</td>
      //                   <td>₹ 0</td>
      //                   <td>₹ 0</td>
      //                   {/* <td>
      //                         ₹ {!bookingDetails.Contracts[1] &&  + (money && parseInt(money))}
      //                         {bookingDetails.Contracts[1] &&
      //                            +  + (money && parseInt(money))}{" "}
      //                       </td> */}

      //                   <td>
      //                     ₹{" "}
      //                     {!bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.GrossFare +
      //                         bookingDetailss.booking_response.Contracts[0]
      //                           .AirlineFare.ServiceCharge}
      //                     {bookingDetailss.booking_response.Contracts[1] &&
      //                       bookingDetailss.booking_response.Contracts[0]
      //                         .AirlineFare.GrossFare +
      //                         bookingDetailss.booking_response.Contracts[0]
      //                           .AirlineFare.ServiceCharge +
      //                         bookingDetailss +
      //                         bookingDetailss.booking_response.Contracts[1]
      //                           .AirlineFare.GrossFare +
      //                         bookingDetailss.booking_response.Contracts[1]
      //                           .AirlineFare.ServiceCharge}
      //                   </td>
      //                 </tr>
      //               )}
      //             </tbody>
      //           </table>
      //         </div>
      //       }

      //       <div className="personal-details">
      //         <h4> Company Contact Details </h4>
      //         <table className="flight-ticket_table table table-bordered">
      //           <tbody>
      //             <tr>
      //               <td style={{ fontWeight: 700 }}>Name</td>
      //               <td>Yatri Service </td>
      //             </tr>
      //             <tr>
      //               <td style={{ fontWeight: 700 }}>Address</td>
      //               <td>Delhi, India-41</td>
      //             </tr>
      //             <tr>
      //               <td style={{ fontWeight: 700 }}>Contact</td>
      //               <td>+91-9721647550 </td>
      //             </tr>
      //             <tr>
      //               <td style={{ fontWeight: 700 }}>Email</td>
      //               <td>Support@yatriservice.com</td>
      //             </tr>
      //           </tbody>
      //         </table>
      //       </div>

      //       <div className="information-details">
      //         <h5>Passport/Visa/Health</h5>
      //         <ul
      //           style={{
      //             listStyleType: "disc",
      //             paddingInlineStart: "20px",
      //           }}
      //         >
      //           <li>
      //             {" "}
      //             Please ensure that you have all the required travel documents
      //             for your entire journey i.e., valid passport & necessary
      //             visas, and that you have had the recommended inoculations for
      //             your destination(s).
      //           </li>
      //           <li>
      //             {" "}
      //             All passengers, including children and infants, have to
      //             present their valid ID proof at the time of check-in.
      //           </li>
      //           <li>
      //             {" "}
      //             We recommend you check-in at least 3 hours prior to departure
      //             of your domestic flight and 4 hours prior to your
      //             international flight
      //           </li>
      //           <li>
      //             Carriage and other facilities provided by the carrier are
      //             subject to their Terms and Condition. We are not liable for
      //             missing any facility of the carrier.
      //           </li>
      //           <li>
      //             Recheck your baggage with your respective airline before
      //             traveling for a hassle-free travel experience.
      //           </li>
      //           <li>
      //             Group Booking Rules will be applicable if passengers are 9 or
      //             more in numbers.
      //           </li>
      //           <li>
      //             Company is not responsible for any delay or cancellation of
      //             flights from airline's end.
      //           </li>
      //           <li>
      //             Unaccompanied Child: Children below the age of 12 will not be
      //             accepted for carriage unless they are accompanied by a person
      //             of at least 18 years of age. Such child/children must be
      //             seated next to the accompanying adult. The accompanying adult
      //             is solely responsible for the well-being of the child/children
      //             traveling together with him/her. This also includes ensuring
      //             that seats are booked to ensure child/children and an
      //             accompanying adult are seated together.
      //           </li>
      //         </ul>
      //       </div>
      //     </div>
      //   </div>
      // </section>
    );
  };

  const downloadTicketInvoice = (booking) => {
    // const selected_booking = filteredData.filter(
    //   (bookingg) => bookingg.id === booking
    // );
    console.log("bookingdvvdjgdfgyvbvdv", booking);
    generatePdfs(booking);
  };

  const generatePdfs = (filteredData) => {
    const element = <InvoiceTemplate item={filteredData} />;
    const container = document.createElement("div");
    document.body.appendChild(container);
    ReactDOM.render(element, container);

    html2canvas(container.querySelector(".invoice-ticket"), {
      scale: 2, // Increase the scale for better quality
      useCORS: true, // Use CORS if necessary
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Adjust PDF size to A5 (148 x 210 mm)
      const pdf = new jsPDF("p", "mm", [165, 250]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the PDF page
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const aspectRatio = imgWidth / imgHeight;

      // Adjust the height to fit the content on a single page
      const scaledWidth = pdfWidth;
      const scaledHeight = pdfWidth / aspectRatio;

      // Set yOffset to 0 to start content at the top
      let yOffset = 0;

      // Center the content vertically and draw the image
      pdf.addImage(imgData, "PNG", 0, yOffset, scaledWidth, scaledHeight);

      // Save the PDF
      pdf.save("invoice.pdf");

      // Clean up
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    });
  };

  const formatDateTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();
    const formattedTime = `${hours}:${String(minutes).padStart(
      2,
      "0"
    )} ${ampm}`;
    const formattedDate = `${day}-${month}-${year}`;
    return `${formattedDate}`;
  };
  const { walletData } = useSelector((state) => state.auth);
  const InvoiceTemplate = ({ item, API }) => {
    return (
      <>
        <div
          className="invoice-ticket"
          style={{
            maxWidth: 1000,
            margin: "15px auto",
            width: "100%",
            padding: 25,
            fontSize: 14,
            background: "#fff",
          }}
        >
          <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              width: "100%",
              borderSpacing: 0,
              borderCollapse: "colapse",
            }}
          >
            <tbody>
              <tr>
                <td style={{ border: "0px none", paddingBottom: 15 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>From</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 16 }}>
                          <b> YatriServices </b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Address: </strong>
                          Delhi, India
                          <br />
                          +91-9721647550
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email: </strong> Support@yatriservice.com
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>phone:</strong> +91-9721647550
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    border: "0px none",
                    paddingBottom: 15,
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>To</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 16 }}>
                          <b> {walletData && walletData.CompanyName}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>phone:</strong>{" "}
                          {walletData && walletData.Phone}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email: </strong>{" "}
                          {walletData && walletData.Email}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Airline PNR :</strong>{" "}
                          {item.booking_response.AirlinePnr}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {item.booking_response.Contracts[0] && (
                <>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: "7px 5px",
                        background: "#903ff3ee",
                        borderLeft: "3px solid #e3111a",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Out Bound Detail
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ paddingBottom: 15, paddingTop: 5 }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderSpacing: 0,
                          borderCollapse: "collapse",
                          border: "1px solid #000",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Passenger
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Sector
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Travel on
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Airline PNR
                            </th>
                          </tr>
                        </thead>
                        {item.booking_response.Contracts[0] && (
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {item.booking_response.Contracts[0] &&
                                  item.booking_response.Flightpassenger.map(
                                    (item) => (
                                      <p>
                                        {item.FirstName} {item.LastName}
                                      </p>
                                    )
                                  )}
                              </td>
                              {item.booking_response.Contracts[0] &&
                                item.booking_response.Contracts[0].AirSegments.map(
                                  (items) => (
                                    <td
                                      style={{
                                        borderLeft: "1px solid #000",
                                        padding: "7px 12px",
                                        borderRight: "1px solid #000",
                                      }}
                                    >
                                      {items.Origen} - {items.Destination}
                                    </td>
                                  )
                                )}
                              {item.booking_response.Contracts[0].AirSegments.map(
                                (items) => (
                                  <td
                                    style={{
                                      borderLeft: "1px solid #000",
                                      padding: "7px 12px",
                                      borderRight: "1px solid #000",
                                    }}
                                  >
                                    {" "}
                                    {items.ArrivalTime === null ||
                                    items.ArrivalTime === "" ? (
                                      <>
                                        {items.ArrivalTime}
                                        {new Date(
                                          items.ArrivalDateTime
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                        })}{" "}
                                        <br />
                                        {formatTime(items.ArrivalDateTime)}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {convertToAmPm(items.ArrivalTime)}
                                        <br />
                                        {items.ArrivalDateTime}
                                      </>
                                    )}
                                  </td>
                                )
                              )}
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {" "}
                                {item.booking_response.AirlinePnr}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </td>
                  </tr>
                </>
              )}
              {item.booking_response.Contracts[1] && (
                <>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: "7px 5px",
                        background: "#903ff3",
                        borderLeft: "3px solid #e3111a",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      In Bound Detail
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ paddingBottom: 15, paddingTop: 5 }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderSpacing: 0,
                          borderCollapse: "collapse",
                          border: "1px solid #000",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Passenger
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Sector
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Travel on
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Airline PNR
                            </th>
                          </tr>
                        </thead>
                        {item.booking_response.Contracts[1] && (
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {item.booking_response.Contracts[1] &&
                                  item.booking_response.Flightpassenger.map(
                                    (item) => (
                                      <p>
                                        {item.FirstName} {item.LastName}
                                      </p>
                                    )
                                  )}
                              </td>
                              {item.booking_response.Contracts[1] &&
                                item.booking_response.Contracts[1].AirSegments.map(
                                  (items) => (
                                    <td
                                      style={{
                                        borderLeft: "1px solid #000",
                                        padding: "7px 12px",
                                        borderRight: "1px solid #000",
                                      }}
                                    >
                                      {items.Origen} - {items.Destination}
                                    </td>
                                  )
                                )}
                              {item.booking_response.Contracts[1].AirSegments.map(
                                (items) => (
                                  <td
                                    style={{
                                      borderLeft: "1px solid #000",
                                      padding: "7px 12px",
                                      borderRight: "1px solid #000",
                                    }}
                                  >
                                    {items.DepartureTime === null ||
                                    items.DepartureTime === "" ? (
                                      <>
                                        {items.DepartureTime}
                                        {new Date(
                                          items.DepartureDateTime
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                        })}{" "}
                                        <br />
                                        {formatTime(
                                          items.DepartureDateTime
                                        )}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {convertToAmPm(items.DepartureTime)}
                                        <br />
                                        {items.DepartureDateTime}
                                      </>
                                    )}{" "}
                                    {/* {items.DepartureDateTime} */}
                                  </td>
                                )
                              )}
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {" "}
                                {item.booking_response.AirlinePnr}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td
                  colSpan={2}
                  style={{
                    padding: "7px 5px",
                    background: "#903ff3",
                    color: "#fff",
                    borderLeft: "3px solid #e3111a",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Fare Details
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 15, paddingTop: 5 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Basefare
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          YQ
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Taxes
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Discount
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Service Fee{" "}
                        </th>

                        {/* <th style="border-left:1px solid #000; padding: 7px 12px;background-color: #f3f3f3;border-bottom: 1px solid #000;border-right: 1px solid #000;">Netfare</th> */}
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          GrossFare
                        </th>
                        {/* <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Markup
                        </th> */}
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Total Fare
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item && (
                        <tr>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            {" "}
                            ₹
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .BaseFare}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .BaseFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .BaseFare}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            {" "}
                            ₹
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .YQTx}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .YQTx +
                                item.booking_response.Contracts[1].AirlineFare
                                  .YQTx}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .TaxFare}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .TaxFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .TaxFare}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .Discount}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .Discount +
                                item.booking_response.Contracts[1].AirlineFare
                                  .Discount}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                                item.booking_response.Contracts[1].AirlineFare
                                  .ServiceCharge}
                          </td>

                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge +
                                item.booking_response.Contracts[1].AirlineFare
                                  .GrossFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .ServiceCharge}
                          </td>
                          {/* <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹ 0
                          </td> */}

                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge +
                                item.booking_response.Contracts[1].AirlineFare
                                  .GrossFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .ServiceCharge}
                          </td>
                        </tr>
                      )}
                      {/* <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 0
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹ 651
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹ 0
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          10
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 2957
                        </td>
  
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 3608
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 100.00
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 3718
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 15 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#903ff3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          (Reimbursement of air ticket issued by airlines)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Tax And Additional Charge
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .ServiceCharge +
                              item.booking_response.Contracts[0].AirlineFare
                                .TaxFare}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .TaxFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .TaxFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Gross Total{" "}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .GrossFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 15 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            color: "#fff",
                            backgroundColor: "#903ff3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                            textAlign: "left",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Terms
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Without original invoice no refund is permissible.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Interest @ 24% will be charged on delayed payment.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Cheque to be drawn in favour of "company name".
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Kindly check all details carefully to avoid
                          un-necessary complications.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "3px 3px", fontSize: 13 }}>
                  Receiver's Signature
                </td>
                <td
                  style={{
                    padding: "3px 3px",
                    fontSize: 13,
                    textAlign: "right",
                  }}
                >
                  Authorized Signatory
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                    fontSize: 13,
                    borderTop: "1px solid #000",
                    paddingTop: 2,
                  }}
                >
                  This is a Computer generated document and does not require any
                  signature.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="" style={{ paddingTop: 10, paddingBottom: 15 }}>
            <div className="btn-group">
              <button onclick="window.close()" className="btn btn-danger">
                <span className="glyphicon glyphicon-remove" /> Close{" "}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="content-wrapper"
      // style={{ minHeight: "531px", margin: "15px" }}
    >
      <Container fluid>
        {/* <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">{heading}</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: "/agent/dashboard" }}
                    className="breadcrumb-home-colr"
                  >
                    <span style={{ color: "#fff" }}>Home</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{heading}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div> */}
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card className="ccard-booking-history">
                  <Card.Header>
                    <div className="cus_user_tags">
                      <UserTags data={filteredData} />
                    </div>
                  </Card.Header>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <Card.Header className="ccard-booking-history">
                    <div className="card_custom_menu">
                      <CardCustomMenu toggleFilter={toggleFilter} />
                    </div>
                  </Card.Header>
                  <Card.Body className="card-booking-history-body">
                    {showFilter && <Filter onFilter={handleFilter} />}
                    <Col md={12}>
                      <div className="table-responsive">
                        <Table bordered hover className="no-footer">
                          <thead>
                            <tr>
                              {/* <th className="no-sort sorting_disabled">
                                Basic
                              </th> */}
                              <th className="no-sort sorting_disabled">
                                Booking Date
                              </th>
                              <th className="no-sort sorting_disabled">
                                Journey Date
                              </th>
                              <th>Passengers Name</th>
                              <th>Segment</th>
                              <th>PNR</th>
                              <th>Booking</th>
                              <th>Fare</th>
                              <th>Invoice</th>
                              <th className="no-sort sorting_disabled">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tdata booking_data">
                            {data.map((booking, index) => (
                              <tr
                                key={index}
                                role="row"
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td>{formatDateTimeee(booking.created_at)}</td>
                                {/* <td>
                                  <b>Trace ID:</b> {booking.Refid}
                                  <br />
                                  {booking.id}
                                </td> */}
                                <td style={{ fontWeight: "500" }}>
                                  {booking.booking_response.Contracts[0]
                                    .AirSegments[0].DepartureTime === null ||
                                  booking.booking_response.Contracts[0]
                                    .AirSegments[0].DepartureTime === "" ? (
                                    <>
                                      {
                                        booking.booking_response.Contracts[0]
                                          .AirSegments[0].DepartureTime
                                      }{" "}
                                      <br />
                                      {formatTime(
                                        booking.booking_response.Contracts[0]
                                          .AirSegments[0].DepartureDateTime
                                      )}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {convertToAmPm(
                                        booking.booking_response.Contracts[0]
                                          .AirSegments[0].DepartureTime
                                      )}{" "}
                                      <br />
                                      {
                                        booking.booking_response.Contracts[0]
                                          .AirSegments[0].DepartureDateTime
                                      }
                                    </>
                                  )}
                                  <br />
                                  {/* {item.DepartureDateTime} */}

                                  {/* 
                                  {
                                    booking.booking_response.Contracts[0]
                                      .AirSegments[0].DepartureDateTime
                                  } */}
                                </td>
                                <td style={{ fontWeight: "400" }}>
                                  {
                                    booking.booking_response.Flightpassenger[0]
                                      .FirstName
                                  }{" "}
                                  {
                                    booking.booking_response.Flightpassenger[0]
                                      .LastName
                                  }
                                </td>
                                <td>
                                  {/* <b>Type:</b> &nbsp; */}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {" "}
                                    {booking.booking_type}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {booking.booking_response.Contracts[0].AirSegments.map(
                                      (segment) =>
                                        `${segment.Origen}-${segment.Destination}`
                                    ).join(", ")}
                                  </span>
                                </td>
                                <td>{booking.booking_response.AirlinePnr}</td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="check_status">
                                    <Button
                                      variant="link"
                                      className={`chk_stat_btn ${
                                        [1].includes(
                                          booking.booking_response
                                            .ResponseStatus
                                        )
                                          ? "yellow_clr"
                                          : [0, 2].includes(
                                              booking.booking_response
                                                .ResponseStatus
                                            )
                                          ? "green_clr"
                                          : "red_clr"
                                      }`}
                                    >
                                      {
                                        statusMapping[
                                          booking.booking_response
                                            .ResponseStatus
                                        ]
                                      }
                                    </Button>
                                  </div>
                                </td>
                                <td style={{ fontWeight: 500 }}>
                                  ₹
                                  {booking.booking_response.Contracts.length ===
                                  2 ? (
                                    <>
                                      {booking.booking_response.Contracts[0]
                                        .AirlineFare.GrossFare +
                                        booking.booking_response.Contracts[1]
                                          .AirlineFare.GrossFare +
                                        adminDm +
                                        adminDm}
                                    </>
                                  ) : (
                                    <>
                                      {booking.booking_response.Contracts[0]
                                        .AirlineFare.GrossFare + adminDm}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Link to={booking.invoiceLink}>
                                    {booking.pnr}
                                  </Link>
                                </td>
                                <td>
                                  <div className="nav-item dropdown action_dropdown cus_action_btn">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="primary"
                                        size="sm"
                                        className="action_btn btn btn-primary btn-rounded btn-xs"
                                        id="dropdown-basic"
                                      >
                                        Action <span className="caret"></span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to={`/agent/bookings/flight/details/${booking.id}`}
                                          >
                                            <FaEye className="eye-icon" /> View
                                            Detail
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            // onClick={() =>
                                            //   downloadTicketParto(booking)
                                            // }
                                            to={`/agent/bookings/flight/ticket-detail/${booking.id}`}
                                          >
                                            <FaFileDownload className="eye-icon" />{" "}
                                            Download Ticket
                                          </Link>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item>
                                          <Link
                                            onClick={() =>
                                              downloadTicketInvoice(booking)
                                            }
                                          >
                                            <FaFileDownload className="eye-icon" />{" "}
                                            Invoice
                                          </Link>
                                        </Dropdown.Item> */}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {currentEntriesToShow.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="9">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col sm={12} md={5}>
                          <div
                            className="dataTables_info"
                            id="invoicetable_info"
                            role="status"
                            aria-live="polite"
                          >
                            {filteredEntries.length > 0
                              ? `Showing ${indexOfFirstEntry + 1} to ${Math.min(
                                  indexOfLastEntry,
                                  filteredEntries.length
                                )} of ${filteredEntries.length} entries`
                              : "No bookings to show"}
                          </div>
                        </Col>
                        <Col sm={12} md={7}>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="invoicetable_paginate"
                          >
                            <Pagination>
                              <Pagination.Prev
                                onClick={() => paginate(currentPage - 1)}
                                disabled={
                                  currentPage === 1 ||
                                  filteredEntries.length === 0
                                }
                              >
                                Previous
                              </Pagination.Prev>
                              {Array.from({ length: totalPages }).map(
                                (_, index) => (
                                  <Pagination.Item
                                    key={index}
                                    active={currentPage === index + 1}
                                    onClick={() => paginate(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                )
                              )}
                              <Pagination.Next
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                  currentPage === totalPages ||
                                  filteredEntries.length === 0
                                }
                              >
                                Next
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default FlightBookingTable;
