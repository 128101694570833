import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import RoundTripSkeleton from "./RoundTripSkeleton";
import RoundTripDetail from "./RoundTripDetail";
import { RiRadioButtonLine } from "react-icons/ri";
import { IoRadioButtonOff } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFound from "../NotFound";

// className={`${type === "OneWay" ? "filterOneWayDiv" : "filterDiv"} flightListPage`}
// showFilter,
// minFare,
// maxFare,
// sliderValue,
// clearAllFilters,
// handleSliderChange,
// handledepTimeFilter,
// deptimeRange,
// arrtimeRange,
// handlearrTimeFilter,
// handleShowAllStops,
// checkedStops,
// handleCheckedstops,
// handleShowAllairlinenames,
// airlines,
// handleChecked,
// setShowFilter,
// applyFilters,
// type






export const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${dateNum}-${month}-${year}`;
};

const RoundList = ({
  search,
  isLoading,
  destinationCity,
  destinationCity2,
  startDate,
  filteredInboundData,
  selectedFlight,
  InboundResullt,
  handleClickDetail,
  activeId,
  fareRules,
  type,
  fares,
  netFare,
  calculateTotalFare,
  agentDm,
  adminDm
}) => {
  const { errors } = useSelector((state) => state.flight);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (errors) {
  //     navigate("/agent/404"); // Navigating to the specified route
  //   }
  // }, []);
console.log("Fare in admin dm",adminDm,agentDm)

const formatLayoverTime = (arrivalTime, nextDepartureTime) => {
  const arrival = new Date(arrivalTime);
  const nextDeparture = new Date(nextDepartureTime);
  const layoverMinutes = Math.floor(
    (nextDeparture.getTime() - arrival.getTime()) / (1000 * 60)
  );
  const layoverHours = Math.floor(layoverMinutes / 60);
  const layoverMinutesRemainder = layoverMinutes % 60;
  return `${layoverHours}h : ${layoverMinutesRemainder}m`;
};

  return (
    <Col
      lg={6}
      md={6}
      sm={6}
      xs={6}
      lgPull={6}
      className="cus_col_6"
      style={{ paddingRight: "0px" }}
    >
      {search && !isLoading ? (
        <div>
          {search.length !== 0 ? (
            <div className="content-main">
              <div className="block_content roundtrip_header">
                <ul>
                  <li className="flight_namess">
                    {destinationCity} <i className="fa fa-arrow-right" />{" "}
                    {destinationCity2}
                  </li>
                  <li className="date">
                    {startDate ? startDate.format("MM/DD/YYYY") : ""}
                  </li>
                  <li className="prev_next_btn">
                    <a id="lnkOutBoundPrevDay">Prev Day</a>
                    <span />
                    <a id="lnkOutBoundNextDay" href="javascript:;">
                      Next Day
                    </a>
                  </li>
                </ul>
                <div className="clearfix" />
              </div>
              <div className="block_content">
                <div className="flight_info">
                  <ul>
                    <li>
                      <a o="" href="javascript:;">
                        Airlines
                      </a>
                    </li>
                    <li>
                      <a onclick="DepartSortRoundOneWay()" href="javascript:;">
                        Depart <i className="depasorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="depasortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="depasorting"
                        defaultValue="descending"
                      />
                    </li>
                    <li>
                      <a
                        onclick="RoundDurationSortOneWay()"
                        href="javascript:;"
                      >
                        Duration <i className="durasorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="durasortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="durasorting"
                        defaultValue="descending"
                      />
                    </li>
                    <li>
                      <a onclick="ArriveSortRoundOneWay()" href="javascript:;">
                        Arrive <i className="arriveasorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="arrivesortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="arrivesorting"
                        defaultValue="descending"
                      />
                    </li>
                    <li className="price_control">
                      <a onclick="PriceSortDRTO()" href="javascript:;">
                        Price <i className="pricesorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="pricesortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="pricesorting"
                        defaultValue="descending"
                      />
                    </li>
                  </ul>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="round_trip_list">
                <div className="refendable11 refendable11onward">
                  {filteredInboundData &&
                    filteredInboundData.map((flight, index) => (
                      <div>
                        {flight.ContractType === type ? (
                          <div className="price1" key={index}>
                            <div className="price111 price111onword">
                              <div className="flight11">
                                <div className="stopscount">
                                  <div
                                    id="divOB2"
                                    className="Price8083 allshow block-content-2 custom_block_content flight-list-v2 DRTFilter 6E Indigo 0Stops bingo_button_4"
                                  >
                                    <div className="box-result custom_box_result active">
                                      <div className="flight_namess obflight_namess">
                                        {flight.AirSegments[0].AirlineName}
                                        <span className="flight_no">
                                          {flight.AirSegments[0].FlightNumber}
                                        </span>
                                      </div>
                                      <ul className="list-search-resultss result_list">
                                        <li>
                                          <img
                                            src={`/Images/${flight.AirlineCode}.png`}
                                            alt=""
                                          />
                                        </li>
                                        <li className="price priced">
                                          <span className="airlineprice">
                                            <span
                                              className="mainprice"
                                              style={{ marginTop: "-10px" }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "11px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {fares[flight.FareType]}
                                              </span>
                                              <br />₹
                                              {calculateTotalFare(flight.AirlineFare.GrossFare,
                                                agentDm, adminDm)}
                                            </span>

                                            <p
                                              className="mainprice"
                                              style={{
                                                fontSize: "13px",
                                                marginLeft: "0px",
                                                color: "#353535",
                                              }}
                                            >
                                              {" "}
                                              {netFare &&
                                                `₹${
                                                  flight.AirlineFare.NetFare
                                                   + adminDm
                                                }`}
                                            </p>
                                          </span>
                                        </li>
                                        <li className="round_check">
                                          <div className="checkbox-default">
                                            <span>
                                              {selectedFlight === flight ? (
                                                <RiRadioButtonLine
                                                  color="#8b3eea"
                                                  size={25}
                                                  className="cursor-pointer"
                                                  onClick={() =>
                                                    InboundResullt(flight)
                                                  }
                                                />
                                              ) : (
                                                <IoRadioButtonOff
                                                  color="#817b7b"
                                                  size={25}
                                                  className="cursor-pointer"
                                                  onClick={() =>
                                                    InboundResullt(flight)
                                                  }
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </li>
                                        <li className="pad_left10">
                                          <span className="date departdate">
                                            {new Date(
                                              flight.AirSegments[0].DepartureDateTime
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: false,
                                            })}
                                          </span>
                                          {flight.AirSegments[0].Origen}
                                          
                                         <span style={{fontSize:'11px',fontWeight:'600'}}>
                                         {formatTime(flight.AirSegments[0].DepartureDateTime)}
                                         </span>
                                        </li>
                                        <li>
                                          <span className="duration departdur">
                                            {/* {flight.AirSegments[0].Duration} */}
                                            {formatLayoverTime(
                                              flight.AirSegments[0].DepartureDateTime,
                                              flight.AirSegments[
                                                flight.AirSegments.length - 1
                                              ].ArrivalDateTime
                                            )}
                                            <span>
                                              <div className="cus_tooltip">
                                                {flight.AirSegments.length -
                                                  1 ==
                                                0
                                                  ? "Non-Stop"
                                                  : `${
                                                      flight.AirSegments
                                                        .length - 1
                                                    } Stops`}
                                              </div>
                                            </span>
                                          </span>
                                        </li>
                                        <li className="pad_left10">
                                          <span className="date arivedate">
                                            {new Date(
                                              flight.AirSegments[flight.AirSegments.length - 1].ArrivalDateTime
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: false,
                                            })}
                                          </span>
                                          {
                                            flight.AirSegments[
                                              flight.AirSegments.length - 1
                                            ].Destination
                                          }
                                            <span style={{fontSize:'11px',fontWeight:'600'}}>
                                         {formatTime(flight.AirSegments[0].ArrivalDateTime)}
                                         </span>
                                        </li>
                                      </ul>
                                      <div className="clearfix" />
                                      <div className="flight_details">
                                        <a
                                          className={`details_btn flightdetailajax cursor-pointer`}
                                          onClick={() => {
                                            handleClickDetail(
                                              flight.ContractId
                                            );
                                          }}
                                        >
                                          Fight Details
                                        </a>

                                        <div className="clearfix" />
                                      </div>
                                    </div>
                                    {activeId === flight.ContractId && (
                                      <RoundTripDetail
                                        idx={flight.ContractId}
                                        flight={flight}
                                        fareRules={fareRules}
                                        calculateTotalFare={calculateTotalFare}
                                        handleClickDetail={handleClickDetail}
                                        netFare={netFare}
                                        agentDm={agentDm}
                                        adminDm={adminDm}
                                      />
                                      // idx={flight.ResultIndex}
                                      // flight={flight}
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="round_trip_not_found">
              {/* <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.hostinger.in%2Ftutorials%2Fhow-to-fix-error-404&psig=AOvVaw2xOtPPomn-AoIQbyGgaiFN&ust=1715245883559000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCIj4l__a_YUDFQAAAAAdAAAAABAE" alt="" /> */}
              No Data Found
            </div>
          )}
        </div>
      ) : (
        // <RoundTripSkeleton />
        <div>{errors ? <NotFound /> : <RoundTripSkeleton />}</div>
      )}
    </Col>
  );
};

export default RoundList;
