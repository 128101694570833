import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { FaUsers, FaGlobe, FaHandshake } from 'react-icons/fa';
import "./Partner.css";
function Partner() {
  return (
    <section className="about-area">
      <Container>
        <Row className="g-4">
          <Col xl={5} lg={6}>
            <div className="section-title mx-430 mb-30 w-md-100">
              {/* <span className="highlights fancy-font font-400">About Us</span> */}
              <h4 className="title">
                Yatri Service: Your Trusted B2B Travel Partner
              </h4>
              <p className="pera">
                In the realm of B2B travel agencies, YatriService stands as your
                unwavering ally. Our commitment to reliability and excellence
                makes us a name you can confidently rely on for all your travel
                needs. Trust in Yatri Service to be your steadfast partner,
                providing a seamless and trustworthy experience in the world of
                B2B travel.
              </p>
              <p className="pera">
                Travel is a transformative and enriching experience that allows
                individuals to explore new destinations, cultures.
              </p>
            </div>
          </Col>
          <Col xl={7} lg={6}>
            <div className="about-count-section about-count-before-bg">
              <div className="banner">
                <img
                  src="https://travelloo.vercel.app/template/assets/images/gallery/about-banner-three.png"
                  alt="travello"
                />
              </div>
              <div className="all-count-list">
                <div className="details">
                  <h4 className="count">5000+</h4>
                  <p className="pera">Customers</p>
                </div>
                <div className="divider" />
                <div className="details">
                  <h4 className="count">70,000+</h4>
                  <p className="pera">Bookings</p>
                </div>
                <div className="divider" />
                <div className="details">
                  <h4 className="count">200+</h4>
                  <p className="pera">Travel Agents</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Partner;
