import React from "react";
// import DepatureDetail from "./DepatureDetail";

// import FlightDetailSkeleton from "./FlightDetailSkeleton";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import DepatureDetail from "./DepartureDetail";

import { Modal } from "react-bootstrap";
import FlightDetailSkeleton from "../../../FlightDetails/FlightDetailSkeleton";
const FlightPayModal = ({
  flight,
  flight2,
  setOpenPayBtn,
  walletDatas,
  openPayBtn,
  internationalMarkup,
  formData,
  agentIm, adminIm,
  infant,
  childData,
  handleTicketBook,
  handleTicketBookRound,
  passengerSeatPreferences,
  passengerMealPreferences,
  passengerBaggagePreferences,
}) => {
  const { walletData } = useSelector((state) => state.auth);

  //   let totalMealCharges = passengerMealPreferences.reduce((total, meal) => {
  //     if (meal && meal.Price) {
  //       total += meal.Price;
  //     }
  //     return total;
  //   }, 0);
  //   let totalMealWithCharges =
  //     flight && flight.Fare.TotalMealCharges + totalMealCharges;

  //   let totalSeatCharges = passengerSeatPreferences.reduce((total, meal) => {
  //     if (meal && meal.Price) {
  //       total += meal.Price;
  //     }
  //     return total;
  //   }, 0);
  //   let totalSeatWithCharges =
  //     flight && flight.Fare.TotalSeatCharges + totalSeatCharges;

  //   let totalBaggageCharges = passengerBaggagePreferences.reduce(
  //     (total, meal) => {
  //       if (meal && meal.Price) {
  //         total += meal.Price;
  //       }
  //       return total;
  //     },
  //     0
  //   );
  //   let totalBaggageWithCharges =
  //     flight && flight.Fare.TotalBaggageCharges + totalBaggageCharges;

  return (
    <Modal
      show={openPayBtn}
      onHide={() => {
        setOpenPayBtn(false);
      }}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton style={{ margin: "0px" }}>
        <Modal.Title>Flight Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {flight ? (
          <DepatureDetail flight={flight} type="Departure" />
        ) : (
          <FlightDetailSkeleton />
        )}

        <div className="bor po-re m-bt">
          <div className="trheadbg">
            {/* <div className="edt1 po-ab">Edit</div> */}
            Traveller Details
          </div>
          <div className="tr-cn">
            <div className="ps-d mar20">
              <div className="ps1n">
                <span className="fnt">E-mail </span>

                <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty">
                  {/* {walletData.Email} */}
                  {walletData.Email}
                </span>
              </div>
              <div className="ps2n">
                <span className="fnt">Contact No. </span>
                <span className="fnt-g ng-binding">
                  {/* {walletData.Phone} */}
                  {walletData.Phone}
                </span>
              </div>
              <div className="ps3n">
                {formData && (
                  <span>
                    <span className="fnt">
                      Adult (
                      <span className="ng-binding">{formData.length}</span>){" "}
                    </span>

                    {formData.map((item, index) => (
                      <span className="fnt-g ng-binding ng-scope">
                        {item.title} {item.firstName} {item.lastName}
                      </span>
                    ))}
                  </span>
                )}
              </div>
              <div className="ps4n">
                {childData && (
                  <span>
                    {" "}
                    <span className="fnt">
                      Child (
                      <span className="ng-binding">{childData.length}</span>){" "}
                    </span>
                    {childData.map((item, index) => (
                      <span className="fnt-g ng-binding ng-scope">
                        {item.title} {item.firstName} {item.lastName}
                      </span>
                    ))}
                  </span>
                )}
              </div>
              <div className="ps5n">
                {infant && (
                  <span>
                    {" "}
                    <span className="fnt">
                      Infant (
                      <span className="ng-binding">{childData.length}</span>){" "}
                    </span>
                    {infant.map((item, index) => (
                      <span className="fnt-g ng-binding ng-scope">
                        {item.title} {item.firstName} {item.lastName}
                      </span>
                    ))}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bor po-re m-bt">
          <div className="trheadbg">
            <div className="edt1 po-ab">Pay using eWallet</div>
          </div>
          <div className="tr-cn">
            {flight && (
              <div>
                <div className="ps-d mar20">
                  <div className="ps1n1">
                    <span className="fnt">Wallet Balance </span>

                    <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty walletBalanceBold">
                      ₹{walletDatas && walletDatas}
                    </span>
                  </div>
                  <div className="ps1n1">
                    <span className="fnt">Flight Price </span>
                    <span className="fnt-g ng-binding walletBalanceBold">
                      ₹{" "}
                      {flight[0].AirlineFare.GrossFare &&
                        flight[0].AirlineFare.GrossFare +
                        agentIm + adminIm +
                        agentIm + adminIm}
                    </span>
                  </div>
                  {/* <div className="ps1n1">
                    <span className="fnt">Amount to deduct </span>

                    <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty ">
                      ₹{" "}
                      {flight[0].AirlineFare.NetFare +
                        agentIm + adminIm +
                        agentIm + adminIm}
                    </span>
                  </div> */}
                  {/* <div className="ps1n1">
                    <span className="fnt">Remaining Balance </span>

                    <span className="fnt-g ng-pristine ng-untouched ng-valid ng-binding ng-not-empty">
                      ₹
                      {walletDatas &&
                        walletDatas -
                          (flight[0].AirlineFare.NetFare +
                            internationalMarkup +
                            internationalMarkup)}
                    </span>
                  </div> */}
                </div>
              </div>
            )}
          </div>
          <div
            className="flightPay"
            style={{ width: "100%", textAlign: "end" }}
          >
            <button
              className=" flightPaybtn"
              onClick={() => {
                // setOpenPayBtn(!openPayBtn);
                handleTicketBook();
              }}
            >
              {" "}
              Confirm & Pay
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FlightPayModal;
