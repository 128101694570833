import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  walletData: localStorage.getItem("walletData")
    ? JSON.parse(localStorage.getItem("walletData"))
    : null,
  loading: false,
  token: sessionStorage.getItem("token")
    ? (sessionStorage.getItem("token"))
    : null,
    errors:'',
    city:[]
}; 
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setToken(state, value) {
      state.token = value.payload;
    },
    setWalletData(state, value) {
      state.walletData = value.payload;
    },
    setErrors(state,value){
      state.errors=value.payload
    },
    setCity(state, action) {
      state.city = action.payload;
    },
  },
});
export const { setLoading, setToken, setWalletData,setErrors,setCity } = authSlice.actions;
export default authSlice.reducer;