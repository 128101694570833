import { apiConnector } from "../apiconnector";
import {
  setSearch,
  setLoading,
  setTraceId,
  setErrors,
  startTimer,
  decrementTimer,
  navigateToHomePage,
  resetTimer,
} from "../../slices/flightSlice";
// import { setErrors } from "../../slices/auth";

const URL = "https://admin.yatriservice.com/api/flightsearch";
export function flightSearch(searchData, navigate) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await apiConnector("POST", URL, searchData);

      console.log("FLIGHTSEARCH API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      // console.log(response.data);
      dispatch(startTimer(900));
      const timerInterval = setInterval(() => {
        dispatch(decrementTimer());
      }, 1000);

      setTimeout(() => {
        clearInterval(timerInterval);
        dispatch(resetTimer());
      }, 900000);

      dispatch(setSearch(response.data.data.Contracts));
      localStorage.setItem("token", response.data.data.apiAuthToken);
    
      sessionStorage.setItem("bookingId", response.data.data.BookingKey);
    } catch (error) {
      console.log("FLIGHTSEARCH API ERROR............", error);
      dispatch(setErrors("Axios Error"));
    }
    dispatch(setLoading(false));
  };
}
