import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <section className="bg-inner small-section success-section">
      <div className="container">
        <div className="row success-detail mt-0">
          <div className="col">
            <img
              src="https://rica-next-pixelstrapthemes.vercel.app/assets/images/flights/plane.png"
              className="img-fluid"
              alt=""
            />
            <h2>Something went wrong</h2>
            <p>
              Their is an technical issue. Please contact to the customer care.
            </p>
            <Link to="/agent/dashboard">
              <button type="submit" className="btn  btn-solid color1">
                search again
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
