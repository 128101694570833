import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Markup } from "../../redux/services/operations/markup";
import { FaPerson, FaRegFilePdf, FaWhatsapp } from "react-icons/fa6";
import { IoPrintSharp } from "react-icons/io5";
import { MdMarkEmailUnread, MdOutlineHideImage } from "react-icons/md";
import { LiaSmsSolid } from "react-icons/lia";
import { BsCurrencyDollar } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";

export const statusMapping = {
  1: "Success",
  // 11: "Pending",
  // 12: "WaitList",
  // 20: "Ticket In Process",
  // 2: "Ticketed",
  // 22: "Ticketed-Changed",
  // 23: "Ticketed-Schedule Changed",
  // 24: "Ticketed-Cancelled",
  // 25: "Ticketed-Void",
  0: "Failed",
  // 40: "Not Booked (Exception)",
  // 41: "Not Booked (Gateway)",
  // 42: "Duplicate",
};
const TicketBooking = () => {
  const componentRef = React.useRef(null);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
    return Promise.resolve();
  }, []);

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "AwesomeFileName",
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });

  // const handlePrint = () => {
  //   const printContent = printRef.current.innerHTML;
  //   const originalContent = document.body.innerHTML;

  //   // Replace the body content with the content to print
  //   document.body.innerHTML = printContent;

  //   // Trigger print
  //   window.print();

  //   // Restore the original page content after printing
  //   document.body.innerHTML = originalContent;

  //   // Ensure React re-binds the event listeners
  //   window.location.reload();
  // };

  const fares = {
    0: "Normal",
    1: "Special",
    3: "UserFare",
    4: "CorporateFare",
    5: "CouponFare",
    6: "SMEFare",
    7: "GDSFare",
    8: "Default",
    9: "GoSmart",
    10: "GoSpecial",
    11: "GoSmartCorporate",
    12: "GoMarine",
    13: "GoBusiness",
    14: "GoBusinessCorporat",
    15: "GoRound",
    16: "SpiceSaver",
    17: "SpiceCorp",
    18: "SpiceSME",
    19: "SpiceCoupon",
    20: "GoFlexi",
    21: "SpiceCorporate",
    22: "UKSpecial",
    23: "GoSmartCorporateSp",
    24: "CrpcFare",
    25: "GoSpecialV2",
    26: "AkasaNormal",
    27: "AkasaAV",
    28: "AkasaSpecial",
    29: "AkasaCorporate",
    30: "AkasaCorpSelect",
    31: "EchoPECO",
    32: "EchoPCLS",
    33: "IndgoFlexi",
    34: "IndigoTactial",
    35: "IndigoCoupon",
    36: "VistaFlex",
    37: "AISME",
    38: "STU",
    39: "IXSme",
    40: "VVIP",
    41: "SUP6E",
    42: "AIBUS",
  };

  const [active, setActive] = useState(false);
  const [hidePerson, setHidePerson] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);
  const [markupActive, setMarkupActive] = useState(false);
  const [money, setMoney] = useState("");
  const [price, setPrice] = useState(1000);
  // const totalBaseFare = bookingDetails.Contracts.reduce((acc, current) => acc + current.AirlineFare.BaseFare, 0);

  const sendWhatsAppMessage = async () => {};

  const formatTime = (arrTime) => {
    const date = new Date(arrTime);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = days[date.getDay()];
    const dateNum = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${dateNum} ${month} ${year}`;
  };

  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 or 12 to 12
    return `${formattedHours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${period}`;
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const [flightBookingsData, setFlightBookingsData] = useState("");
  const [hideLogo, setHideLogo] = useState(false);
  const [filteredBooking, setFilteredBooking] = useState("");
  const { walletData } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchFlightBookingData = async () => {
      try {
        const requestData = {
          AgentId: walletData.Id,
        };
        const response = await axios.post(
          "https://admin.yatriservice.com/api/details",
          requestData
        );
        console.log("dghfytdftyfv", response);
        if (response.data.success) {
          console.log("bookings from db", response.data.data);
          setFlightBookingsData(response.data.data);
        } else {
          console.error(
            "Failed to fetch flight booking data:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching flight booking data:", error);
      }
    };
    const dataaaa = {
      AgentId: walletData.Id,
    };
    fetchFlightBookingData();
    dispatch(Markup(dataaaa));
  }, []);

  useEffect(() => {
    if (flightBookingsData.length > 0) {
      const booking = flightBookingsData.find(
        (booking) => booking.id === parseInt(id)
      );
      setFilteredBooking(booking);
    }
  }, [flightBookingsData, id]);

  const [isInternational, setIsInternational] = useState(false);
  useEffect(() => {
    const classifyFlightResponse = (filteredBooking) => {
      console.log("Classify", filteredBooking);
      if (
        filteredBooking &&
        filteredBooking.booking_response.Contracts.length === 2
      ) {
        return false; // Roundtrip Domestic
        // setIsInternational(true)
      }

      if (
        filteredBooking &&
        filteredBooking.booking_response.Contracts.length === 1
      ) {
        const hasReturnSegment =
          filteredBooking.booking_response.Contracts[0].AirSegments.some(
            (segment) => segment.SegmentType === "Return"
          );

        if (hasReturnSegment) {
          // setIsInternational(true)
          return true; // International Roundtrip
        } else {
          // setIsInternational(false)
          return false; // Oneway Flight
        }
      }

      return false; // Default case if no conditions are met
    };

    const isRoundtripOrInternational = classifyFlightResponse(filteredBooking);
    // console.log("Is Roundtrip or International:", isRoundtripOrInternational);
    setIsInternational(isRoundtripOrInternational);
  }, [filteredBooking]);
  const { adminDm, adminIm } = useSelector((state) => state.markUp);
  console.log("ADMIN PANEL MARKUP", adminIm, adminDm);
  const markup = isInternational ? adminIm : adminDm;

  const handleSubmit = (e) => {
    e.preventDefault();
    parseInt(setPrice(price + parseInt(money)));
    setMarkupActive(false);
  };

  // const downloadPdf = () => {
  //   const capture = document.querySelector(".ticketdata");

  //   // Use html2canvas with reduced scale and better settings
  //   html2canvas(capture, {
  //     scale: 0.9, // Adjust scale; higher for quality, lower for smaller size
  //     useCORS: true, // Handles CORS images correctly
  //   }).then((canvas) => {
  //     // Convert canvas to compressed image data (JPEG with reduced quality)
  //     const imgData = canvas.toDataURL("image/jpeg", 0.7); // JPEG format with 70% quality
  //     const doc = new jsPDF("p", "mm", "a4", true); // Enable compression

  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();

  //     const imageWidth = canvas.width;
  //     const imageHeight = canvas.height;

  //     // Maintain aspect ratio while scaling image to fit PDF
  //     const ratio = Math.min(
  //       componentWidth / imageWidth,
  //       componentHeight / imageHeight
  //     );

  //     const imageX = (componentWidth - imageWidth * ratio) / 2; // Center horizontally
  //     const imageY = 10; // Set top margin
  //     const imageWidthScaled = imageWidth * ratio;
  //     const imageHeightScaled = imageHeight * ratio;

  //     // Add the image with reduced quality to the PDF
  //     doc.addImage(
  //       imgData,
  //       "JPEG", // Use JPEG for smaller file size
  //       imageX,
  //       imageY,
  //       imageWidthScaled,
  //       imageHeightScaled
  //     );

  //     // Save the PDF with optimized size
  //     doc.save("bookingDetail.pdf");
  //   });
  // };

  const doc = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
    compress: true, // Enable internal compression
  });

  const downloadPdf = () => {
    const capture = document.querySelector(".ticketdata");

    html2canvas(capture, {
      scale: 1.4, // Reduce scale for smaller image
      useCORS: true,
    }).then((canvas) => {
      // Convert canvas to compressed JPEG data
      const imgData = canvas.toDataURL("image/jpeg", 0.5); // Reduce JPEG quality to 50%
      const doc = new jsPDF("p", "mm", "a4");

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Scale image to fit within the PDF dimensions
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2; // Center image horizontally
      const imgY = 10; // Set a margin
      const scaledWidth = imgWidth * ratio;
      const scaledHeight = imgHeight * ratio;

      // Add the compressed image to the PDF
      doc.addImage(imgData, "JPEG", imgX, imgY, scaledWidth, scaledHeight);

      // Save the PDF with maximum compression
      doc.save("bookingDetail.pdf");
    });
  };

  return (
    <div>
      {filteredBooking && (
        <section className="container-fluid " style={{ minWidth: "1280px" }}>
          <div className="flightBookingHeaderDiv">
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHidePerson(!hidePerson)}
            >
              <FaPerson />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                Hide Agent Info
              </span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHideLogo(!hideLogo)}
            >
              <MdOutlineHideImage />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                Hide Logo
              </span>
            </div>
            <div className="flightBookingUpperIcon" onClick={handlePrint}>
              <IoPrintSharp />
              <span className="hotelBookingHideDetails"> Print</span>
            </div>
            <div className="flightBookingUpperIcon" onClick={downloadPdf}>
              <FaRegFilePdf />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                {" "}
                Save Pdf
              </span>
            </div>
            <div className="flightBookingUpperIcon">
              <MdMarkEmailUnread />
              <span className="hotelBookingHideDetails"> Email </span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={sendWhatsAppMessage}
            >
              <FaWhatsapp />
              <span className="hotelBookingHideDetails"> Whatsapp</span>
            </div>
            <div className="flightBookingUpperIcon">
              <LiaSmsSolid />
              <span className="hotelBookingHideDetails"> Sms</span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHidePrice(!hidePrice)}
            >
              <BsCurrencyDollar />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                {" "}
                Hide Price
              </span>
            </div>
            <div className="flightBookingUpperIcon">
              <BsCurrencyDollar />
              <span
                className="hotelBookingHideDetails"
                onClick={() => setMarkupActive(!markupActive)}
              >
                {" "}
                Markup
              </span>
            </div>
            <div
              style={{
                padding: "3px 6px",
                backgroundColor: "#db0e0edb",
                fontSize: "20px",
                color: "white",
              }}
            >
              <RxCross2 />
            </div>
          </div>
          {markupActive ? (
            <div className="hotelBookingMarkupMain">
              <div className="hotelBookingMarkup">
                <p className="hotelBookingMarkuppara markup-margins">
                  {" "}
                  Additional Transaction fees / discount
                </p>
                <p
                  className="markup-margins"
                  style={{
                    color: "#2d3290",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  Existing Amount: Rs.
                </p>
                <p
                  className="markup-margins"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Reset Amount
                </p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    value={money}
                    onChange={(e) => setMoney(e.target.value)}
                    style={{ width: "100%", padding: "5px 10px" }}
                  />
                  <div className="hotelBookingCloseSub">
                    <button
                      className="hotelBookingMarkupClose"
                      onClick={() => setMarkupActive(false)}
                    >
                      close
                    </button>
                    <button className="hotelBookingMarkupSub" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="container ticketdata" ref={componentRef} id="ticketdata">
            <div className="">
              <div className="top-part">
                {!hideLogo && (
                  <div className="logo">
                    <img
                      src="/Images/yatri-service.png"
                      alt="Logo"
                      width={110}
                      height={45}
                    />
                  </div>
                )}
                <div
                  className="ticket-details"
                  style={{ width: "100%", textAlign: "end" }}
                >
                  <h4 style={{ fontSize: "16px", fontWeight: "700" }}>
                    Ticket-Confirmed{" "}
                    {/* {filteredBooking.booking_response &&
                      statusMapping[
                        filteredBooking.booking_response.ResponseStatus
                      ]} */}
                  </h4>
                </div>
              </div>
              <div className="top-parts">
                {!hidePerson && (
                  <div className="ticket-details">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="booking-ticket-upper-sections">
                        Company Name :{" "}
                        <b>{walletData && walletData.CompanyName}</b>
                      </div>
                    </div>{" "}
                    <div className="booking-ticket-upper-sections">
                      Address : <b> {walletData && walletData.Address}</b>
                    </div>
                    <div className="booking-ticket-upper-sections">
                      Phone No :<b> {walletData && walletData.Phone}</b>
                    </div>
                    <div className="booking-ticket-upper-sections">
                      <p> Email Id : </p> &nbsp;
                      <b>{walletData && walletData.Email}</b>
                    </div>
                  </div>
                )}
                <div className="ticket-details">
                  <div className="">
                    <div className="booking-ticket-upper-sections">
                      <p style={{ marginBottom: "0px" }}>Refund : </p>
                      <b>
                        {filteredBooking &&
                        filteredBooking.booking_response.Contracts[0]
                          .Refundable &&
                        filteredBooking.booking_response.Contracts[0]
                          .Refundable === true
                          ? "Refundable"
                          : "Non Refundable"}{" "}
                      </b>
                    </div>
                    {/* <div className="booking-ticket-upper-sections">
                    <p>Refrence Id :</p>
                    <b> {filteredBooking.booking_response} </b>
                  </div> */}
                    {filteredBooking &&
                      filteredBooking.booking_response.Contracts[0] && (
                        <div className="booking-ticket-upper-sections">
                          <span>
                            Inbound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <b>
                            {" "}
                            {
                              filteredBooking.booking_response.Contracts[0]
                                .AirlinePnr
                            }{" "}
                          </b>
                        </div>
                      )}
                    {filteredBooking &&
                      filteredBooking.booking_response.Contracts[1] && (
                        <p style={{ marginBottom: "0px" }}>
                          OutBound Airline PNR : &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>
                            {
                              filteredBooking.booking_response.Contracts[1]
                                .AirlinePnr
                            }{" "}
                          </span>
                        </p>
                      )}
                  </div>
                </div>
              </div>

              <div className="passenger-details">
                {/* {filteredBooking &&
                  filteredBooking.booking_response.Flightpassenger.map(
                    (item) => ( */}
                <h4>
                  {" "}
                  Passenger -{" "}
                  <span style={{ fontSize: "15px", fontWeight: "600" }}>
                    (
                    {filteredBooking.booking_response &&
                      filteredBooking.booking_response.Flightpassenger[0].Email}
                    )
                  </span>
                  {/* - {passengerDetails.adults} Adult {passengerDetails.children} Child  */}
                </h4>
                {/* )
                  )} */}
                <table className="flight-ticket_table table table-bordered text-center">
                  <thead>
                    <tr>
                      <th scope="col">Passenger Name</th>
                      {/* <th scope="col">Airline</th> */}
                      <th scope="col">Status</th>
                      {/* <th scope="col">Sector</th> */}
                      <th scope="col">Airline PNR</th>
                      <th scope="col">Ticket Number</th>
                      <th scope="col">Refundable</th>
                      <th scope="col">Seat No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBooking &&
                      filteredBooking.booking_response.Flightpassenger.map(
                        (item,index) => (
                          <tr>
                            <td>
                              <p>
                                {item.FirstName} {item.LastName}
                              </p>
                            </td>
                            {/* <td>
                     
                        {
                          bookingDetails.Contracts[0].AirSegments[0]
                            .AirlineCode
                        }
                       
                      </td> */}

                            <td>
                              Success
                              {/* {passenger.Ticket && passenger.Ticket.Status} */}
                            </td>
                            {/* <td>
                        {bookingDetails.Contracts.map((item) => (
                          <p>
                            {item.AirSegments.map((item) => (
                              <p>
                                {item.Origen} - {item.Destination},
                              </p>
                            ))}
                          </p>
                        ))}
                      </td> */}
                            <td>
                              {filteredBooking.booking_response.AirlinePnr}
                            </td>
                            <td>{item.TicketNumber === "" ? (<div>{filteredBooking.ticket_numbers[index]}</div>) : item.TicketNumber}</td>
                            <td>
                              {" "}
                              {filteredBooking &&
                              filteredBooking.booking_response.Contracts[0]
                                .Refundable &&
                              filteredBooking.booking_response.Contracts[0]
                                .Refundable === true
                                ? "Refundable"
                                : "Non Refundable"}{" "}
                            </td>
                            <td>{item.SeatCode}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>

              <div className="flight-details">
                <h4> Flight Details </h4>
                <table className="flight-ticket_table table text-center table-bordered">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col" colSpan="2">
                        Airline Details
                      </th>
                      <th scope="col">Departure</th>
                      <th scope="col">Arrival</th>
                      <th scope="col">Duration</th>
                    </tr>
                  </thead>

                  {filteredBooking &&
                    filteredBooking.booking_response.Contracts.map((data) => (
                      <tbody>
                        {data.AirSegments.map((item) => (
                          <tr style={{ background: "#6e25c129" }}>
                            <td>
                              <img
                                src={`/Images/AirlineLogo/${item.AirlineCode}.gif`}
                                className="img-fluid"
                                alt=""
                                style={{ height: "42px", width: "40px" }}
                              />
                              <div className="flightInfoDivtag1">
                                <p
                                  className=""
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {item.AirlineName}- {item.FlightNumber}
                                </p>
                              </div>
                              {/* <i
                            className="fa-solid fa-plane-circle-check fa-fw"
                            style={{ color: "#8b3eea" }}
                          ></i> */}
                            </td>
                            <td>
                              <div>
                                <p>
                                  <span style={{ fontWeight: 600 }}>
                                    {/* {detail.fD.aI.name} */}
                                  </span>
                                  <br />
                                  {item.AirlineCode} {item.FlightNumber} <br />
                                  Cabin Class: (
                                  {item.TypeOfClass === 0 && "Economy"}
                                  {item.TypeOfClass === 1 && "First"}
                                  {item.TypeOfClass === 2 && "Business"}
                                  {item.TypeOfClass === 3 && "Premium_Economy"}
                                  ) <br />
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#8b3eea",
                                      fontWeight: "600",
                                    }}
                                  >
                                    ({item.SegmentType})
                                  </span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <span>
                                {item.SourceAirport.city_name} ({item.Origen})
                              </span>
                              <br />
                              <p style={{ marginBottom: "0px" }}>
                                <span style={{ fontWeight: 600 }}>
                                  {item.DepartureTime === null ||
                                  item.DepartureTime === "" ? (
                                    <>
                                      {item.DepartureTime}
                                      {new Date(
                                        item.DepartureDateTime
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })}{" "}
                                      <br />
                                      {formatTime(item.DepartureDateTime)}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {convertToAmPm(item.DepartureTime)}
                                      <br />
                                      {item.DepartureDateTime}
                                    </>
                                  )}
                                </span>
                              </p>
                            </td>
                            <td>
                              <span>
                                {/* {item.DestinationAirport.city_name} ( */}
                                {item.DestinationAirport.city_name} (
                                {item.Destination}){" "}
                              </span>
                              <br />
                              <p style={{ marginBottom: "0px" }}>
                                <span style={{ fontWeight: 600 }}>
                                  {/* {item.ArrivalTime === null ||
                              item.ArrivalTime === ""
                                ? item.ArrivalTime
                                : convertToAmPm(item.ArrivalTime)}
                              <br />
                              {item.ArrivalDateTime} */}

                                  {item.ArrivalTime === null ||
                                  item.ArrivalTime === "" ? (
                                    <>
                                      {item.ArrivalTime}
                                      {new Date(
                                        item.ArrivalDateTime
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })}{" "}
                                      <br />
                                      {formatTime(item.ArrivalDateTime)}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {convertToAmPm(item.ArrivalTime)}
                                      <br />
                                      {item.ArrivalDateTime}
                                    </>
                                  )}
                                </span>
                                {/* {detail.aa.name} */}
                              </p>
                            </td>
                            <td>{item.Duration}</td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                </table>
              </div>

              <div className="baggage-flight">
                <h4> Baggage Info </h4>
                <table className="flight-ticket_table table text-center table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" rowSpan="2">
                        Airline
                      </th>
                      <th scope="col" rowSpan="2">
                        Sector
                      </th>
                      <th scope="col" colSpan="2">
                        Baggage
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">Check-In</th>
                      <th scope="col">Cabin</th>
                    </tr>
                  </thead>

                  {filteredBooking &&
                    filteredBooking.booking_response.Contracts.map((data) => (
                      <tbody>
                        {data.AirSegments.map((item) => (
                          <tr>
                            <td>
                              {item.AirlineName}- {item.FlightNumber} <br />
                              {/* {item.AirlineCode} */}
                              <span
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "4px",
                                  color: "#8b3eea",
                                  fontWeight: "600",
                                }}
                              >
                                ({item.SegmentType})
                              </span>
                            </td>
                            <td>
                              ( {item.Origen}-{item.Destination})
                            </td>
                            <td>{item.BaggageAllowed.CheckInBaggage}</td>
                            <td>{item.BaggageAllowed.HandBaggage}</td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                </table>
              </div>

              {!hidePrice && (
                <div className="flight-details">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      background: "#8b3eea",
                      alignItems: "center",
                    }}
                  >
                    <h4> Fare Info </h4>
                    <h6 style={{ color: "#fff", marginRight: "10px" }}>
                      Fare Type :{" "}
                      {
                        fares[
                          filteredBooking.booking_response.Contracts[0] &&
                            filteredBooking.booking_response.Contracts[0]
                              .FareType
                        ]
                      }
                    </h6>
                  </div>
                  <table className="flight-ticket_table table text-center table-bordered">
                    <thead className=" table-primary">
                      <tr style={{ backgroundColor: "#6e25c129 !important" }}>
                        <th scope="col">Taxes & Surcharges/Other Charges</th>
                        <th scope="col"> Base Fare</th>

                        {/* <th scope="col">Discount</th>
                    <th scope="col">Service Fee</th>
                    <th scope="col">Excess Baggage</th>
                    <th scope="col">Meal</th>
                    <th scope="col">Seat Charges</th>
                   */}
                        <th scope="col">Total Fare</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBooking && (
                        <tr>
                          <td>
                            ₹{" "}
                            {!filteredBooking.booking_response.Contracts[1] &&
                              filteredBooking.booking_response.Contracts[0]
                                .AirlineFare.TaxFare +
                                markup +
                                (money && parseInt(money))}
                            {filteredBooking.booking_response.Contracts[1] &&
                              filteredBooking.booking_response.Contracts[0]
                                .AirlineFare.TaxFare +
                                markup +
                                (markup +
                                  filteredBooking.booking_response.Contracts[1]
                                    .AirlineFare.TaxFare) +
                                (money && parseInt(money))}
                            {/* {
                            bookingDetails.TravelItinerary
                              .ItineraryInfo.ItineraryPricing.TotalTax +
                              bookingDetails.TravelItinerary
                                .ItineraryInfo.ItineraryPricing.ServiceTax
                          } */}
                          </td>
                          <td>
                            {" "}
                            ₹
                            {!filteredBooking.booking_response.Contracts[1] &&
                              filteredBooking.booking_response.Contracts[0]
                                .AirlineFare.BaseFare}
                            {filteredBooking.booking_response.Contracts[1] &&
                              filteredBooking.booking_response.Contracts[0]
                                .AirlineFare.BaseFare +
                                filteredBooking.booking_response.Contracts[1]
                                  .AirlineFare.BaseFare}
                          </td>

                          {/* <td>
                        ₹{" "}
                        {!bookingDetails.Contracts[1] &&
                          bookingDetails.Contracts[0].AirlineFare
                            .Discount}
                        {bookingDetails.Contracts[1] &&
                          bookingDetails.Contracts[0].AirlineFare
                            .Discount +
                            bookingDetails.Contracts[1].AirlineFare
                              .Discount}
                      </td> */}
                          {/* <td>
                        ₹{" "}
                        {!bookingDetails.Contracts[1] &&
                          bookingDetails.Contracts[0].AirlineFare
                            .ServiceCharge}
                        {bookingDetails.Contracts[1] &&
                          bookingDetails.Contracts[0].AirlineFare
                            .ServiceCharge +
                            bookingDetails.Contracts[1].AirlineFare
                              .ServiceCharge}
                      </td> */}
                          {/* <td>₹ 0</td>
                      <td>₹ 0</td>
                      <td>₹ 0</td> */}

                          <td>
                            ₹{" "}
                            {!filteredBooking.booking_response.Contracts[1] &&
                              filteredBooking.booking_response.Contracts[0]
                                .AirlineFare.GrossFare +
                                markup +
                                (money && parseInt(money))}
                            {filteredBooking.booking_response.Contracts[1] &&
                              filteredBooking.booking_response.Contracts[0]
                                .AirlineFare.GrossFare +
                                markup +
                                filteredBooking.booking_response.Contracts[1]
                                  .AirlineFare.GrossFare +
                                markup +
                                (money && parseInt(money))}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {/* <div className="personal-details">
              <h4> Company Contact Details </h4>
              <table className="flight-ticket_table table table-bordered">
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Name</td>
                    <td>Yatri Service </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Address</td>
                    <td>Delhi, India-41</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Contact</td>
                    <td>+91-9721647550 </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Email</td>
                    <td>Support@yatriservice.com</td>
                  </tr>
                </tbody>
              </table>
            </div> */}

              <div className="information-details">
                <h5>Passport/Visa/Health</h5>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingInlineStart: "20px",
                  }}
                >
                  <li>
                    {" "}
                    Please ensure that you have all the required travel
                    documents for your entire journey i.e., valid passport &
                    necessary visas, and that you have had the recommended
                    inoculations for your destination(s).
                  </li>
                  <li>
                    {" "}
                    All passengers, including children and infants, have to
                    present their valid ID proof at the time of check-in.
                  </li>
                  <li>
                    {" "}
                    We recommend you check-in at least 3 hours prior to
                    departure of your domestic flight and 4 hours prior to your
                    international flight
                  </li>
                  <li>
                    Carriage and other facilities provided by the carrier are
                    subject to their Terms and Condition. We are not liable for
                    missing any facility of the carrier.
                  </li>
                  <li>
                    Recheck your baggage with your respective airline before
                    traveling for a hassle-free travel experience.
                  </li>
                  <li>
                    Group Booking Rules will be applicable if passengers are 9
                    or more in numbers.
                  </li>
                  <li>
                    Company is not responsible for any delay or cancellation of
                    flights from airline's end.
                  </li>
                  <li>
                    Unaccompanied Child: Children below the age of 12 will not
                    be accepted for carriage unless they are accompanied by a
                    person of at least 18 years of age. Such child/children must
                    be seated next to the accompanying adult. The accompanying
                    adult is solely responsible for the well-being of the
                    child/children traveling together with him/her. This also
                    includes ensuring that seats are booked to ensure
                    child/children and an accompanying adult are seated
                    together.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default TicketBooking;
