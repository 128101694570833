const datass={
    "id": 40,
    "Refid": "AE00040",
    "user_id": "AE00000",
    "depart": "Delhi",
    "arrival": "Mumbai",
    "return_depart": null,
    "return_arrival": null,
    "pnr": "Test",
    "pnrib": null,
    "depart_flight": "DEL-BOM",
    "source": "DEL",
    "destination": "BOM",
    "from_date": "2024-08-30",
    "to_date": "2024-08-30",
    "return_flight": null,
    "depart_date": "2024-08-30",
    "return_date": "2024-08-30",
    "booking_id": "20277797",
    "trace_id": "ef7d0e8a-5ce4-4465-ab18-d88ac9c3d378",
    "policy": null,
    "booking_response": {
        "APIToken": "ef7d0e8a-5ce4-4465-ab18-d88ac9c3d378",
        "ResponseStatus": 1,
        "Error": {
            "ErrorCode": "0",
            "ErrorDesc": ""
        },
        "IsFareChange": false,
        "IsTimeCharge": false,
        "ContractId": "63",
        "BookingId": "20277797",
        "BookingStatus": 1,
        "AirlinePnr": "Test",
        "Contracts": [
            {
                "ContractId": 63,
                "ContractType": "Onword",
                "GDSPnr": "Test",
                "AirlinePnr": "Test",
                "AirlineCode": null,
                "Engine": 4,
                "Lcc": true,
                "IsUKSpecial": false,
                "IsGoAirSpecialV2": false,
                "Refundable": true,
                "AirlineRemark": null,
                "FareType": 0,
                "AirlineFare": {
                    "ContractType": "Onword",
                    "Currency": "INR",
                    "BaseFare": 2958,
                    "YQTx": 0,
                    "TaxFare": 651,
                    "TaxBreakup": [],
                    "Discount": 0,
                    "GrossFare": 3609,
                    "Commission": 59,
                    "NetFare": 3553,
                    "ServiceCharge": 10,
                    "TDS": 0,
                    "GSTOnServiceCharge": {
                        "SGST": 0,
                        "CGST": 0,
                        "IGST": 0
                    },
                    "APIProductClass": null,
                    "APIBrandName": null,
                    "PexFareDetails": null
                },
                "AirlineFares": null,
                "PexFareDetails": [
                    {
                        "PaxType": 1,
                        "TotPax": 1,
                        "BaseFare": 2958,
                        "TaxFare": 651,
                        "YQTax": 0,
                        "GrossFare": 3609,
                        "NetFare": 0,
                        "ServiceCharge": 0
                    }
                ],
                "AirSegments": [
                    {
                        "SegmentType": "Onword",
                        "BaggageAllowed": {
                            "CheckInBaggage": "15 KG",
                            "HandBaggage": "7 KG"
                        },
                        "TypeOfClass": 0,
                        "AirlineCode": "6E",
                        "AirlineName": "6E",
                        "FlightNumber": "2766",
                        "AirCraftType": "321",
                        "FareClass": "S",
                        "Origen": "DEL",
                        "Destination": "BOM",
                        "OriginAirportTerminal": null,
                        "DepartureDateTime": "2024-08-30T03:35:00",
                        "DepartureTime": null,
                        "DestinationAirportTerminal": "T2",
                        "ArrivalDateTime": "2024-08-30T05:40:00",
                        "ArrivalTime": null,
                        "Duration": "2h : 5m",
                        "NumberofStops": 0,
                        "SourceAirport": {
                            "id": 856,
                            "airport_code": "DEL",
                            "airport_name": "Indira Gandhi Airport",
                            "city_code": "DEL",
                            "city_name": "Delhi",
                            "country_name": "India",
                            "country_code": "IN",
                            "top_cities": 1,
                            "markup_fee": null,
                            "markup_type": null,
                            "created_at": "2020-05-23 13:14:32",
                            "updated_at": "2022-03-19 23:43:21",
                            "priority": 1,
                            "no_airport": null,
                            "is_airport_city": null
                        },
                        "DestinationAirport": {
                            "id": 450,
                            "airport_code": "BOM",
                            "airport_name": "Mumbai",
                            "city_code": "BOM",
                            "city_name": "Mumbai",
                            "country_name": "India",
                            "country_code": "IN",
                            "top_cities": 1,
                            "markup_fee": null,
                            "markup_type": null,
                            "created_at": "2020-05-23 13:14:32",
                            "updated_at": "2022-03-19 23:43:19",
                            "priority": 3,
                            "no_airport": null,
                            "is_airport_city": null
                        }
                    }
                ],
                "ContractDuration": null,
                "TotalSeats": null,
                "FareGroups": null
            }
        ],
        "Flightpassenger": [
            {
                "PaxId": "0",
                "Title": "Mr",
                "FirstName": "ADFASDF",
                "LastName": "ASDF",
                "PaxType": 1,
                "Gender": null,
                "DateOfBirth": "2000-08-10",
                "PassportNo": "",
                "PassportExpiry": "",
                "ContryCode": null,
                "ContactNo": null,
                "Email": "guruansh@holidaychacha.com",
                "IsLeadPax": false,
                "MealCode": "",
                "BaggageCode": "",
                "SeatCode": "",
                "FrequentFlNo": null,
                "PaxFare": {
                    "BaseFare": 0,
                    "TaxFare": 0,
                    "YQTax": 0,
                    "PriceBaggage": 0,
                    "PriceMeal": 0,
                    "PriceSeat": 0
                },
                "TicketNumber": "Test"
            }
        ]
    },
    "booking_response_ib": {
        "APIToken": "ef7d0e8a-5ce4-4465-ab18-d88ac9c3d378",
        "ResponseStatus": 1,
        "Error": {
            "ErrorCode": "0",
            "ErrorDesc": ""
        },
        "IsFareChange": false,
        "IsTimeCharge": false,
        "ContractId": "63",
        "BookingId": "20277797",
        "BookingStatus": 1,
        "AirlinePnr": "Test",
        "Contracts": [
            {
                "ContractId": 63,
                "ContractType": "Onword",
                "GDSPnr": "Test",
                "AirlinePnr": "Test",
                "AirlineCode": null,
                "Engine": 4,
                "Lcc": true,
                "IsUKSpecial": false,
                "IsGoAirSpecialV2": false,
                "Refundable": true,
                "AirlineRemark": null,
                "FareType": 0,
                "AirlineFare": {
                    "ContractType": "Onword",
                    "Currency": "INR",
                    "BaseFare": 2958,
                    "YQTx": 0,
                    "TaxFare": 651,
                    "TaxBreakup": [],
                    "Discount": 0,
                    "GrossFare": 3609,
                    "Commission": 59,
                    "NetFare": 3553,
                    "ServiceCharge": 10,
                    "TDS": 0,
                    "GSTOnServiceCharge": {
                        "SGST": 0,
                        "CGST": 0,
                        "IGST": 0
                    },
                    "APIProductClass": null,
                    "APIBrandName": null,
                    "PexFareDetails": null
                },
                "AirlineFares": null,
                "PexFareDetails": [
                    {
                        "PaxType": 1,
                        "TotPax": 1,
                        "BaseFare": 2958,
                        "TaxFare": 651,
                        "YQTax": 0,
                        "GrossFare": 3609,
                        "NetFare": 0,
                        "ServiceCharge": 0
                    }
                ],
                "AirSegments": [
                    {
                        "SegmentType": "Onword",
                        "BaggageAllowed": {
                            "CheckInBaggage": "15 KG",
                            "HandBaggage": "7 KG"
                        },
                        "TypeOfClass": 0,
                        "AirlineCode": "6E",
                        "AirlineName": "6E",
                        "FlightNumber": "2766",
                        "AirCraftType": "321",
                        "FareClass": "S",
                        "Origen": "DEL",
                        "Destination": "BOM",
                        "OriginAirportTerminal": null,
                        "DepartureDateTime": "2024-08-30T03:35:00",
                        "DepartureTime": null,
                        "DestinationAirportTerminal": "T2",
                        "ArrivalDateTime": "2024-08-30T05:40:00",
                        "ArrivalTime": null,
                        "Duration": "2h : 5m",
                        "NumberofStops": 0,
                        "SourceAirport": {
                            "id": 856,
                            "airport_code": "DEL",
                            "airport_name": "Indira Gandhi Airport",
                            "city_code": "DEL",
                            "city_name": "Delhi",
                            "country_name": "India",
                            "country_code": "IN",
                            "top_cities": 1,
                            "markup_fee": null,
                            "markup_type": null,
                            "created_at": "2020-05-23 13:14:32",
                            "updated_at": "2022-03-19 23:43:21",
                            "priority": 1,
                            "no_airport": null,
                            "is_airport_city": null
                        },
                        "DestinationAirport": {
                            "id": 450,
                            "airport_code": "BOM",
                            "airport_name": "Mumbai",
                            "city_code": "BOM",
                            "city_name": "Mumbai",
                            "country_name": "India",
                            "country_code": "IN",
                            "top_cities": 1,
                            "markup_fee": null,
                            "markup_type": null,
                            "created_at": "2020-05-23 13:14:32",
                            "updated_at": "2022-03-19 23:43:19",
                            "priority": 3,
                            "no_airport": null,
                            "is_airport_city": null
                        }
                    }
                ],
                "ContractDuration": null,
                "TotalSeats": null,
                "FareGroups": null
            }
        ],
        "Flightpassenger": [
            {
                "PaxId": "0",
                "Title": "Mr",
                "FirstName": "ADFASDF",
                "LastName": "ASDF",
                "PaxType": 1,
                "Gender": null,
                "DateOfBirth": "2000-08-10",
                "PassportNo": "",
                "PassportExpiry": "",
                "ContryCode": null,
                "ContactNo": null,
                "Email": "guruansh@holidaychacha.com",
                "IsLeadPax": false,
                "MealCode": "",
                "BaggageCode": "",
                "SeatCode": "",
                "FrequentFlNo": null,
                "PaxFare": {
                    "BaseFare": 0,
                    "TaxFare": 0,
                    "YQTax": 0,
                    "PriceBaggage": 0,
                    "PriceMeal": 0,
                    "PriceSeat": 0
                },
                "TicketNumber": "Test"
            }
        ]
    },
    "type": "b2b",
    "created_at": [],
    "updated_at": [],
    "status": 1,
    "return_status": 0,
    "ticket_status": 1,
    "track_pnr": "PEN",
    "email": "guruansh@holidaychacha.com",
    "mobile": "9739504090",
    "booking_request": null,
    "farequoteob_log": null,
    "bookingib_request": null,
    "farequoteib_log": {
        "APIToken": "ef7d0e8a-5ce4-4465-ab18-d88ac9c3d378",
        "BookingKey": "a3e0a739-108b-4e97-b45e-f6d676372f51",
        "IsFareChange": false,
        "IsTimeCharge": false,
        "ResponseStatus": 1,
        "Error": {
            "ErrorCode": "",
            "ErrorDesc": ""
        },
        "Sell": {
            "Contracts": [
                {
                    "ContractId": 63,
                    "ContractType": "Onword",
                    "GDSPnr": null,
                    "AirlinePnr": null,
                    "AirlineCode": null,
                    "Engine": 4,
                    "Lcc": true,
                    "IsUKSpecial": false,
                    "IsGoAirSpecialV2": false,
                    "Refundable": true,
                    "AirlineRemark": null,
                    "FareType": 0,
                    "AirlineFare": {
                        "ContractType": null,
                        "Currency": "INR",
                        "BaseFare": 2958,
                        "YQTx": 0,
                        "TaxFare": 641,
                        "TaxBreakup": [],
                        "Discount": 0,
                        "GrossFare": 3609,
                        "Commission": 59,
                        "NetFare": 3555,
                        "ServiceCharge": 10,
                        "TDS": 3,
                        "GSTOnServiceCharge": {
                            "SGST": 1,
                            "CGST": 1,
                            "IGST": 0
                        },
                        "APIProductClass": null,
                        "APIBrandName": null,
                        "PexFareDetails": null
                    },
                    "AirlineFares": null,
                    "PexFareDetails": [
                        {
                            "PaxType": 1,
                            "TotPax": 1,
                            "BaseFare": 2958,
                            "TaxFare": 641,
                            "YQTax": 0,
                            "GrossFare": 3609,
                            "NetFare": 0,
                            "ServiceCharge": 10
                        }
                    ],
                    "AirSegments": [
                        {
                            "SegmentType": "Onword",
                            "BaggageAllowed": {
                                "CheckInBaggage": "15 KG",
                                "HandBaggage": "7 KG"
                            },
                            "TypeOfClass": 0,
                            "AirlineCode": "6E",
                            "AirlineName": "Indigo",
                            "FlightNumber": "2766",
                            "AirCraftType": "321",
                            "FareClass": "S",
                            "Origen": "DEL",
                            "Destination": "BOM",
                            "OriginAirportTerminal": null,
                            "DepartureDateTime": "2024-08-30T03:35:00",
                            "DepartureTime": null,
                            "DestinationAirportTerminal": "T2",
                            "ArrivalDateTime": "2024-08-30T05:40:00",
                            "ArrivalTime": null,
                            "Duration": "2h : 5m",
                            "NumberofStops": 0,
                            "SourceAirport": {
                                "id": 856,
                                "airport_code": "DEL",
                                "airport_name": "Indira Gandhi Airport",
                                "city_code": "DEL",
                                "city_name": "Delhi",
                                "country_name": "India",
                                "country_code": "IN",
                                "top_cities": 1,
                                "markup_fee": null,
                                "markup_type": null,
                                "created_at": "2020-05-23 13:14:32",
                                "updated_at": "2022-03-19 23:43:21",
                                "priority": 1,
                                "no_airport": null,
                                "is_airport_city": null
                            },
                            "DestinationAirport": {
                                "id": 450,
                                "airport_code": "BOM",
                                "airport_name": "Mumbai",
                                "city_code": "BOM",
                                "city_name": "Mumbai",
                                "country_name": "India",
                                "country_code": "IN",
                                "top_cities": 1,
                                "markup_fee": null,
                                "markup_type": null,
                                "created_at": "2020-05-23 13:14:32",
                                "updated_at": "2022-03-19 23:43:19",
                                "priority": 3,
                                "no_airport": null,
                                "is_airport_city": null
                            }
                        }
                    ],
                    "ContractDuration": [
                        {
                            "ContractType": "Onword",
                            "TripDuration": "2h : 5m"
                        }
                    ],
                    "TotalSeats": null,
                    "FareGroups": null
                }
            ]
        },
        "BalanceFare": 0
    },
    "booking_req_log": null,
    "agent_id": 91,
    "admin_id": 85,
    "booking_type": "ONEWAY",
    "is_international": 0,
    "remark": null,
    "apnr": null,
    "farerule": "{\"APIToken\":\"ef7d0e8a-5ce4-4465-ab18-d88ac9c3d378\",\"Status\":1,\"Error\":{\"ErrorCode\":\"0\",\"ErrorDesc\":\"\"},\"FareRule\":\"<div><h5>Fare Rules Normal<\\/h5><span>Mentioned fees are Per Pax Per Sector<\\/span><br \\/><span>Apart nfrom airline charges, GST + RAF + applicable charges if any, will be charged.<\\/span><table><thead><tr><th>Type<\\/th><th>Cancellation Fee<\\/th><th>Date Changes Fee<\\/th><th>No Show<\\/th><th>Seat Chargeable<\\/th><\\/tr><\\/thead><tbody><tr><td>All<\\/td><td><p><b>INR 3500 + INR 50<\\/b><br \\/>Cancellation permitted 06 Hrs before scheduled departure<br>Within 06-96 hrs Rs 3,500<br>Before 96 hrs Rs 3,000<\\/p><\\/td><td><p><b>INR 3500 + INR 50<\\/b><br \\/>Cancellation permitted 06 Hrs before scheduled departure<br>Within 06-96 hrs Rs 3,500<br>Before 96 hrs Rs 3,000<\\/p><\\/td><td><p>If Cancelled within 3 hrs of scheduled departure only statutory taxes will be Refunded.<\\/p><\\/td><td><p>Paid Seat<\\/p><\\/td><\\/tr><\\/tbody><\\/table><\\/div>\"}",
    "refunded": 0,
    "refund_remarks": null,
    "lcc": null,
    "old_id": null,
    "reissue_booking_id": null,
    "is_hold": 0,
    "offer_type": "tbo"
}

export default datass