import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const PrintableComponent = () => {
  const printRef = useRef(null); // Ref for the printable content

  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Points to the content to print
    documentTitle: "Printable Document", // Optional: Set the document title
    onAfterPrint: () => alert("Print successful!"), // Optional: Callback after printing
    onBeforeGetContent: () => console.log("Preparing content for print..."), // Debug callback
  });

  return (
    <div style={{ padding: "20px" }}>
      {/* Button to Trigger Print */}
      <button
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          marginBottom: "20px",
        }}
        onClick={handlePrint}
      >
        Print
      </button>

      {/* Printable Content */}
      <div
        ref={printRef}
        style={{
          border: "1px solid #000",
          padding: "20px",
          marginTop: "10px",
          background: "#f9f9f9",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Print Test</h1>
        <p>This is a simple test to check if the print functionality works.</p>
        <ul>
          <li>Point 1</li>
          <li>Point 2</li>
          <li>Point 3</li>
        </ul>
        <p>
          If you see this in the print preview, the functionality is working as
          expected!
        </p>
      </div>
    </div>
  );
};

export default PrintableComponent;